export type ProgramCounts = {
  enrollmentsCount: number;
  connectionsCount: number;
};

export enum AutoMatchingStatus {
  STARTED = 'started',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export enum SaveStatus {
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}
