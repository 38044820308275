import { useContext } from 'react';

import { User } from 'redux/schemas/models/my-account';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';

import { css } from '@emotion/react';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';

import { PILL_COLOR, PILL_MAX_WIDTH } from 'athena/components/mentoring-program/program-configuration/program-setup/alias-setting';

import Avatar from '../../../avatar';
import MentoringProgramContext from '../../../context';

type AvatarPillType = {
  user: User;
  role: MentoringProgramRole;
  size?: number;
};

const AvatarPill = ({ user, role, size = 100 }: AvatarPillType) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { programAlias } = mentoringProgram;
  const aliasRole = role === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR ? 'mentor' : 'mentee';
  const alias = programAlias?.[aliasRole]?.capitalizedSingularized;

  const styles = css`
    .avatar {
      height: ${size}px;
      width: ${size}px;
      border-radius: 50%;
    }
    .pill {
      height: ${largeSpacing}px;
      font-weight: ${semiBoldFontWeight};
      border-radius: ${largeSpacing / 2}px;
      color: ${PILL_COLOR[role]?.text};
      background-color: ${PILL_COLOR[role]?.background};
      max-width: ${PILL_MAX_WIDTH}px;
      margin-top: -13px;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `;

  return (
    <div css={styles} className='d-flex align-items-center flex-column'>
      <Avatar
        showNameOnHover
        user={user}
        key={user.id}
        className='mr-1 avatar'
        role={role}
      />
      <div
        className='pill d-flex align-items-center pl-2 pr-2 text-small'
      >
        <div>{alias}</div>
      </div>
    </div>
  );
};

export default AvatarPill;
