import extend from 'lodash/extend';
import mergeWith from 'lodash/mergeWith';
import { schema, normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import { getCurrentUser } from 'redux/selectors/users';
import { MyAccount } from 'redux/schemas/models/my-account';
import { NameAndEmailFormSource } from 'redux/schemas/models/user';
import { UpdateCourseNameAndEmailParams } from 'redux/schemas/api/user';
import {
  getMyAccount,
  getBasicUserInfo,
  updateEnrollment,
  setProfilePicture,
  removeUserEnrollment,
  updateHasViewedRteFte,
  updateBasicUserProfile,
  updateRecentlyUsedColors,
  updateNameAndEmail,
  setCurrentUserEnrollments,
  enrollWithRole,
  translateLecturePage,
  translateOutline,
  unsetTranslationPreference,
  searchCourseUsers,
  searchCourseOrgUsers,
  updateSystemGeneratedEmail,
  setShowUpdateEmailAlert,
  patchUser,
  searchOrgUsersForProgram,
  translateJourney,
} from '../actions/users';
import {
  courseUsersSchema,
  enrollmentSchema,
  myAccount,
  usersListSchema,
} from '../schemas/api/my-account';
import { initialRootState } from './index';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getMyAccount.fulfilled, (state, action) => {
      const data = normalize(action.payload, myAccount);
      Object.assign(state.models.users, data.entities.myAccount);
      mergeWith(state.models.courses, data.entities.courses, replaceArrays);
      Object.assign(state.models.enrollments, data.entities.enrollments);
      state.app.currentUserId = data.result;
      // Sets the current institution id if not already set
      // Mimics the Angular.js app's assumption that the 1st institution returned from my_account.json
      // is the current one
      if (!state.app.currentInstitutionId && data.entities?.institutions) {
        state.app.currentInstitutionId = Object.values(data.entities.institutions)[0].id;
        state.models.institutions = data.entities.institutions as any;
      }

      if (!state.app.lecturePage.currentLectureId) {
        state.app.lecturePage.leftPanelVisibilityPreference = data.entities.myAccount[state.app.currentUserId].isTimelineExpanded;
      }
    })
    .addCase(updateHasViewedRteFte.pending, (state, action) => {
      const currentUser = getCurrentUser(state);
      state.models.users[currentUser.id].hasViewedRteFte = true;
    })
    .addCase(updateRecentlyUsedColors.pending, (state, action) => {
      const currentUser = getCurrentUser(state);
      state.models.users[currentUser.id].recentlyUsedColors = action.meta.arg;
    })
    .addCase(getBasicUserInfo.fulfilled, (state, action) => {
      const data = normalize(action.payload, myAccount);

      mergeWith(state.models.institutions, data.entities.institutions);
      mergeWith(state.models.courses, data.entities.courses, replaceArrays);
      mergeWith(state.models.users, data.entities.myAccount, replaceArrays);
      mergeWith(state.models.enrollments, data.entities.enrollments, replaceArrays);
      state.app.redirecting = false;
    })
    .addCase(updateEnrollment, (state, action) => {
      extend(state.models.enrollments[action.payload.id], action.payload.patch);
    })
    .addCase(setProfilePicture.fulfilled, (state, action) => {
      const currentUser = getCurrentUser(state);

      currentUser.profilePicture = action.payload;
    })
    .addCase(removeUserEnrollment, (state, action) => {
      const {
        userId,
        enrollmentId: enrollmentIdToRemove,
      } = action.payload;

      delete state.models.enrollments[enrollmentIdToRemove];
      state.models.users[userId].enrollmentIds = state.models.users[userId].enrollmentIds.filter(
        (enrollmentId) => enrollmentId !== enrollmentIdToRemove,
      );
    })
    .addCase(updateBasicUserProfile.fulfilled, (state, action) => {
      const { currentUserId } = state.app;
      const { user } = action.meta.arg;

      /**
       * NOTE: This request currently doesn't respond with the updated user, so
       * for now I am updating redux state with the payload sent.
       */
      Object.assign(state.models.users[currentUserId], {
        ...user,
        initials: getInitials(user),
      });
    })
    .addCase(updateNameAndEmail.fulfilled, (state, action) => {
      const { userId, source } = action.meta.arg;
      const { user } = action.payload;

      if (user && state?.models?.users[userId]) {
        Object.assign(state.models.users[userId], {
          ...user,
          initials: getInitials(user),
        });
      }

      if (source === NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION) {
        const { catalogId, courseUserId } = action.meta.arg as UpdateCourseNameAndEmailParams;
        const course = state.models.courses[catalogId];
        if (course?.isContentManagementCollection) {
          mergeWith(state.models.courseUsers[catalogId][courseUserId].user, user);
        }
      }
    })
    .addCase(setCurrentUserEnrollments, (state, action) => {
      const normalized = normalize(
        action.payload,
        new schema.Array(enrollmentSchema),
      );

      state.models.users[state.app.currentUserId].enrollmentIds = normalized.result;
      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(enrollWithRole.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, enrollmentSchema);
      state.models.users[state.app.currentUserId].enrollmentIds.push(normalized.result);
      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(translateLecturePage.pending, (state, action) => {
      if (action.meta.arg.lecturePageId === state.app.destinationLecturePage?.id) {
        state.app.destinationLecturePage.translated = false;
      } else {
        state.app.lecturePage.translationFailed = false;
        state.app.lecturePage.isTranslating = true;
      }
    })
    .addCase(translateLecturePage.rejected, (state, action) => {
      if (action.meta.arg.lecturePageId === state.app.destinationLecturePage?.id) {
        state.app.destinationLecturePage.translated = false;
      } else {
        state.app.lecturePage.isTranslating = false;
        state.app.lecturePage.translationFailed = true;
      }
    })
    .addCase(translateLecturePage.fulfilled, (state, action) => {
      if (action.meta.arg.lecturePageId === state.app.destinationLecturePage?.id) {
        state.app.destinationLecturePage.translated = true;
      }
      const currentUser = getCurrentUser(state);
      currentUser.translationPreferenceLanguage = action.meta.arg.language;
      currentUser.lastTranslationPreferenceLanguage = action.meta.arg.language;
    })
    .addCase(translateOutline.fulfilled, (state, action) => {
      const currentUser = getCurrentUser(state);
      currentUser.translationPreferenceLanguage = action.meta.arg.language;
      currentUser.lastTranslationPreferenceLanguage = action.meta.arg.language;
    })
    .addCase(unsetTranslationPreference.pending, (state, action) => {
      if (state.models.courses[state.app.currentCatalogId].isJourney) {
        // It requires a non empty string, not related to any language.
        state.app.upcomingJourneyLanguage = 'NoLanguage';
      }
    })
    .addCase(unsetTranslationPreference.fulfilled, (state) => {
      const currentUser = getCurrentUser(state);
      currentUser.translationPreferenceLanguage = null;
      if (state.app.upcomingJourneyLanguage) {
        state.app.upcomingJourneyLanguage = null;
      }
    })
    .addCase(searchCourseUsers.fulfilled, (state, action: any) => {
      const data = normalize/* <CourseUser, OrgUserEntities> */(
        action.payload.response,
        courseUsersSchema,
      );

      mergeWith(state.models.courseUsers, { [action.meta.arg.catalogId]: data.entities.courseUser }, replaceArrays);
    })
    .addCase(searchCourseOrgUsers.fulfilled, (state, action) => {
      state.app.collection.hasMoreInUsersSearch = action.payload.count > action.meta.arg.page * action.meta.arg.pageSize;
    })
    .addCase(searchOrgUsersForProgram.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, usersListSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(updateSystemGeneratedEmail.fulfilled, (state, action) => {
      if (!action.payload?.errorCodes?.length) {
        state.models.users[state.app.currentUserId].email = action.meta.arg.user.email;
        state.models.users[state.app.currentUserId].hasSystemGeneratedAddress = false;
      }
    })
    .addCase(setShowUpdateEmailAlert, (state, action) => {
      state.app.showUpdateEmailAlert = action.payload;
    })
    .addCase(patchUser, (state, action) => {
      const { id, ...restUser } = action.payload as Partial<MyAccount>;

      Object.assign(state.models.users[id], restUser);
    })
    .addCase(translateJourney.pending, (state, action) => {
      state.app.upcomingJourneyLanguage = action.meta.arg.language;
    })
    .addCase(translateJourney.fulfilled, (state, action) => {
      state.app.upcomingJourneyLanguage = null;
      const currentUser = getCurrentUser(state);
      currentUser.translationPreferenceLanguage = action.meta.arg.language;
      currentUser.lastTranslationPreferenceLanguage = action.meta.arg.language;
    });
});

const getInitials = (user) => (user.firstName[0]?.toUpperCase() ?? '')
  + (user.lastName[0]?.toUpperCase() ?? '');
