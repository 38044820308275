import React from 'react';
import { css } from '@emotion/core';
import { Dropdown } from 'react-bootstrap';

import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import { isRtl } from 'styles/global_defaults/media-queries';
import { gray2, gray3, gray4 } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownCustomItem,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';
import { AccessRole } from '.';

const dropdownFormWidth = 220;

const styles = css`
.nv-dropdown {
  width: ${dropdownFormWidth}px;

  .dropdown-role-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${doubleSpacing}px;
    padding: 0 ${halfSpacing}px;
    border: 1px solid ${gray4};
    border-radius: ${quarterSpacing}px;

    .dropdown-role-title {
      color: ${gray2};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon {
      color: ${gray3};
    }
  }

  .bs4-dropdown-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .bs4-dropdown-item {
    display: flex;
    padding-left: ${halfSpacing}px;
    padding-right: ${halfSpacing}px;
  }
}
`;

type Props = {
  roles: AccessRole[];
  selectedRoleValue: number;
  setSelectedRoleValue: React.Dispatch<React.SetStateAction<number>>

};

const RolesDropdown = (props: Props) => {
  const { roles, selectedRoleValue, setSelectedRoleValue } = props;

  const rolesItems: NvDropdownCustomItem[] = roles.map((role: AccessRole) => ({
    type: 'custom',
    id: role.value,
    customItem: (
      <Dropdown.Item
        className='role-item'
        as='button'
        onClick={() => setSelectedRoleValue(role.value)}
      >
        <div>
          {role.label}
        </div>
        <div>
          {role.renderInfo && (
            <NvPopover
              showOnHover
              placement='top'
              preventOverflow
              content={role.renderInfo()}
            >
              <div>
                <NvIcon icon='info' size='xss-smallest' className='color-primary' />
              </div>
            </NvPopover>
          )}
        </div>
      </Dropdown.Item>
    ),
  }));

  const selectedRoleIdIndex = rolesItems.findIndex(role => role.id === selectedRoleValue);

  const customDropdownTarget = () => {
    const title = selectedRoleIdIndex !== -1
      ? roles[selectedRoleIdIndex].label
      : t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.SELECT_ROLE_PLACEHOLDER();

    return (
      <div className='dropdown-role-container'>
        <span className='dropdown-role-title'>{title}</span>
        <div className='icon text-xs icon-dropdown-arrow' />
      </div>
    );
  };

  return (
    <div css={styles}>
      <NvDropdown
        items={rolesItems}
        minWidth={dropdownFormWidth}
        initialIndex={selectedRoleIdIndex}
        customTarget={customDropdownTarget}
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        align={isRtl() ? NvDropdownAlign.RIGHT : NvDropdownAlign.LEFT}
      />
    </div>
  );
};

export default RolesDropdown;
