import { schema } from 'normalizr';
import { MentorshipProgramProfileAnswer } from '../models/mentoring-program-profile-answers';

export const MentorshipProgramProfileAnswerSchema = new schema.Entity<
MentorshipProgramProfileAnswer
>('mentorshipProgramProfileAnswers');

export const MentorshipProgramProfileAnswersSchema = new schema.Array(
  MentorshipProgramProfileAnswerSchema,
);
