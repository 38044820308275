import { InputType, ProgramLevelQuestionType } from 'redux/schemas/models/mentoring-program-profile-questions';

export const DRAFT_PREFIX = 'draft';
export const NAME_MAX_LENGTH = 190;
export const DESCRIPTION_MAX_LENGTH = 250;
export const GUIDELINE_MAX_LENGTH = 16000;

export const QUESTION_MAX_LENGTH = 255;

export const PAGE_SIZE = 20;
export type PageSizeParams = {
  pageSize: number;
};

// Every flex-grow element inside "tabs-container" which nearest parent with
// explicit height is "tabs-container", needs to use this class in order to
// make tab content inner css "overflow" features work properly.
// More reference: https://www.w3.org/TR/css-flexbox-1/#min-size-auto
export const withOverflowFlexGrowClass = 'with-overflow-flex-grow';

export enum AccountField {
  PICTURE_FILE_NAME = 'picture_file_name',
  HEADLINE = 'headline',
  BIO = 'bio',
  DISPLAY_LOCATION = 'display_location',
}

export const getName = (fieldIdentifier: string, t) => {
  const namesMap = {
    [AccountField.PICTURE_FILE_NAME]: t.PROFILE.PROFILE_IMAGE(),
    [AccountField.HEADLINE]: t.PROFILE.HEADLINE(),
    [AccountField.BIO]: t.PROFILE.BIO(),
    [AccountField.DISPLAY_LOCATION]: t.PROFILE.BIO_QUESTIONS.LOCATION(),
  };

  return namesMap[fieldIdentifier];
};

export const getInputType = (fieldIdentifier: string) => {
  const typesMap = {
    [AccountField.PICTURE_FILE_NAME]: InputType.IMAGE,
    [AccountField.HEADLINE]: InputType.SHORT_TEXT,
    [AccountField.BIO]: InputType.LONG_TEXT,
    [AccountField.DISPLAY_LOCATION]: InputType.DISPLAY_LOCATION,
  };

  return typesMap[fieldIdentifier];
};

export const getInputTypeLabel = (t, inputType: InputType) => {
  const DROPDOWN_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.DROPDOWN();
  const SHORT_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.SHORT_TEXT();
  const LONG_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.LONG_TEXT();
  const LOCATION_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.LOCATION();
  const IMAGE_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.ACCOUNT_LEVEL_FIELDS.INPUT_TYPE_LABEL.IMAGE();

  const inputTypeLabelsMap = {
    [InputType.DROPDOWN]: DROPDOWN_VALUE,
    [InputType.LONG_TEXT]: LONG_TEXT_VALUE,
    [InputType.SHORT_TEXT]: SHORT_TEXT_VALUE,
    [InputType.DISPLAY_LOCATION]: LOCATION_TEXT_VALUE,
    [InputType.IMAGE]: IMAGE_VALUE,
  };

  return inputTypeLabelsMap[inputType];
};

export const getProgramLevelInputTypeLabelsMap = (t) => {
  const DROPDOWN_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.DROPDOWN_VALUE();
  const CHECKLIST_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.CHECKLIST_VALUE();
  const SHORT_TEXT_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.SHORT_TEXT_VALUE();
  const LONG_TEXT_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.LONG_TEXT_VALUE();

  const inputTypeLabelsMap = {
    [ProgramLevelQuestionType.DROPDOWN]: DROPDOWN_VALUE,
    [ProgramLevelQuestionType.CHECKLIST]: CHECKLIST_VALUE,
    [ProgramLevelQuestionType.SHORT_TEXT]: SHORT_TEXT_VALUE,
    [ProgramLevelQuestionType.LONG_TEXT]: LONG_TEXT_VALUE,
  };

  return inputTypeLabelsMap;
};
