import React from 'react';
import has from 'lodash/has';
import ReactDOM from 'react-dom';
import { css } from '@emotion/react';
import { Provider } from 'react-redux';

import { loadRoles } from 'redux/actions/roles';
import { getMyAccount } from 'redux/actions/users';
import { loadCourse } from 'redux/actions/courses';
import store, { cloneDeepSerializable } from 'redux/store';
import { RolesNormalized } from 'redux/schemas/models/role';
import AppStylesProvider from 'components/bs4-theme-provider';
import { AthenaContext } from 'athena/hooks/use-is-athena-app';
import { listen as reactTranslateListen } from 'react-translate';
import { RolesService } from 'institutions/services/roles-service';
import useTranslationsLoaded from 'shared/hooks/use-translations-loaded';
import loadInstitution, { loadCourses } from 'redux/actions/institutions';
import {
  AngularContext,
  AngularServicesContext,
  ExposedAngularServices,
} from 'react-app';

/**
 * @name nvReactComponent
 * @restrict E
 * @description
 * The `NvReactComponent` directive mounts (and unmounts) a react component
 * inside the element from the expression of the `component` attribute.
 * The react component is mounted and unmounted if it's used along with
 * render-if attribute.
 * @example
  <nv-react-component component="MyReactComponent"></nv-react-component>
 */
/* @ngInject */
function NvReactComponentDirectiveFactory(
  $window,
  $injector: angular.auto.IInjectorService,
  // AngularServicesContext injected services for old code support:
  $state,
  $uibModal,
  $rootScope: angular.IScope,
  $compile: angular.ICompileService,
  $timeout: angular.ITimeoutService,
  $location: angular.ILocationService,
  $templateCache: angular.ITemplateCacheService,
  RailsRoutes,
  TimelinesManager,
  CourseRolesManager,
  CurrentUserManager,
  FlyoutModalManager,
  InstitutionsManager,
  CurrentCourseManager,
  InteroperabilityRoutes,
  CurrentPermissionsManager,
  ConfettiAnimation,
) {
  if (process.env.EXPERIMENTAL) {
    $window.toggleReactComponentsHighlight = function () {
      document.body.classList.toggle('highlight-react-components');
    };
  }

  const injectServices = (services: string[]) => services.map(
    (serviceName) => $injector.get(serviceName),
  );

  const useAngularServices = (services: [string]) => {
    const servicesRef = React.useRef<unknown[]>();

    if (!servicesRef.current) {
      servicesRef.current = injectServices(services);
    }

    return servicesRef.current;
  };

  type NvReactComponentProps = {
    scope: angular.IScope,
    componentSource: string,
    highlightEnabled: boolean,
    component: React.ComponentType,
  };

  const NvReactComponent = (props: NvReactComponentProps) => {
    const {
      scope,
      // Source of the component from the Angular scope perspective.
      componentSource,
      component: Component,
      highlightEnabled = true,
    } = props;

    const transLoaded = useTranslationsLoaded();
    const [, setRerender] = React.useState(false);
    const rerender = React.useCallback(() => setRerender((prev) => !prev), []);

    React.useEffect(() => reactTranslateListen(rerender), [rerender]);

    if (!transLoaded) return null;

    const defaultAngularServices: ExposedAngularServices = {
      $state,
      $compile,
      $timeout,
      $location,
      $uibModal,
      $rootScope,
      $scope: scope,
      $templateCache,
      $injector,
      RailsRoutes,
      TimelinesManager,
      CourseRolesManager,
      CurrentUserManager,
      FlyoutModalManager,
      InstitutionsManager,
      CurrentCourseManager,
      InteroperabilityRoutes,
      CurrentPermissionsManager,
      ConfettiAnimation,
    };

    return (
      <Provider store={store}>
        <AppStylesProvider>
          {/*
            Using AngularServicesContext for old code support but we should use
            AngularContext injectServices function from now.
          */}
          <AngularServicesContext.Provider value={defaultAngularServices}>
            <AngularContext.Provider
              value={{
                $scope: scope,
                injectServices: useAngularServices,
              }}
            >
              <Component />
              {process.env.EXPERIMENTAL
                && highlightEnabled
                && (
                  <ReactHighlight componentSource={componentSource} />
                )}
            </AngularContext.Provider>
          </AngularServicesContext.Provider>
        </AppStylesProvider>
      </Provider>
    );
  };

  return {
    restrict: 'E',
    scope: {
      component: '<',
      renderIf: '<?',
      isAthenaApp: '<?',
      highlightEnabled: '<?',
      dispatchStoreUpdates: '<?',
      inline: '<?',
    },
    compile(element) {
      element.addClass('react-app');

      return function postLink(scope, elem, attrs) {
        let mounted = false;
        const directiveElement = elem.get(0);

        if (!scope.inline) {
          elem.addClass('display-block');
        }

        if (scope.isAthenaApp) {
          elem.addClass('athena-app');
        }

        function mountReactComponent() {
          const renderedComponent = (
            <AthenaContext.Provider value={scope.isAthenaApp}>
              <NvReactComponent
                scope={scope.$parent}
                component={scope.component}
                componentSource={attrs.component}
                highlightEnabled={scope.highlightEnabled}
              />
            </AthenaContext.Provider>
          );

          if (scope.dispatchStoreUpdates ?? true) {
            if (Object.keys(CurrentUserManager.user).length) {
              store.dispatch(getMyAccount(cloneDeepSerializable(CurrentUserManager.user)));
            }

            if (InstitutionsManager.institution) {
              store.dispatch(loadInstitution(cloneDeepSerializable(InstitutionsManager.institution)));
            }

            if (InstitutionsManager.institution?.courses) {
              store.dispatch(loadCourses(cloneDeepSerializable(InstitutionsManager.institution.courses)));
            }

            if (CurrentCourseManager.course) {
              store.dispatch(loadCourse(cloneDeepSerializable(CurrentCourseManager.course)));
            }

            if (CourseRolesManager.requestCourseRolesPromise) {
              CourseRolesManager.requestCourseRolesPromise.then((rolesNormalized: RolesNormalized) => {
                const serializedRoles = cloneDeepSerializable(rolesNormalized);
                // This is a temporal solution for a known bug in cloneDeepSerializable.
                // Current behavior is extracted from CourseRoleModel constructor augmenting permissions property
                const roles = Object.keys(serializedRoles).reduce((rolesObj, key) => {
                  rolesObj[key] = {
                    ...serializedRoles[key],
                    ...(serializedRoles[key]?.permission ? { permissions: RolesService.getVisiblePermissions(serializedRoles[key]?.permission) } : {}),
                  };
                  return rolesObj;
                }, {});
                store.dispatch(loadRoles(roles));
              });
            }
          }

          ReactDOM.render(renderedComponent, directiveElement);
          mounted = true;
        }

        function unmountReactComponent() {
          ReactDOM.unmountComponentAtNode(directiveElement);
          mounted = false;
        }

        if (has(attrs, 'renderIf')) {
          scope.$watch(() => scope.renderIf, (value) => {
            if (value) {
              if (!mounted) {
                mountReactComponent();
              }
            } else if (mounted) {
              unmountReactComponent();
            }
          });
        } else if (!mounted) {
          mountReactComponent();
        }

        scope.$on('$destroy', () => {
          if (mounted) {
            unmountReactComponent();
          }
        });
      };
    },
  };
}

const REACT_COLOR = '#61dbfb';

const reactHighlightstyles = css`
  display: none;

  .top, .right, .bottom, .left {
    position: absolute;
    z-index: 2147483647;
    background-color: ${REACT_COLOR};
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .top, .bottom {
    width: 100%;
    height: 2px;
  }

  .left, .right {
    width: 2px;
    height: 100%;
  }

  .top {
    top: 0;
    left: 0;
  }

  .right {
    top: 0;
    right: 0;
  }

  .bottom {
    left: 0;
    bottom: 0;
  }

  .left {
    top: 0;
    left: 0;
  }

  .react-highlight-badge {
    top: 0;
    left: 0;
    display: block;
    padding: 0 5px;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    z-index: 2147483647;
    color: ${REACT_COLOR};
    background-color: black;
    border-end-end-radius: 5px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  }
`;

type ReactHighlightProps = {
  componentSource: string,
};

const ReactHighlight = (props: ReactHighlightProps) => (
  <div
    css={reactHighlightstyles}
    className='react-component-highlight'
  >
    <div className='react-highlight-badge'>
      React: {props.componentSource}
    </div>
    <div className='top' />
    <div className='right' />
    <div className='bottom' />
    <div className='left' />
  </div>
);

export default NvReactComponentDirectiveFactory;
