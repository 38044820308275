import React, { useMemo } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import {
  setSaveStatus,
  updateMentoringProgram,
} from 'redux/actions/mentoring-programs';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import {
  DESCRIPTION_MAX_LENGTH,
  GUIDELINE_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from 'athena/components/mentoring-program/constants';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { css } from '@emotion/react';
import { headerLineHeight } from 'styles/global_defaults/fonts';
import renderFormComponents from 'athena/helpers/form-components';
import { aliasValidationSchema } from './alias-setting';

import ProgramNameAndDescription from './program-name-and-description';
import ProgramAliases from './program-aliases';
import ProgramConnections from './program-connections';
import ProgramGuidelines, { getDefaultGuidelines } from './program-guidelines';
import ProgramDates from './program-dates';

const getSections = (aliases) => [
  { name: 'name', component: ProgramNameAndDescription },
  { name: 'dates', component: ProgramDates, eventType: 'onChange' },
  { name: 'guidelines', component: ProgramGuidelines },
  { name: 'aliases', component: ProgramAliases, extras: { aliases } },
  { name: 'connections', component: ProgramConnections },
];

type FormValue = {
  name: string;
  maxMentees: number;
  mentorAlias: string;
  menteeAlias: string;
  description: string;
};

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
  .description {
    line-height: ${headerLineHeight}px;
  }
`;

const ProgramSetup = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = React.useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector(state => getMentoringProgramAliases(state, mentoringProgram?.id));

  const aliases = useMemo(() => [{
    name: 'mentorAlias',
    role: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
  }, {
    name: 'menteeAlias',
    role: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
  }], []);

  const components = useMemo(() => getSections(aliases), [aliases]);

  const validationSchema = Yup.object().shape({
    mentorAlias: aliasValidationSchema,
    menteeAlias: aliasValidationSchema,
    maxMentees: Yup.number()
      .min(1)
      .max(Infinity),
    name: Yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(NAME_MAX_LENGTH, t.VALIDATION.MAX_LENGTH(`${NAME_MAX_LENGTH}`)),
    description: Yup.string()
      .max(DESCRIPTION_MAX_LENGTH, t.VALIDATION.MAX_LENGTH(`${DESCRIPTION_MAX_LENGTH}`)),
    guideline: Yup.string()
      .max(GUIDELINE_MAX_LENGTH, t.VALIDATION.MAX_LENGTH(`${GUIDELINE_MAX_LENGTH}`)),
    releasedDate: Yup.string()
      .nullable()
      .optional(),
    endDate: Yup.string()
      .nullable()
      .optional()
      .when('releasedDate', (releasedDate, schema) => (
        releasedDate ? schema.test({
          test: (value) => (value ? moment(value).isAfter(releasedDate) : true),
          message: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_DATES.CLOSURE_DATE.ERROR(),
        }) : schema
      )),
  });

  const getDefaultValues = React.useCallback(
    () => ({
      name: mentoringProgram?.name ?? '',
      description: mentoringProgram?.description ?? '',
      maxMentees: mentoringProgram?.concurrentMentorConnection ?? 1,
      mentorAlias: mentoringProgramAliases.MentorAlias,
      menteeAlias: mentoringProgramAliases.MenteeAlias,
      guideline: mentoringProgram?.guideline ?? getDefaultGuidelines(mentoringProgram?.name),
      releasedDate: mentoringProgram?.releasedDate,
      endDate: mentoringProgram?.endDate,
    }),
    [mentoringProgram, mentoringProgramAliases],
  );

  const rhfMethods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema),
  });

  const { reset, handleSubmit } = rhfMethods;

  React.useEffect(() => {
    reset(getDefaultValues());
  }, [reset, getDefaultValues]);

  const onSubmit = (form: FormValue) => {
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    wrapThunkAction(
      dispatch(
        updateMentoringProgram({
          id: mentoringProgram.id,
          autoMatchingEnabled: mentoringProgram.autoMatchingEnabled || false,
          maxMentees: mentoringProgram.concurrentMentorConnection,
          ...form,
        }),
      ),
    )
      .then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  return (
    <FormProvider {...rhfMethods}>
      {mentoringProgram && (
        <div css={styles}>
          <div>
            <div className='title heading-2 mb-2'>
              {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.TITLE()}
            </div>
            <div className='description text-large-body mb-6'>
              {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.DESCRIPTION()}
            </div>
            {renderFormComponents(components, handleSubmit(onSubmit))}
          </div>
        </div>
      )}
    </FormProvider>
  );
};

export default ProgramSetup;
