import React from 'react';

import { MentoringProgramNormalized } from 'redux/schemas/models/mentoring-programs';

export enum MentoringProgramError {
  USER_NOT_AUTHORIZED = 'error.mentorship_program.user_not_authorized',
  USER_DROPPED = 'error.mentorship_program.user_dropped',
}

export type MentoringProgramContextValue = {
  isLoading: boolean;
  mentoringProgram?: MentoringProgramNormalized;
  countState: {
    result: any;
    isLoading: boolean;
    refresh: () => Promise<void>;
  },
  error: MentoringProgramError;
};

const MentoringProgramContext = React.createContext<MentoringProgramContextValue>(null);

export default MentoringProgramContext;

export type MentoringProgramHomeUIContextValue = {
  withOverflowFlexGrowClass: string;
};

export const MentoringProgramHomeUIContext = React.createContext<MentoringProgramHomeUIContextValue>(null);
