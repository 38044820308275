import t from 'react-translate';
import { useState } from 'react';
import { css } from '@emotion/react';
import { getInputType, getInputTypeLabel, getName } from 'athena/components/mentoring-program/constants';

import NvPopover from 'shared/components/nv-popover';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { MentorshipProgramProfileQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';

const overlayStyles = css`
  max-width: 280px;
  .confirmation {
    max-width: 100%;
    .button-container {
      justify-content: center;
    }
  }
`;

type AccountLevelFieldRowProps = MentorshipProgramProfileQuestion & {
  onChange: (questionId: number, newState: boolean) => void;
};

const AccountLevelFieldRow = (props: AccountLevelFieldRowProps) => {
  const { id, novoedQuestionName, isRequired, isAttachedToRule, onChange } = props;
  const [isSwitchingRequired, setIsSwitchingRequired] = useState(false);

  const handleChange = (newState: boolean) => {
    if (!newState && isAttachedToRule) {
      setIsSwitchingRequired(true);
    } else onChange(id, newState);
  };

  return (
    <div key={`account-level-${id}`} className='align-content-center field-row'>
      <div className='field-name text-regular semi-bold'>
        {getName(novoedQuestionName, t)}
        {isRequired ? <span className='required'>*</span> : null}
      </div>
      <div className='field-type text-regular'>
        {getInputTypeLabel(t, getInputType(novoedQuestionName))}
      </div>
      <NvPopover
        enabled
        show={isSwitchingRequired}
        overlayStyles={overlayStyles}
        content={(
          <NvConfirmationPopover
            onCancel={() => setIsSwitchingRequired(false)}
            onConfirm={() => {
              onChange(id, false);
              setIsSwitchingRequired(false);
            }}
            header={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.TITLE()}
            message={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.DESCRIPTION()}
            cancelBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.CANCEL()}
            confirmBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.OK()}
          />
        )}
        placement='top'
        rootClose
        onHide={() => setIsSwitchingRequired(false)}
        className='d-inline-flex mw-100 justify-content-center'
        preventOverflow
      >
        <NvSwitch
          className='cell'
          checked={isRequired}
          onChange={handleChange}
        />
      </NvPopover>
    </div>
  );
};

export default AccountLevelFieldRow;
