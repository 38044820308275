import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { css } from '@emotion/react';
import {
  headerLineHeight,
  textLargeBodyFontSize,
  textLargeFontSize,
} from 'styles/global_defaults/fonts';
import { almostBlack, almostWhite, darkGray } from 'athena/styles/colors';
import {
  cardOverlaySize,
  halfSpacing,
  standardSpacing,
  threeQuartersSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import Avatar from '../../avatar';

type ParticipantPreviewProps = {
  enrollment: MentoringProgramEnrollment;
};

const styles = css`
  display: grid;
  grid-template-columns: 80px auto;
  height: ${cardOverlaySize}px;
  margin-top: ${threeQuartersSpacing}px;
  background-color: ${almostWhite};
  border-radius: ${halfSpacing}px;

  .avatar {
    margin-left: ${standardSpacing}px;
    width: ${tripleSpacing}px;
    height: ${tripleSpacing}px;
    font-size: ${textLargeFontSize}px;
  }
  .name {
    color: ${almostBlack};
    font-size: ${textLargeBodyFontSize}px;
    line-height: ${headerLineHeight}px;
  }
  .email {
    color: ${darkGray};
  }
`;

const ParticipantPreview = ({ enrollment }: ParticipantPreviewProps) => {
  const { user, role } = enrollment;

  return (
    <div className='align-items-center' css={styles}>
      <Avatar className='avatar' user={user} role={role.name} />
      <div className='d-flex pl-3 pr-3 flex-column ellipsis'>
        <span className='name ellipsis'>{user.fullName}</span>
        <span className='email text-small ellipsis'>
          {user.email}
        </span>
      </div>
    </div>
  );
};

export default ParticipantPreview;
