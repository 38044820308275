import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';

type BaseParams = {
  pageSize?: number;
};

// eslint-disable-next-line import/prefer-default-export
export const usePaginatedApi = <E extends Object, P extends BaseParams>(
  performRequest: (params: P & { page: number }) => Promise<E[]> | undefined,
  params: P,
  selector: (state: RootState, ids: number[]) => E[],
  idAttribute = 'id',
) => {
  const performRequestRef = React.useRef(performRequest);
  performRequestRef.current = performRequest;

  const paramsRef = React.useRef(params);
  paramsRef.current = params;

  const [page, setPage] = React.useState(0);
  const pageRef = React.useRef(page);
  pageRef.current = page;

  const [allLoaded, setAllLoaded] = React.useState(false);
  const allLoadedRef = React.useRef(allLoaded);
  allLoadedRef.current = allLoaded;

  const [result, setResult] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const load = React.useCallback(async (newPage: number, hardReset?: boolean) => {
    setPage(newPage);

    // If perform request doesn't return a promise it means we have cancelled
    // request with current params and in that case we reset the hook state
    const promise = performRequestRef.current({
      ...paramsRef.current,
      page: newPage,
    });

    if (promise) {
      setIsLoading(true);

      return promise.then((chunk: E[]) => {
        const idsChunk = chunk.map((elem) => elem[idAttribute]);

        if (newPage === 1) {
          setResult(null);
          if (hardReset) {
            setTimeout(() => {
              setResult(idsChunk);
            }, 0);
          } else {
            setResult(idsChunk);
          }
        } else {
          setResult((prev) => [...(prev || []), ...idsChunk]);
        }
        setAllLoaded(!idsChunk.length);
      }).finally(() => setIsLoading(false));
    }
    setPage(0);
    setResult(null);
    setAllLoaded(false);
    return Promise.resolve();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If param changes, we are going to restart from page 1 only if the API has
  // already been requested before
  React.useEffect(() => {
    load(1, true);
  }, [load, params]);


  // This method loads pages as long as there are more
  const loadMore = React.useCallback(() => {
    if (!allLoadedRef.current) {
      load(pageRef.current + 1, false);
    }
  }, [load]);

  const reset = React.useCallback(async () => {
    await load(1, false);
  }, [load]);

  const resultToReturn = useSelector((state: RootState) => (result ? selector(state, result) : null));

  return {
    page,
    reset,
    loadMore,
    allLoaded,
    isLoading,
    result: resultToReturn,
  };
};
