/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestionType,
} from 'redux/schemas/models/mentoring-program-profile-questions';

export type MentorshipProgramProfileParams = {
  mentorshipProgramId: number;
};

export type GetMentorshipProgramProfileParams = MentorshipProgramProfileParams & {
  type: ProgramProfileQuestionType;
  page: number;
  pageSize: number;
};

export const getMentorshipProgramProfileQuestions = createAsyncThunk<
MentorshipProgramProfileQuestion[],
GetMentorshipProgramProfileParams
>(
  'GET_MENTORSHIP_PROGRAM_PROFILE_QUESTIONS',
  async ({ mentorshipProgramId, type, page, pageSize }) => {
    const response = await axios.get(
      `/mentorship_programs/${mentorshipProgramId}/mentorship_program_profile_questions.json`,
      {
        params: {
          type,
          page,
          page_size: pageSize,
        },
      },
    );
    return response.data.result;
  },
);

export enum ProgramProfileQuestionType {
  ORG_LEVEL = 'org_level_question',
  PROGRAM_LEVEL = 'mentorship_program_level',
}

export type MentorshipProgramOrgLevelQuestion = {
  type: ProgramProfileQuestionType.ORG_LEVEL;
  isRequired: boolean;
  questionId?: number;
};

export type MentorshipProgramProgramLevelQuestion = {
  questionId?: number;
  index: number;
  type: ProgramProfileQuestionType.PROGRAM_LEVEL;
  isRequired: boolean;
  questionType: ProgramLevelQuestionType;
  questionText: string;
  responseOptions: { optionContent: string }[];
};

type CreateMentorshipProgramProfileQuestionsParams = MentorshipProgramProfileParams & {
  mentorshipProgramProfileQuestions: (
    | MentorshipProgramOrgLevelQuestion
    | MentorshipProgramProgramLevelQuestion
  )[];
};

export const createMentorshipProgramProfileQuestions = createAsyncThunk<
MentorshipProgramProfileQuestion[],
CreateMentorshipProgramProfileQuestionsParams
>(
  'CREATE_MENTORSHIP_PROGRAM_PROFILE_QUESTIONS',
  async ({ mentorshipProgramId, mentorshipProgramProfileQuestions }) => {
    const response = await axios.post(
      `/mentorship_programs/${mentorshipProgramId}/mentorship_program_profile_questions.json`,
      {
        mentorshipProgramProfileQuestions,
      },
    );
    return response.data.result;
  },
);

type UpdateMentorshipProgramProfileQuestionParams = MentorshipProgramProfileParams & {
  questionId: number;
  mentorshipProgramProfileQuestion:
  | { isRequired: boolean }
  | MentorshipProgramOrgLevelQuestion
  | MentorshipProgramProgramLevelQuestion;
};

export const updateMentorshipProgramProfileQuestion = createAsyncThunk<
MentorshipProgramProfileQuestion,
UpdateMentorshipProgramProfileQuestionParams
>(
  'UPDATE_MENTORSHIP_PROGRAM_PROFILE_QUESTION',
  async ({
    mentorshipProgramId,
    questionId,
    mentorshipProgramProfileQuestion,
  }) => {
    const response = await axios.put(
      `/mentorship_programs/${mentorshipProgramId}/mentorship_program_profile_questions/${questionId}.json`,
      {
        mentorshipProgramProfileQuestion,
      },
    );
    return response.data.result;
  },
);

type DeleteMentorshipProgramProfileQuestionParams = MentorshipProgramProfileParams & {
  questionId: number;
};

export const deleteMentorshipProgramProfileQuestion = createAsyncThunk<
boolean,
DeleteMentorshipProgramProfileQuestionParams
>(
  'DELETE_MENTORSHIP_PROGRAM_PROFILE_QUESTION',
  async ({ mentorshipProgramId, questionId }) => {
    const response = await axios.delete(
      `/mentorship_programs/${mentorshipProgramId}/mentorship_program_profile_questions/${questionId}.json`,
    );
    return response.data.result;
  },
);

export type NewProgramLevelQuestionDraft = Partial<MentorshipProgramProfileQuestion> & {
  tempId: string;
};

export const resetOrgLevelProfileQuestionsList = createAction<{ resetList: boolean }>('RESET_ORG_LEVEL_PROFILE_QUESTIONS_LIST');
export const resetProgramLevelProfileQuestionsList = createAction<{ resetList: boolean }>('RESET_PROGRAM_LEVEL_PROFILE_QUESTIONS_LIST');
