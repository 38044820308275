import { css } from '@emotion/react';

import { black, gray2, gray4, primary } from 'styles/global_defaults/colors';
import { doubleSpacing, halfSpacing, largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

export const modalStyles = css`
  .bs4-modal-body {
    padding: ${doubleSpacing}px;
  }
`;

export const modalContentStyles = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    max-height: 35px;
  }

  .select-role {
    .select-role-message {
      color: ${black};
      margin-bottom: ${standardSpacing}px;
    }

    .select-role-form {
      display: flex;
      gap: ${halfSpacing}px;
      align-items: center;
    }
  }

  .divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${standardSpacing}px;
    margin: ${largeSpacing}px 0;

    .line {
      height: 1px;
      width: ${largeSpacing}px;
      background-color: ${gray4};
    }

    span {
      color: ${gray2};
    }
  }

  .user-management {
    span {
      color: ${black};
    }

    a {
      color: ${primary};
    }
  }

  .actions {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;
