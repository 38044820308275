import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import NvModal, { ModalType } from 'shared/components/nv-modal';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { darkGray } from 'athena/styles/colors';
import { gray4 } from 'styles/global_defaults/colors';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { enrollUserToProgram } from 'redux/actions/mentoring-program-enrollments';
import AddBySearching from 'athena/components/mentoring-program/participants/add-participants-modal/add-by-searching';
import UserForm, { FormData } from 'athena/components/mentoring-program/participants/add-participants-modal/user-form';
import { MentoringProgramEnrollmentError, MentoringProgramEnrollmentResult } from 'redux/schemas/models/mentoring-program-enrollments';

type Props = {
  show: boolean;
  onClose: (added: boolean) => void;
};

const AddParticipantsModal = (props: Props) => {
  const { show, onClose } = props;

  const dispatch = useAppDispatch();
  const [reachedEnd, setReachedEnd] = React.useState(false);
  const [userAddedBySearching, setUserAddedBySearching] = React.useState(false);
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { programId } = $state.params;

  const styles = css`
    height: 100%;
    overflow-y: auto;

    .or {
      .bar {
        height: 1px;
        width: ${largeSpacing}px;
        background-color: ${gray4};
      }

      .text-body {
        color: ${darkGray};
      }
    }
  `;

  const handleManuallyAddUserSubmit = ({ role, email, firstName, lastName }: FormData) => {
    wrapThunkAction(
      dispatch(enrollUserToProgram({
        role,
        email,
        lastName,
        firstName,
        programId,
      })),
    ).then((action) => {
      const { newlyEnrolled, existing, errors } = action.payload as MentoringProgramEnrollmentResult;
      if (newlyEnrolled?.length) {
        dispatch(addAlertMessage({
          header: t.FORM.SUCCESS_BANG(),
          type: AlertMessageType.SUCCESS,
          message: t.MENTORING_PROGRAMS.PARTICIPANTS.ADD_SUCCESS((action.payload as any).newlyEnrolled.length),
        }));
      }
      const [errorDetails] = errors || [];
      const { error } = errorDetails || {};
      if (existing?.length || error === MentoringProgramEnrollmentError.PROGRAM_ADMIN_CANNOT_CHANGE_ROLE_ERROR) {
        dispatch(addAlertMessage({
          header: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_NOT_ADDED(),
          type: AlertMessageType.WARNING,
          message: t.MENTORING_PROGRAMS.PARTICIPANTS.USER_ALREADY_EXISTS(),
        }));
      }
      onClose(true);
    });
  };

  useEffect(() => {
    if (!show && userAddedBySearching) {
      onClose(true);
      setUserAddedBySearching(false);
    }
  }, [show]);

  return (
    <NvModal
      show={show}
      width={800}
      bodyClassName='p-0'
      type={ModalType.DYNAMIC}
      onClose={() => onClose(false)}
      header={t.MENTORING_PROGRAMS.PARTICIPANTS.ADD()}
      body={(
        <div
          css={styles}
          className='p-5'
          // Not using useInfiniteScroll hook as it doesn't work due to this
          // react bug: https://github.com/facebook/react/issues/20074
          onScroll={(e) => {
            const element = e.target as HTMLDivElement;
            let isReachedLimit = false;

            if (element.scrollHeight > element.offsetHeight) {
              isReachedLimit = element.offsetHeight + element.scrollTop + (70 * 3) >= element.scrollHeight;
            }

            setReachedEnd(isReachedLimit);
          }}
        >
          {show && <UserForm onSubmit={handleManuallyAddUserSubmit} />}
          <div className='or d-flex align-items-center justify-content-center mb-4'>
            <div className='bar mr-2' />
            <div className='text-body bold'>
              {t.SHARED.OR()}
            </div>
            <div className='bar ml-2' />
          </div>
          <AddBySearching reachedEnd={reachedEnd} onAdd={() => setUserAddedBySearching(true)} />
        </div>
      )}
    />
  );
};

export default AddParticipantsModal;
