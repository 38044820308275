import { css } from '@emotion/react';
import moment from 'moment';
import { novoEdBlue } from 'athena/styles/colors';
import { black, danger, shade, white } from 'styles/global_defaults/colors';
import { boldFontWeight, headerFontSize, headerRegularLineHeight, semiBoldFontWeight, textMediumFontSize, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { halfSpacing } from 'styles/global_defaults/scaffolding';


type NvCalendarProps = {
  date: string;
};

const NvCalendar = ({
  date,
}: NvCalendarProps) => {
  const styles = css`
    .month {
      color: ${black};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textMediumFontSize}px;
      line-height: ${textSmallLineHeight}px;
      text-align: center;
    }

    .rings {
      display: flex;
      justify-content: space-evenly;
      position: relative;
      top: ${halfSpacing}px;

      .ring {
        background-color: ${shade};
        width: ${halfSpacing}px;
        height: 17px;
        border-radius: ${halfSpacing}px;
      }
    }

    .calendar {
      display: flex;
      flex-direction: column;
      align-items: center;

      height: 80px;
      width: 80px;
      border-radius: 4px;

      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
    }

    .day-of-week {
      width: 100%;
      text-align: center;
      padding-top: ${halfSpacing}px;

      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      color: ${white};
      background-color: ${novoEdBlue};

      font-weight: ${semiBoldFontWeight};
      font-size: ${textSmallFontSize}px;
      line-height: ${textSmallLineHeight}px;
    }

    .day-of-month {
      color: ${black};
      font-weight: ${boldFontWeight};
      font-size: ${headerFontSize}px;
      line-height: ${headerRegularLineHeight}px;
      padding-top: 8px;
    }

    .red-triangle {
      position: relative;
      left: 27px;
      bottom: 9px;
      height: 0;
      width: 0;
      border-bottom: 14px solid ${danger};
      border-left: 14px solid transparent;
    }
  `;

  return (
    <div css={styles}>
      <div className='month'>{moment(date).format('MMMM')}</div>
      <div className='rings'>
        <div className='ring' />
        <div className='ring' />
      </div>
      <div className='calendar'>
        <div className='day-of-week'>{moment(date).format('ddd').toUpperCase()}</div>
        <div className='day-of-month'>{moment(date).format('D')}</div>
        <div className='red-triangle' />
      </div>
    </div>
  );
};

export default NvCalendar;
