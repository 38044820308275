import t from 'react-translate';
import MaxMenteesSetting from './max-mentees-setting';

const ProgramConnections = () => (
  <div className='mb-4'>
    <div className='heading-5 mb-2'>
      {t.MENTORING_PROGRAMS.CONNECTIONS.TITLE()}
    </div>
    <MaxMenteesSetting name='maxMentees' />
  </div>
);

export default ProgramConnections;
