import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { css } from '@emotion/react';
import { config } from '@config/pendo.config.json';
import CollapsibleRowMenu, { CollapsibleRowMenuButton } from 'components/collapsible-row-menu';

const styles = css`
  position: absolute;
  right: 0;
  top: ${standardSpacing}px;
`;

type OptionsMenuProps = {
  onDelete: () => void;
};

const OptionsMenu = ({ onDelete }: OptionsMenuProps) => (
  <CollapsibleRowMenu css={styles}>
    <>
      <CollapsibleRowMenuButton
        icon='trash'
        onClick={onDelete}
        data-qa={
            config.pendo.athena.mentorshipProgram.settings.matchingRules
              .deleteMatchingRule
          }
      />
    </>
  </CollapsibleRowMenu>
);

export default OptionsMenu;
