import t from 'react-translate';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  CompletionStatus,
  StepInfo,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { AngularContext } from 'react-app';
import ProfileModal from './profile-modal';
import RequiredQuestionsModal from '../../required-questions-modal';

const CompleteProfileButton = ({ status }: StepInfo) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { showProfile = false } = $state.params || {};
  const [showProfileModal, setShowProfileModal] = useState<boolean>(showProfile);
  const [showRequiredQuestionsModal, setShowRequiredQuestionsModal] = useState<boolean>(false);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const ctaSettings = {
    [CompletionStatus.NOT_STARTED]: {
      buttonTitle: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.GET_STARTED(),
    },
    [CompletionStatus.IN_PROGRESS]: {
      buttonTitle: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.CONTINUE(),
    },
    [CompletionStatus.COMPLETED]: {
      buttonTitle: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.EDIT_PROFILE(),
    },
  };

  const { buttonTitle } = ctaSettings[status];

  return (
    <>
      <Button onClick={() => setShowProfileModal(true)}>{buttonTitle}</Button>
      {showProfileModal && (
        <ProfileModal
          title={showProfile && t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.DEFAULT()}
          setShowModal={setShowProfileModal}
          onClose={(remaining) => {
            setRemainingQuestions(remaining);
            setShowRequiredQuestionsModal(true);
          }}
        />
      )}
      {showRequiredQuestionsModal && (
        <RequiredQuestionsModal
          show={showRequiredQuestionsModal}
          remainingQuestions={remainingQuestions}
          onClose={() => setShowRequiredQuestionsModal(false)}
        />
      )}
    </>
  );
};

export default CompleteProfileButton;
