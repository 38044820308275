import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { OrgLevelQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';

type OrgFieldProps = {
  field: MentorshipProgramProfileAnswer;
};

const OrgField = ({ field }: OrgFieldProps) => {
  const { profileQuestion, answer } = field;
  const { profileSetting } = (profileQuestion as OrgLevelQuestion) || {};
  const { name: questionText, isHidden } = profileSetting || {};

  return (
    answer && !isHidden && (
      <>
        <div className='gray-2 text-small mb-1'>{questionText}</div>
        <div className='mb-1 text-regular heading-5'>{answer}</div>
      </>
    )
  );
};

type OrgLevelInfoProps = {
  endRef: (node?: Element | null) => void;
  orgInfo: MentorshipProgramProfileAnswer[];
};

const OrgLevelInfo = ({ endRef, orgInfo }: OrgLevelInfoProps) => (
  <>
    <div className='px-5 py-4 info-container'>
      {orgInfo?.map(field => (<OrgField key={field.id} field={field} />))}
    </div>
    <div ref={endRef} />
  </>
);

export default OrgLevelInfo;
