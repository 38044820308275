import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import ProgramLevelField from './program-level-field';
import { useInView } from 'react-intersection-observer';
import { GetProgramProfileQuestionParams } from '../../types';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { MutableRefObject, useContext, useEffect, useMemo, useState } from 'react';
import { DRAFT_PREFIX, PAGE_SIZE } from 'athena/components/mentoring-program/constants';
import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestion,
  QuestionType,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import {
  getMentorshipProgramProfileQuestions,
  ProgramProfileQuestionType,
  resetProgramLevelProfileQuestionsList,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile-questions';
import {
  getMentorshipProgramProfileQuestionsList,
  getResetProgramLevelProfileQuestionsListState,
} from 'redux/selectors/mentorship-program-profile-questions';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import ProgramLevelHeaders from './program-level-headers';
import AddQuestionButton from './add-question-button';

const getQuestionList = (question: MentorshipProgramProfileQuestion) => {
  const { profileQuestion } = question;
  const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};
  return questionList;
};

const getNewQuestionDraft = () => ({
  tempId: `${DRAFT_PREFIX}-${uuid()}`,
  questionType: QuestionType.QUESTION,
});

type ProgramLevelListProps = {
  programLevelRef: MutableRefObject<any>;
};

const ProgramLevelList = ({ programLevelRef }: ProgramLevelListProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const [draftQuestions, setDraftQuestions] = useState([]);
  const resetList = useSelector(getResetProgramLevelProfileQuestionsListState);

  const { ref: endRef, inView } = useInView();
  const onAddDraftQuestion = () => setDraftQuestions(currentDraftQuestions => ([...currentDraftQuestions, getNewQuestionDraft()]));
  const onDeleteDraftQuestion = (questionId: string) => setDraftQuestions(currentDraftQuestions => (currentDraftQuestions.filter(item => item.tempId !== questionId)));

  const params = useMemo(
    () => ({
      type: ProgramProfileQuestionType.PROGRAM_LEVEL,
      pageSize: PAGE_SIZE,
    }),
    []
  );

  const {
    reset,
    result: programLevelQuestions,
    loadMore,
  } = usePaginatedApi<
    MentorshipProgramProfileQuestion,
    GetProgramProfileQuestionParams
  >(
    (p) =>
      dispatch(
        getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p })
      ).then((action) => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList
  );
  const sortByQuestionIndex = (
    firstQuestion: MentorshipProgramProfileQuestion,
    secondQuestion: MentorshipProgramProfileQuestion
  ) => {
    const { questionIndex: firstQuestionIndex } =
      getQuestionList(firstQuestion) || {};
    const { questionIndex: secondQuestionIndex } =
      getQuestionList(secondQuestion) || {};
    return firstQuestionIndex - secondQuestionIndex;
  };
  const questionsSortedByQuestionIndex = useMemo(
    () => programLevelQuestions?.sort(sortByQuestionIndex),
    [programLevelQuestions]
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  useEffect(() => {
    if (resetList) {
      reset();
      dispatch(resetProgramLevelProfileQuestionsList({ resetList: false }));
    }
  }, [resetList]);

  const updateQuestionIndex = (
    question: MentorshipProgramProfileQuestion,
    newQuestionIndex: number
  ) => {
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    wrapThunkAction(
      dispatch(
        updateMentorshipProgramProfileQuestion({
          questionId: question.id,
          mentorshipProgramId,
          mentorshipProgramProfileQuestion: {
            index: newQuestionIndex,
            isRequired: question.isRequired,
            questionText: getQuestionList(question).questionText,
            questionType: getQuestionList(question).type,
            type: ProgramProfileQuestionType.PROGRAM_LEVEL,
            responseOptions: getQuestionList(question).responseOptions.map(
              (option) => ({ optionContent: option.optionContent })
            ),
          },
        })
      )
    )
      .then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  const swapQuestionIndex = (index: number, newIndex: number) => {
    const questionAtIndex = questionsSortedByQuestionIndex[index];
    const questionAtNewIndex = questionsSortedByQuestionIndex[newIndex];
    const firstQuestionIndex = getQuestionList(questionAtIndex)?.questionIndex;
    const secondQuestionIndex =
      getQuestionList(questionAtNewIndex)?.questionIndex;
    if (firstQuestionIndex !== undefined && secondQuestionIndex !== undefined) {
      updateQuestionIndex(questionAtIndex, secondQuestionIndex);
      updateQuestionIndex(questionAtNewIndex, firstQuestionIndex);
    }
  };

  return (
    <>
      <ProgramLevelHeaders />
      {[...(questionsSortedByQuestionIndex || []), ...draftQuestions]?.map(
        (question, index) => {
          if (question) {
            return (
              <ProgramLevelField
                ref={(programLevelFieldRef) => {
                  programLevelRef.current[index] = programLevelFieldRef;
                }}
                key={index}
                index={index}
                question={question}
                canMoveUp={index !== 0 && !question?.tempId}
                onMoveUp={() => swapQuestionIndex(index, index - 1)}
                canMoveDown={
                  index !== questionsSortedByQuestionIndex?.length - 1 &&
                  !question.tempId
                }
                onMoveDown={() => swapQuestionIndex(index, index + 1)}
                reset={reset}
                onDeleteDraftQuestion={onDeleteDraftQuestion}
              />
            );
          }
          return null;
        }
      )}
      <div ref={endRef} />
      <AddQuestionButton onClick={onAddDraftQuestion} />
    </>
  );
};

export default ProgramLevelList;
