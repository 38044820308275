/* eslint-disable import/prefer-default-export */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AutoMatchingRule } from 'redux/schemas/models/mentoring-program-matching-rules';
import {
  resetOrgLevelProfileQuestionsList,
  resetProgramLevelProfileQuestionsList,
} from './mentoring-program-profile-questions';

type MentorshipProgramParams = {
  mentorshipProgramId: number;
};

export const getMentorshipProgramAutoMatchingRulesTotalCount = createAsyncThunk<
{ autoMatchingRulesCount: number },
MentorshipProgramParams
>(
  'GET_MENTORSHIP_PROGRAM_AUTO_MATCHING_RULES_COUNT',
  async ({ mentorshipProgramId }) => {
    const response = await axios.get(
      `/mentorship_programs/${mentorshipProgramId}/auto_matching_rules/count.json`,
    );
    return response.data.result;
  },
);

type AutoMatchingRulesParams = MentorshipProgramParams & {
  page: number;
  pageSize: number;
};
export const getMentorshipProgramAutoMatchingRules = createAsyncThunk<
AutoMatchingRule[],
AutoMatchingRulesParams
>(
  'GET_MENTORSHIP_PROGRAM_AUTO_MATCHING_RULES',
  async ({ page, pageSize, mentorshipProgramId }) => {
    const response = await axios.get(
      `/mentorship_programs/${mentorshipProgramId}/auto_matching_rules.json`,
      {
        params: {
          page,
          page_size: pageSize,
        },
      },
    );
    return response.data.result;
  },
);

type AutoMatchingRuleParams = MentorshipProgramParams & {
  autoMatchingRule: Pick<
  AutoMatchingRule,
  'responseType' | 'expression' | 'importanceLevel'
  > & {
    mentorshipProgramProfileQuestionId: number;
  };
};

export const createMentorshipProgramAutoMatchingRule = createAsyncThunk<
AutoMatchingRule,
AutoMatchingRuleParams
>(
  'CREATE_MENTORSHIP_PROGRAM_AUTO_MATCHING_RULE',
  async ({ mentorshipProgramId, autoMatchingRule }, thunkAPI) => {
    const response = await axios.post(
      `/mentorship_programs/${mentorshipProgramId}/auto_matching_rules.json`,
      {
        autoMatchingRule,
      },
    );
    thunkAPI.dispatch(resetOrgLevelProfileQuestionsList({ resetList: true }));
    thunkAPI.dispatch(resetProgramLevelProfileQuestionsList({ resetList: true }));
    return response.data.result;
  },
);

type UpdateAutoMatchingRuleParams = AutoMatchingRuleParams & {
  ruleId: number;
};

export const updateMentorshipProgramAutoMatchingRule = createAsyncThunk<
AutoMatchingRule,
UpdateAutoMatchingRuleParams
>(
  'UPDATE_MENTORSHIP_PROGRAM_AUTO_MATCHING_RULE',
  async ({ mentorshipProgramId, ruleId, autoMatchingRule }) => {
    const response = await axios.put(
      `/mentorship_programs/${mentorshipProgramId}/auto_matching_rules/${ruleId}.json`,
      {
        autoMatchingRule,
      },
    );
    return response.data.result;
  },
);

type DeleteAutoMatchingRuleParams = MentorshipProgramParams & {
  ruleId: number;
};

export const deleteMentorshipProgramAutoMatchingRule = createAsyncThunk<
boolean,
DeleteAutoMatchingRuleParams
>(
  'DELETE_MENTORSHIP_PROGRAM_AUTO_MATCHING_RULE',
  async ({ mentorshipProgramId, ruleId }) => {
    const response = await axios.delete(
      `/mentorship_programs/${mentorshipProgramId}/auto_matching_rules/${ruleId}.json`,
    );
    return response.data.result;
  },
);

export const resetAutoMatchingRulesList = createAction<{ resetList: boolean }>('RESET_AUTO_MATCHING_RULES_LIST');
