import { css } from '@emotion/react';
import { threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

export const ROW_HEIGHT = 70;

// eslint-disable-next-line import/prefer-default-export
export const commonStyles = css`
  height: ${ROW_HEIGHT}px;
  padding-top: ${threeQuartersSpacing}px;
  padding-bottom: ${threeQuartersSpacing}px;
`;
