import { schema } from 'normalizr';
import { MentorshipProgramConnection } from 'redux/schemas/models/mentorship-program-connections';

export const MentorshipProgramConnectionSchema = new schema.Entity<
MentorshipProgramConnection
>('mentorshipProgramConnections');

export const MentorshipProgramConnectionsSchema = new schema.Array(
  MentorshipProgramConnectionSchema,
);
