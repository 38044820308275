import { css } from '@emotion/react';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import {
  quarterSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { almostBlack, paleGray } from 'athena/styles/colors';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import NvIcon from 'shared/components/nv-icon';
import Avatar from '../../avatar';
import { CellProps } from './types';
import { commonStyles } from './styles';

const styles = css`
  ${commonStyles};
  .avatar {
    &:not(:first-of-type) {
      margin-left: -${quarterSpacing}px;
    }
  }
  .participant-avatars {
    display: inline-flex;
    margin-right: ${threeQuartersSpacing}px;
  }
  .participant-names {
    gap: ${threeQuartersSpacing}px;
    overflow: hidden;

    i {
      color: ${paleGray};
    }
  }
  .name {
    color: ${almostBlack};
    font-weight: ${semiBoldFontWeight};
  }
`;

const ConnectionCell = (props: CellProps) => {
  const {
    cell: { row },
    className,
  } = props;
  const {
    original: { mentorshipProgramConnectionMembers },
  } = row;

  const getEnrollmentsByRole = (role: MentoringProgramRole) => mentorshipProgramConnectionMembers
    .filter(member => member.mentorshipProgramEnrollment.role.name === role)
    .map(member => member.mentorshipProgramEnrollment);

  const mentors = getEnrollmentsByRole(
    MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
  );
  const mentees = getEnrollmentsByRole(
    MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
  );

  return (
    <div className={`${className} d-flex`} css={styles}>
      <div className='participant-avatars'>
        {[...mentors, ...mentees].map(participant => (
          <Avatar
            key={participant.id}
            user={participant.user}
            role={participant.role.name}
            className='avatar position-relative'
          />
        ))}
      </div>
      <div className='participant-names d-flex align-items-center'>
        {mentors.map(mentor => (
          <span key={mentor.id} className='name ellipsis text-medium'>
            {mentor.user.fullName}
          </span>
        ))}
        <NvIcon icon='link' size='smallest' />
        {mentees.map(mentee => (
          <span key={mentee.id} className='name ellipsis text-medium'>
            {mentee.user.fullName}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ConnectionCell;
