import { createSelector } from 'reselect';

import { RootState } from 'redux/schemas';
import { MentorshipProgramConnection } from 'redux/schemas/models/mentorship-program-connections';

export const getCreateConnectionModalSettings = (
  state: RootState,
) => state.app.connections.createConnectionModal;

export const getMentoringProgramConnectionsList = createSelector(
  (state: RootState) => state.models.mentorshipProgramConnections,
  (state, ids: number[]) => ids,
  (mentorshipProgramConnections: Record<number, MentorshipProgramConnection>, ids: number[]) => ids.map((id) => mentorshipProgramConnections[id]),
);
