import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { getCourse } from 'redux/selectors/course';
import { enrollWithRole } from 'redux/actions/users';
import { getCurrentUser } from 'redux/selectors/users';
import { getCourseRoles } from 'redux/selectors/roles';
import { FullCourse } from 'redux/schemas/models/courseFull';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { aliases, getTranslateKey } from 'shared/services/i18n-utils';
import { EnrollWithRoleParams } from 'redux/schemas/app/course-access-modal';
import { getCourseAccessModalProps } from 'redux/selectors/course-access-modal';
import { resetCourseAccessModalProps } from 'redux/actions/course-access-modal';
import { halfSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';
import ManageOfferingAccessModal from 'institutions/components/manage-offering-access-modal';

const popOverStyles = css`
  .permissions-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: ${halfSpacing}px;
  }

  .permissions {
    > div {
      margin-bottom: ${halfSpacing}px;

      .permission-name {
        font-weight: bold;
        margin-right: ${quarterSpacing}px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

function ManageCourseAccessModal() {
  const dispatch = useAppDispatch();
  const roles = useSelector(getCourseRoles);
  const currentUser = useSelector(getCurrentUser);
  const [showModal, setShowModal] = React.useState(false);
  const { catalogId } = useSelector(getCourseAccessModalProps);
  const course = useSelector(state => getCourse(state, catalogId));

  const { $scope, injectServices } = React.useContext(AngularContext);

  const [
    $state,
    $timeout,
    RailsRoutes,
    CurrentUserManager,
  ] = injectServices([
    '$state',
    '$timeout',
    'RailsRoutes',
    'CurrentUserManager',
  ]);

  React.useEffect(() => {
    if (catalogId) {
      setShowModal(true);
    }
  }, [catalogId]);

  const closeModal = () => setShowModal(false);

  // useEffect to cleanup modal props in redux store only when unmount
  React.useEffect(() => () => closeModal(), []);

  const resetModalProps = () => {
    dispatch(resetCourseAccessModalProps());
  };

  const handleModalClose = () => {
    closeModal();
    resetModalProps();
  };

  const handleEnroll = (selectedRoleValue) => {
    const params: EnrollWithRoleParams = {
      catalogId,
      courseRoleId: selectedRoleValue,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
    };

    return wrapThunkAction(dispatch(enrollWithRole(params)))
      .then((action) => {
        $timeout(() => {
          CurrentUserManager.addUserCourse(action.payload);
          CurrentUserManager.user.enrollments.push(action.payload);
        });
        $scope.CurrentCourseManager.requestCourse(catalogId, true).then((enrolledCourse: FullCourse) => {
          if (enrolledCourse?.userCourse?.termsAcceptanceNeeded) {
            window.location.href = RailsRoutes.termsPath(catalogId);
          } else if (course?.isProgram) {
            $state.go('program-home', { catalogId });
          } else {
            $state.go('course-home', { catalogId });
          }

          handleModalClose();
        });
      });
  };

  const userManagementLink: string = $state.href('user-management-course-admins', { catalogId });

  const isCourseFlyer = $state?.current?.name === 'course-flyer';

  return (
    <ManageOfferingAccessModal
      type='course'
      show={showModal}
      onEnroll={handleEnroll}
      onClose={handleModalClose}
      managementLink={userManagementLink}
      managementLinkNewTab={!isCourseFlyer}
      roles={roles.map((role: CourseRole) => ({
        label: role.name,
        value: role.id,
        renderInfo: () => {
          const permissions = role?.permissions.filter(Boolean);

          if (!permissions?.length) {
            return <span>{t.INSTITUTIONS.ROLES.LEARNER_ROLE_DESC()}</span>;
          }

          return (
            <div css={popOverStyles}>
              <div className='permissions-title'>{t.INSTITUTIONS.ROLES.PERMISSIONS()}</div>
              <div className='permissions'>
                {permissions.map(permission => (
                  <div key={permission.type}>
                    <span className='permission-name'>
                      {permission?.name
                        ? t.INSTITUTIONS.ROLES[`${getTranslateKey(permission.name)}`](aliases)
                        : ''}
                      :
                    </span>
                    <span>
                      {t.INSTITUTIONS.ROLES[`${getTranslateKey(permission.helpText)}`](aliases)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );
        },
      }))}
    />
  );
}

export default ManageCourseAccessModal;
