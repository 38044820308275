export enum FlyoutNames {
  ADMIN_TOOLS = 'admintools',
  COURSE_MENTEES = 'mentees',
  MY_PROGRAMS = 'myPrograms',
  MY_LEARNING = 'mylearning',
  TEAMS_GROUPS = 'teamsgroups',
  PRACTICE_ROOM = 'practiceroom',
  BOOKMARKS = 'bookmarks',
  SEARCH = 'search',
}

export const flyoutConfig = {
  admintools: {
    controller: 'AdminToolsFlyoutCtrl as vm',
    template: 'lhs/templates/admin-tools-flyout-react-app.html',
    isNavigational: true,
  },
  mentees: {
    controller: 'CourseMenteesFlyoutCtrl as vm',
    template: 'lhs/templates/course-mentees-flyout-react-app.html',
    isNavigational: true,
  },
  myPrograms: {
    controller: 'MyProgramsFlyoutCtrl as vm',
    template: 'athena/templates/my-programs-flyout-react-app.html',
    isNavigational: true,
  },
  mylearning: {
    controller: 'MyLearningFlyoutCtrl as vm',
    template: 'lhs/templates/my-learning-flyout-react-app.html',
    isNavigational: true,
  },
  teamsgroups: {
    controller: 'TeamsGroupsFlyoutCtrl as vm',
    template: 'lhs/templates/teams-groups-flyout-react-app.html',
    isNavigational: true,
  },
  practiceroom: {
    controller: 'PracticeRoomFlyoutReactCtrl as vm',
    template: 'practice_room/templates/flyout-react-app.html',
    isNavigational: true,
  },
  search: {
    controller: 'ContentSearchFlyoutCtrl as vm',
    template: 'content_search/templates/content-search-flyout-react-app.html',
    isNavigational: false,
  },
  bookmarks: {
    controller: 'BookmarksFlyoutCtrl as vm',
    template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
    isNavigational: false,
  },
};

export default flyoutConfig;
