import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import t from 'react-translate';
import { Result } from 'redux/schemas/api';
import {
  GetSubmissionsParams,
  UpdateCourseNameAndEmailParams,
  UpdateOrgNameAndEmailParams,
  ResetVideoPracticeSubmissionsParams,
  ResetQuestionSetSubmissionsParams,
  SendMessageParams,
  AddUsersToCourseParam,
  SearchCourseUsersParams,
  RemoveUsersFromCourseParam,
  SearchCourseOrgUsersParams,
  SearchCourseOrgUsersResponse,
  UpdateEmailForNewSSOUser,
} from 'redux/schemas/api/user';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { EnrollWithRoleParams } from 'redux/schemas/app/course-access-modal';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import { NameAndEmailFormSource } from 'redux/schemas/models/user';
import { InfiniteTableLoadingParams } from 'redux/create-action-creators';
import { omit } from 'underscore';
import { addAlertMessage } from './alert-messages';
import { User, Enrollment, MyAccount, CourseUser } from '../schemas/models/my-account';
import { unsetAllCachedLecturePages } from './lecture-pages';

export type UsersState = { [id: string]: User };

function fetchMyAccount(prefetchedUser: MyAccount | {}): Promise<MyAccount> {
  // Check for preloaded user information to avoid additional requests
  if (Object.keys(prefetchedUser).length !== 0) {
    return Promise.resolve(prefetchedUser as MyAccount);
  }

  // Make the request if the user information needs to be updated
  return axios.get<MyAccount>('/my_account.json', {
    params: {
      mentoring_info: 1,
    },
  }).then(response => response.data);
}

type ColorList = string[];
export const updateRecentlyUsedColors = createAsyncThunk(
  'UPDATE_RECENTLY_USED_COLORS',
  (colors: ColorList) => axios
    .put('/users/update_recently_used_colors.json', {
      recentlyUsedColors: colors,
    })
    .then(response => response.data.result),
);

export const getMyAccount = createAsyncThunk(
  'GET_MY_ACCOUNT',
  fetchMyAccount,
);

export const updateHasViewedRteFte = createAsyncThunk(
  'UPDATE_HAS_VIEWED_RTE_FTE',
  () => axios.put('/users/update_has_viewed_rte_fte.json').then(response => response.data),
);

/**
 * Calling this action with "Basic" because there's a similar endpoint but the
 * other one needs a course context, it's url is:
 * `/${catalogId}/save_profile.json` and that one supports answering course
 * profile questions.
 */
export const updateBasicUserProfile = createAsyncThunk(
  'UPDATE_BASIC_USER_PROFILE',
  (basicProfileForm: {
    user: {
      bio?: string,
      headline?: string,
      lastName?: string,
      location?: string,
      firstName?: string,
    },
    profileResponse?: {
      answers: OrgProfileField[],
    },
  }) => axios.put(
    '/save_profile.json',
    basicProfileForm,
  ).then((response) => response.data),
);

export const getBasicUserInfo = createAsyncThunk(
  'GET_USER_INFO',
  ({
    userId,
    forOrgLevelProfile,
  }: { userId: number, forOrgLevelProfile: boolean }) => axios.get(
    `users/basic_info/${userId}`,
    {
      params: {
        for_org_profile: forOrgLevelProfile ? true : undefined,
      },
    },
  ).then((response) => response.data.result.data),
);

export const setProfilePicture = createAsyncThunk(
  'SET_PROFILE_PICTURE',
  (payload: {
    image: File,
    userId: number,
  }) => {
    const formData = new FormData();
    formData.append('picture', payload.image);
    formData.append('user[user]', payload.userId.toString());

    return axios.put(
      '/update_profile_pic',
      formData,
    ).then((response) => response.data.result.profilePicture);
  },
);

export const updateNameAndEmail = createAsyncThunk(
  'UPDATE_USER_NAME_AND_EMAIL',
  async (params: UpdateOrgNameAndEmailParams | UpdateCourseNameAndEmailParams) => {
    try {
      const url = (params.source === NameAndEmailFormSource.COURSE
        || params.source === NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION)
        ? `${(params as UpdateCourseNameAndEmailParams).catalogId}/user_management/update_course_user_details.json`
        : `institutions/${(params as UpdateOrgNameAndEmailParams).institutionId}/user_management/update_user_details.json`;

      const response = await axios.post(url, params);
      return response?.data?.result;
    } catch (error) {
      return error?.response?.data?.error;
    }
  },
);

export const getQuestionSetSubmissions = createAsyncThunk(
  'GET_QUESTION_SET_SUBMISSIONS',
  (params: GetSubmissionsParams, thunkAPI) => axios.get(
    `/${params.catalogId}/question_set_submissions/attempts/${params.userId}.json`,
  ).then((response) => response.data.result)
    .catch((error) => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    }),
);

export const getVideoPracticeSubmissions = createAsyncThunk(
  'GET_VIDEO_PRACTICE_SUBMISSIONS',
  (params: GetSubmissionsParams, thunkAPI) => axios.get(
    `/${params.catalogId}/video_practice_submissions/attempts/${params.userId}.json`,
  ).then((response) => response.data.result)
    .catch((error) => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    }),
);

export const resetVideoPracticeSubmission = createAsyncThunk(
  'RESET_VIDEO_PRACTICE_SUBMISSION',
  async (params: ResetVideoPracticeSubmissionsParams) => axios.post(
    `/${params.catalogId}/video_practice_submissions/reset_attempt/${params.userId}.json`,
    params,
  ).then((response) => response.data),
);

export const resetQuestionSetSubmission = createAsyncThunk(
  'RESET_VIDEO_PRACTICE_SUBMISSION',
  async (params: ResetQuestionSetSubmissionsParams) => axios.post(
    `${params.catalogId}/question_set_submissions/reset_attempt.json`,
    params,
  ).then((response) => response.data),
);

export const updateEnrollment = createAction<{
  id: number,
  patch: Partial<Omit<Enrollment, 'id'>>,
}>('UPDATE_ENROLLMENT');

export const removeUserEnrollment = createAction<{
  userId: number,
  enrollmentId: number,
}>('REMOVE_USER_ENROLLMENT');

export const setCurrentUserEnrollments = createAction<Enrollment[]>(
  'SET_CURRENT_USER_ENROLLMENTS',
);

export const fetchCurrentUserEnrollments = createAsyncThunk(
  'FETCH_CURRENT_USER_ENROLLMENTS',
  async (params, thunkAPI) => {
    const response = await axios.get('/my_account.json', {
      params: {
        enrollments_only: 1,
      },
    });

    thunkAPI.dispatch(setCurrentUserEnrollments(response.data.enrollments));

    return response.data.enrollments;
  },
);

export const enrollWithRole = createAsyncThunk<unknown, EnrollWithRoleParams>(
  'ENROLL_WITH_ROLE',
  async (params, thunkAPI) => {
    const { catalogId, ...restParams } = params;
    const response = await axios.post(`${catalogId}/user_management/enroll`, restParams);
    return response.data.result;
  },
);

export const sendMessage = createAsyncThunk(
  'SEND_MESSAGE',
  async (params: SendMessageParams) => {
    const { catalogId, ...restParams } = params;
    const response = await axios.post(`${catalogId}/conversations/create.json`, restParams);
    return response.data.result;
  },
);

export const translateLecturePage = createAsyncThunk(
  'TRANSLATE_LECTURE_PAGE',
  async ({ language, catalogId, lecturePageId }: {
    language: string,
    catalogId: string,
    lecturePageId: number,
  }, thunkAPI) => {
    let response;
    try {
      response = await axios.post(
        `/courses/${catalogId}/lecture_pages/${lecturePageId}/translate`,
        {
          language,
        },
      );
    } catch (e) {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.AUTOMATED_TRANSLATION.ERROR(),
        message: t.AUTOMATED_TRANSLATION.RETRY(),
      }));

      throw e;
    }

    thunkAPI.dispatch(unsetAllCachedLecturePages());

    return response.data.result;
  },
);

export const translateOutline = createAsyncThunk(
  'TRANSLATE_OUTLINE',
  async (params: any, thunkAPI) => axios.post(
    `/courses/${params.catalogId}/translate_outline`,
    {
      language: params.language,
    },
  ).then(res => res.data.result)
    .catch(() => {
      thunkAPI.dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.AUTOMATED_TRANSLATION.ERROR(),
        message: t.AUTOMATED_TRANSLATION.RETRY(),
      }));
    }),
);

export const unsetTranslationPreference = createAsyncThunk(
  'UNSET_TRANSLATION_PREFERENCE',
  async (params, thunkAPI) => {
    const response = await axios.delete('/remove_translation_preference');

    thunkAPI.dispatch(unsetAllCachedLecturePages());

    return response.data.result;
  },
);

export const translateJourney = createAsyncThunk(
  'TRANSLATE_JOURNEY_OUTLINE',
  async (params: any, thunkAPI) => {
    if (params.language) {
      return axios.post(`/journeys/${params.catalogId}/translate`,
        {
          language: params.language,
        }).then(res => res.data.result)
        .catch(() => {
          thunkAPI.dispatch(addAlertMessage({
            type: AlertMessageType.ERROR,
            header: t.AUTOMATED_TRANSLATION.ERROR(),
            message: t.AUTOMATED_TRANSLATION.RETRY(),
          }));
        });
    }
    return null;
  },
);

export const addUsersToCourse = createAsyncThunk(
  'ADD_USERS_TO_COURSE',
  async (params: AddUsersToCourseParam, { dispatch, rejectWithValue }) => axios.post(`/${params.catalogId}/user_management.json`, params).then(
    (response) => response.data.result,
  ).catch((e) => {
    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.ERROR_SOMETHING_WRONG(),
    }));

    return rejectWithValue(e.response.data.error);
  }),
);

export const removeUsersFromCourse = createAsyncThunk(
  'REMOVE_USERS_FROM_COURSE',
  async (params: RemoveUsersFromCourseParam, { dispatch, rejectWithValue }) => (
    axios.post(`/${params.catalogId}/user_management/unenroll.json`, params).then(
      (response) => response.data.result,
    )).catch((e) => {
    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.ERROR_SOMETHING_WRONG(),
    }));

    return rejectWithValue(e.response.data.error);
  }),
);

export const searchCourseUsers = createAsyncThunk(
  'SEARCH_COURSE_USERS',
  async (params: SearchCourseUsersParams & InfiniteTableLoadingParams<CourseUser>, { dispatch, rejectWithValue }) => {
    const payload = omit({ ...params, page: params.pageIndex }, 'pageIndex', 'catalogId');

    return (axios.post<Result<{ users: CourseUser[], count: number }>>(`/${params.catalogId}/search/course_users.json`, payload)
      .then(res => ({
        response: res.data.result.users,
        totalCount: res.data.result.count,
      }))
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })
    );
  },
);


export const searchCourseOrgUsers = createAsyncThunk<SearchCourseOrgUsersResponse, SearchCourseOrgUsersParams>(
  'SEARCH_COURSE_ORG_USERS',
  async (params: SearchCourseOrgUsersParams, { dispatch, rejectWithValue }) => {
    const payload = omit({ ...params }, 'catalogId');

    return (axios.post<Result<{ users: User[], count: number }>>(`/${params.catalogId}/search/organization_users.json`, payload)
      .then(response => response.data.result)
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      })
    );
  },
);

type SearchOrgUsersForProgramParams = {
  page: number;
  pageSize: number;
  queryTerm: string;
  programId: number;
};

export const searchOrgUsersForProgram = createAsyncThunk<User[], SearchOrgUsersForProgramParams>(
  'SEARCH_ORG_USERS_FOR_PROGRAM',
  async (params) => {
    const response = await axios.get(`/mentorship_programs/${params.programId}/mentorship_program_enrollment_management/search.json`, {
      params: {
        page: params.page,
        page_size: params.pageSize,
        text_search: params.queryTerm,
      },
    });

    return response.data.result;
  },
);

export const updateSystemGeneratedEmail = createAsyncThunk(
  'UPDATE_SYSTEM_GENERATED_EMAIL',
  async (params: UpdateEmailForNewSSOUser, { dispatch, rejectWithValue }) => {
    const payload = { ...params };

    return (axios.put('/save_profile.json', payload)
      .then(response => response.data.result)
      .catch((e) => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.ERROR_SOMETHING_WRONG(),
        }));

        return rejectWithValue(e.response.data.error);
      }));
  },
);

export const setShowUpdateEmailAlert = createAction<boolean>('SET_SHOW_UPDATE_EMAIL_ALERT');

export const patchUser = createAction<Partial<MyAccount>>('PATCH_USER');

export const resetPassword = createAsyncThunk<boolean, string>(
  'RESET_USER_PASSWORD',
  async (email) => {
    const response = await axios.post('/users/password.json', {
      user: {
        email,
      },
    });

    return response.data.result;
  },
);
