import { createReducer } from '@reduxjs/toolkit';
import {
  createMentorshipProgramProfileQuestions,
  deleteMentorshipProgramProfileQuestion,
  getMentorshipProgramProfileQuestions,
  resetOrgLevelProfileQuestionsList,
  resetProgramLevelProfileQuestionsList,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile-questions';
import { normalize } from 'normalizr';
import { MentorshipProgramProfileQuestionsSchema } from 'redux/schemas/api/mentoring-program-profile-questions';
import { replaceArrays } from 'shared/lodash-utils';
import { mergeWith } from 'lodash';
import { initialRootState } from '.';

const setProgramProfileActionHandler = (state, action) => {
  const normalized = normalize(
    action.payload,
    MentorshipProgramProfileQuestionsSchema,
  );

  mergeWith(state.models, normalized.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(
      getMentorshipProgramProfileQuestions.fulfilled,
      setProgramProfileActionHandler,
    )
    .addCase(
      createMentorshipProgramProfileQuestions.fulfilled,
      setProgramProfileActionHandler,
    )
    .addCase(
      updateMentorshipProgramProfileQuestion.fulfilled,
      (state, action) => {
        const { questionId } = action.meta.arg;
        state.models.mentorshipProgramProfileQuestions[questionId] = action.payload;
      },
    )
    .addCase(
      deleteMentorshipProgramProfileQuestion.fulfilled,
      (state, action) => {
        const { questionId } = action.meta.arg;
        delete state.models.mentorshipProgramProfileQuestions[questionId];
      },
    )
    .addCase(
      resetOrgLevelProfileQuestionsList, (state, action) => {
        const { resetList } = action.payload;
        state.app.mentoringProgram.settings.profile.orgLevel.resetList = resetList;
      },
    )
    .addCase(
      resetProgramLevelProfileQuestionsList, (state, action) => {
        const { resetList } = action.payload;
        state.app.mentoringProgram.settings.profile.programLevel.resetList = resetList;
      },
    );
});
