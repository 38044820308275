import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import moment from 'moment';
import MentoringProgramContext from 'athena/components/mentoring-program/context';

import { css } from '@emotion/react';
import { novoEdBlack } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';

import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import ConnectionInfo from './connection-info';
import NoConnection from './no-connection';

const ConnectionDetails = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const {
    id: mentorshipProgramId,
    mentorshipProgramEnrollment: enrollmentId,
    matchAnnouncementDate,
  } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(
    (state) => getMentoringProgramEnrollment(state, enrollmentId),
  );
  const { assignedConnections } = mentorshipProgramEnrollment || {};

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const connectionId = parseInt($state.params.connectionId, 10);
  const connection = assignedConnections.find((connec) => (
    connec.connectionId === connectionId
  )) ?? assignedConnections[0];
  const { id, user, role } = connection?.assignedEnrollment || {};

  const announcementDateHasPassed = moment().isAfter(moment(matchAnnouncementDate));
  const showConnection = id && announcementDateHasPassed;

  const containerClasses = showConnection
    ? 'd-flex flex-column right-panel'
    : 'd-flex flex-column right-panel justify-content-center';

  const styles = css`
    min-height: 300px;
    height: ${id ? 'fit-content' : '300px'};
    border-radius: 10px;
    background-color: ${white};
    .title {
      color: ${novoEdBlack};
    }
  `;

  return (
    <div css={styles} className={containerClasses}>
      {showConnection ? (
        <ConnectionInfo
          user={user}
          role={role}
          enrollmentId={id}
          mentorshipProgramId={mentorshipProgramId}
        />
      ) : (
        <NoConnection />
      )}
    </div>
  );
};

export default ConnectionDetails;
