import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { useContext, useEffect, useMemo, useState } from 'react';
import { novoEdBlue } from 'athena/styles/colors';
import { danger } from 'styles/global_defaults/colors';
import { getInstitutionData } from 'redux/actions/institutions';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { getOrgLevelProfileSettings } from 'redux/selectors/org-level-profile';
import { OrgLevelProfileSettings } from 'redux/schemas/models/org-level-profile-settings';
import { PAGE_SIZE } from 'athena/components/mentoring-program/constants';
import {
  getMentorshipProgramProfileQuestions,
  ProgramProfileQuestionType,
  resetOrgLevelProfileQuestionsList,
} from 'redux/actions/mentoring-program-profile-questions';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import {
  MentorshipProgramProfileQuestion,
  QuestionType,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { getMentorshipProgramProfileQuestionsList, getResetOrgLevelProfileQuestionsListState } from 'redux/selectors/mentorship-program-profile-questions';
import { useInView } from 'react-intersection-observer';
import { GetProgramProfileQuestionParams } from '../types';
import OrgLevelFields from './org-level-fields';
import AccountLevelFields from './account-level-fields';

const rowStyle = css`
  display: grid;
  grid-template-columns: 45% 45% 10%;
  padding: 0 ${standardSpacing}px;

  .cell:last-child {
    justify-self: center;
  }
`;

const styles = css`
  .headers {
    ${rowStyle};
  }
  .field-row {
    ${rowStyle};
  }
  .not-editable {
    color: ${novoEdBlue};
  }
  .required {
    color: ${danger};
  }
`;

const OrgLevelFieldsSection = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { ref: endRef, inView } = useInView();
  const { id: institutionId } = useSelector(getCurrentInstitution) || {};
  const { orgLevel }: OrgLevelProfileSettings = useSelector(getOrgLevelProfileSettings) || {};
  const resetList = useSelector(getResetOrgLevelProfileQuestionsListState);
  const [accountLevelQuestions, setAccountLevelQuestions] = useState([]);
  const [orgLevelQuestions, setOrgLevelQuestions] = useState([]);
  const headers = [
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.HEADERS.FIELD_NAME(),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.HEADERS.INPUT_TYPE(),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.HEADERS.REQUIRED(),
  ];

  useEffect(() => {
    if (institutionId && !orgLevel) {
      dispatch(getInstitutionData({ institutionId }));
    }
  }, [institutionId, orgLevel, dispatch]);

  const params = useMemo(
    () => ({
      type: ProgramProfileQuestionType.ORG_LEVEL,
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { reset, result, loadMore } = usePaginatedApi<
  MentorshipProgramProfileQuestion,
  GetProgramProfileQuestionParams
  >(
    p => dispatch(
      getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p }),
    ).then(action => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  useEffect(() => {
    if (resetList) {
      reset();
      dispatch(resetOrgLevelProfileQuestionsList({ resetList: false }));
    }
  }, [resetList]);

  useEffect(() => {
    setAccountLevelQuestions(
      result?.filter(question => question.isNovoedQuestion),
    );
    setOrgLevelQuestions(
      result?.filter(
        question => question.questionType === QuestionType.PROFILE_SETTING,
      ),
    );
  }, [result]);

  return (
    <div css={styles}>
      <div className='subtitle heading-5 mb-2'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.TITLE()}
      </div>
      <div className='description text-regular mb-4'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.DESCRIPTION()}
      </div>
      <div className='text-small semi-bold align-content-center headers'>
        {headers.map(header => (
          <div key={header} className='cell'>
            {header}
          </div>
        ))}
      </div>
      {accountLevelQuestions && <AccountLevelFields accountLevel={accountLevelQuestions} />}
      {!!orgLevel?.length && (
        <OrgLevelFields
          orgLevel={orgLevel}
          orgLevelQuestions={orgLevelQuestions}
          reloadFields={reset}
        />
      )}
      <div ref={endRef} />
    </div>
  );
};

export default OrgLevelFieldsSection;
