import { css } from '@emotion/react';
import { almostWhite, shadedWhite } from 'athena/styles/colors';
import { RowProps } from 'shared/components/nv-table';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import React from 'react';
import { ColumnID } from './types';
import ParticipantCell from './participant-cell';
import { RadioSelectorCell, CheckboxSelectorCell } from './selector-cell';

const styles = css`
  border-bottom: 1px solid ${shadedWhite};

  &:hover {
    background-color: ${almostWhite};
  }

  &:last-of-type {
    border-bottom: 0;
  }

  &.disabled {
    opacity: 0.4;
  }
`;

const mentorCells = {
  [ColumnID.SELECTOR]: RadioSelectorCell,
  [ColumnID.PARTICIPANT]: ParticipantCell,
};

const menteeCells = {
  [ColumnID.SELECTOR]: CheckboxSelectorCell,
  [ColumnID.PARTICIPANT]: ParticipantCell,
};

const getParticipantRow = (cells) => ({
  row,
  className,
  getCellStyle,
}: RowProps<MentoringProgramEnrollment>) => (
  <div
    css={styles}
    className={`${className} ${
      !row.getCanSelect() ? 'disabled' : ''
    }`}
  >
    {row.getVisibleCells().map((cell, index) => {
      const CellComponent = cells[cell.column.id];

      return (
        <CellComponent key={cell.id} css={getCellStyle(index)} cell={cell} />
      );
    })}
  </div>
);

export const MentorRow = getParticipantRow(mentorCells);
export const MenteeRow = getParticipantRow(menteeCells);
