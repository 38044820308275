import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { GetMentorshipProgramProfileParams, MentorshipProgramProfileParams } from './mentoring-program-profile-questions';
import { getMentoringProgramEnrollment } from './mentoring-program-enrollments';

type MentorshipProgramEnrollmentParams = {
  mentorshipProgramEnrollmentId: number;
};

type GetMentorshipProgramProfileAnswersParams = GetMentorshipProgramProfileParams & MentorshipProgramEnrollmentParams;

export const getMentorshipProgramProfileAnswers = createAsyncThunk<
MentorshipProgramProfileAnswer[],
GetMentorshipProgramProfileAnswersParams
>(
  'GET_MENTORSHIP_PROGRAM_PROFILE_ANSWERS',
  async ({ mentorshipProgramId, type, page, pageSize, mentorshipProgramEnrollmentId }) => {
    const response = await axios.get(
      `/mentorship_programs/${mentorshipProgramId}/mentorship_program_profile_answers.json`,
      {
        params: {
          mentorship_program_enrollment_id: mentorshipProgramEnrollmentId,
          type,
          page,
          page_size: pageSize,
        },
      },
    );
    return response.data.result;
  },
);

export type MentorshipProgramProfileAnswersInput = (MentorshipProgramEnrollmentParams & {
  mentorshipProgramProfileQuestionId: number;
  answer: string | string[] | null;
})[];

type CreateMentorshipProgramProfileAnswersParams = MentorshipProgramProfileParams & {
  mentorshipProgramProfileAnswers: MentorshipProgramProfileAnswersInput;
  enrollmentId: number;
};

export const createMentorshipProgramProfileAnswers = createAsyncThunk<
boolean,
CreateMentorshipProgramProfileAnswersParams
>(
  'CREATE_MENTORSHIP_PROGRAM_PROFILE_ANSWERS',
  async ({ mentorshipProgramId, mentorshipProgramProfileAnswers, enrollmentId }, thunkAPI) => {
    const response = await axios.post(
      `/mentorship_programs/${mentorshipProgramId}/mentorship_program_profile_answers.json`,
      {
        mentorshipProgramProfileAnswers,
      },
    );
    thunkAPI.dispatch(getMentoringProgramEnrollment({ programId: mentorshipProgramId, enrollmentId }));
    return response.data.result;
  },
);
