import { createSelector } from 'reselect';
import { RootState } from 'redux/schemas';
import { MyAccount, User } from 'redux/schemas/models/my-account';
import { OrgProfileField } from 'redux/schemas/models/account-fields';
import { RolesService } from 'institutions/services/roles-service';

export const getCurrentUser = (state: RootState): MyAccount => {
  if (!state.app.currentUserId) {
    return null;
  }

  return state.models.users[state.app.currentUserId];
};

export const getCurrentUserId = createSelector(
  (state: RootState) => state.app,
  app => app.currentUserId,
);

export const getEnrollments = createSelector(
  (state: RootState) => state.models,
  models => models.enrollments,
);

export const getUser = (state: RootState, userId: number): User => state.models.users[userId];

/**
 * Selector which can used as a depedency of another reselect selector
 * https://github.com/reduxjs/reselect/issues/360#issuecomment-409256149
 */
export const getUserById = createSelector<RootState, { [id: number]: User }, Function>(
  state => state.models.users,
  (users) => (userId: number) => users[userId],
);

export const getCurrentUserMentoringInfo = createSelector(
  [getCurrentUser],
  (user) => user.mentoringInfo,
);

export const getUserEnrollments = createSelector(
  (state, userId) => state.models.users[userId].enrollmentIds,
  (state) => state.models.enrollments,
  (enrollmentIds, enrollments) => enrollmentIds.map(
    enrollmentId => enrollments[enrollmentId],
  ),
);

export const getCurrentUserEnrollments = createSelector(
  [getCurrentUser, getEnrollments],
  (user, enrollments) => user.enrollmentIds.map(enrollmentId => enrollments[enrollmentId]),
);

export const getCurrentEnrollment = createSelector(
  (state: RootState) => state.app.currentCourseId,
  getCurrentUserEnrollments,
  (courseId, enrollments) => enrollments.find(enrollment => enrollment.courseId === courseId),
);

export const getUserProfileDetails = (
  state: RootState,
  userId: number,
): OrgProfileField[] => {
  const { profileDetails = [] } = state.models.users[userId];

  return profileDetails;
};

export const getOrgProfilePracticeSubmissions = (
  state: RootState,
  userId: number,
) => {
  const submissions = state.app.orgProfile[userId]?.practiceSubmissions?.practiceSubmissionIds?.map(
    submissionId => state.models.practiceSubmissions[submissionId],
  );
  return { submissions, ...state.app.orgProfile[userId]?.practiceSubmissions || {} };
};

export const hasOrgAdminPermissions = (state: RootState): boolean => {
  if (!state.app.currentUserId || !state.app.currentInstitutionId) {
    return null;
  }
  return RolesService.isNovoEdAdmin(state.models.users[state.app.currentUserId])
    || RolesService.isOrgAdmin(state.models.institutions[state.app.currentInstitutionId]);
};

export const hasCourseManagerPermissions = (state: RootState): boolean => {
  if (!state.app.currentUserId || !state.app.currentInstitutionId) {
    return null;
  }
  return RolesService.isNovoEdAdmin(state.models.users[state.app.currentUserId])
    || RolesService.isOrgCourseManager(state.models.institutions[state.app.currentInstitutionId]);
};

export const getUsersList = createSelector(
  (state: RootState) => state.models.users,
  (state, ids: number[]) => ids,
  (users: Record<number, User>, ids: number[]) => ids.map((id) => users[id]),
);
