import React, { useContext } from 'react';
import t from 'react-translate';

// Redux
import { useSelector } from 'react-redux';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';

import { ReactComponent as LightGrayAvatar } from 'styles/icons/light-gray-avatar.svg';

// Context
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';

const NoConnection = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id, mentorshipProgramEnrollment } = mentoringProgram;
  const aliases = useSelector((state) => getMentoringProgramAliases(state, id));
  const { role } = useSelector((state) => getMentoringProgramEnrollment(state, mentorshipProgramEnrollment));

  return (
    <div className='w-100 h-100 flex-column d-flex justify-content-center align-items-center'>
      <LightGrayAvatar width={100} height={100} className='mb-6' />
      <div className='heading-5 gray-2'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.CONNECTION_DETAILS.NO_CONNECTION({
          role: role.name,
          ...aliases,
        })}
      </div>
    </div>
  );
};

export default NoConnection;
