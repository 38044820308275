import t from 'react-translate';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import NvIcon from 'shared/components/nv-icon';
import NvPopover from 'shared/components/nv-popover';
import { getAutoMatchingRulesTotalCount } from 'redux/selectors/mentorship-program-matching-rules';
import { dividerMedium, novoEdBlack, novoEdBlue } from 'athena/styles/colors';
import {
  tripleSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { gray1, gray5 } from 'styles/global_defaults/colors';
import { gridStyles } from './styles';

const styles = css`
  ${gridStyles};
  height: 45px;
  border-bottom: 1px solid ${dividerMedium};

  .header-info {
    color: ${novoEdBlue};
    margin-left: ${quarterSpacing}px;
  }
`;

const overlayStyles = css`
  max-width: 100%;
  .bs4-popover-body {
    padding: ${standardSpacing}px;
  }
  .importance-level {
    height: ${tripleSpacing}px;
    width: 433px;
    display: grid;
    padding: ${quarterSpacing}px 0;
    gap: ${threeQuartersSpacing}px;
    grid-template-columns: 90px auto;

    &:not(:last-child) {
      margin-bottom: ${quarterSpacing}px;
      border-bottom: 1px solid ${gray5};
    }

    .title {
      color: ${novoEdBlack};
    }
    .description {
      color: ${gray1};
    }
  }
  .learn-more {
    a {
      color: ${novoEdBlue};
    }
    margin-top: ${standardSpacing}px;
  }
`;

const ImportanceLevelInfo = () => {
  const learnMoreLink = ''; // TODO: Add when available
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST;
  const levels = [
    {
      title: translationBase.IMPORTANCE_LEVEL.NICE_TO_HAVE.TITLE(),
      description: translationBase.IMPORTANCE_LEVEL.NICE_TO_HAVE.DESCRIPTION(),
    },
    {
      title: translationBase.IMPORTANCE_LEVEL.PREFERRED.TITLE(),
      description: translationBase.IMPORTANCE_LEVEL.PREFERRED.DESCRIPTION(),
    },
    {
      title: translationBase.IMPORTANCE_LEVEL.REQUIRED.TITLE(),
      description: translationBase.IMPORTANCE_LEVEL.REQUIRED.DESCRIPTION(),
    },
  ];

  return (
    <>
      {levels.map(({ title, description }) => (
        <div className='importance-level'>
          <div className='title text-medium bold'>{title}</div>
          <div className='description text-medium'>{description}</div>
        </div>
      ))}
      {learnMoreLink ? (
        <div className='learn-more text-large-body semi-bold'>
          <a href={learnMoreLink} target='_blank' rel='noreferrer'>
            {translationBase.POPOVER.LEARN_MORE()}
          </a>
        </div>
      ) : null}
    </>
  );
};

const MatchingRulesHeaders = () => {
  const translationBase = t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST.HEADERS;
  const autoMatchingRulesCount = useSelector(getAutoMatchingRulesTotalCount);

  const headers = [
    {
      title: translationBase.RULES(autoMatchingRulesCount),
    },
    {
      title: translationBase.IMPORTANCE_LEVEL(),
      info: <ImportanceLevelInfo />,
    },
  ];

  return (
    <div css={styles} className='pl-4 pr-4 align-items-center'>
      {headers.map(({ title, info }) => (
        <div className='d-flex align-items-center'>
          <div key={title} className='text-small semi-bold'>
            {title}
          </div>
          {info && (
            <NvPopover showOnHover content={info} placement='top' overlayStyles={overlayStyles}>
              <NvIcon className='header-info' icon='info' size='xss-smallest' />
            </NvPopover>
          )}
        </div>
      ))}
    </div>
  );
};

export default MatchingRulesHeaders;
