import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvPopover from 'shared/components/nv-popover';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';
import { css } from '@emotion/react';
import { getInputTypeLabel } from 'athena/components/mentoring-program/constants';
import { InputType } from 'redux/schemas/models/mentoring-program-profile-questions';
import { useState } from 'react';
import { OrgProfileFieldType } from './org-level-fields';

const overlayStyles = css`
  max-width: 280px;
  .confirmation {
    max-width: 100%;
    .button-container {
      justify-content: center;
    }
  }
`;

const NonEditableField = () => (
  <div className='d-flex align-items-center not-editable'>
    <div className='pr-1 field-type italic text-regular'>
      {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.NOT_EDITABLE.LABEL()}
    </div>
    <NvTooltip
      text={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.NOT_EDITABLE.TOOLTIP()}
    >
      <NvIcon icon='info' size='xss-smallest' />
    </NvTooltip>
  </div>
);

type EditableFieldProps = {
  inputType: string;
  isRequired: boolean;
  isAttachedToRule: boolean;
  onChange: (
    isRequired: boolean,
    shouldUpdateMatchingRules: boolean
  ) => void;
};

const EditableField = (props: EditableFieldProps) => {
  const { inputType, isRequired, isAttachedToRule, onChange } = props;
  const [isSwitchingRequired, setIsSwitchingRequired] = useState(false);

  const handleChange = (newState: boolean) => {
    if (!newState && isAttachedToRule) {
      setIsSwitchingRequired(true);
    } else onChange(newState, false);
  };

  return (
    <>
      <div className='field-type text-regular'>{getInputTypeLabel(t, inputType as unknown as InputType)}</div>
      <NvPopover
        enabled
        show={isSwitchingRequired}
        overlayStyles={overlayStyles}
        content={(
          <NvConfirmationPopover
            onCancel={() => setIsSwitchingRequired(false)}
            onConfirm={() => {
              onChange(false, true);
              setIsSwitchingRequired(false);
            }}
            header={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.TITLE()}
            message={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.DESCRIPTION()}
            cancelBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.CANCEL()}
            confirmBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.OK()}
          />
        )}
        placement='top'
        rootClose
        onHide={() => setIsSwitchingRequired(false)}
        className='d-inline-flex mw-100 justify-content-center'
        preventOverflow
      >
        <NvSwitch
          className='cell'
          checked={isRequired}
          onChange={handleChange}
        />
      </NvPopover>
    </>
  );
};

type OrgLevelFieldRowProps = {
  field: OrgProfileFieldType;
  onChange: (
    isRequired: boolean,
    shouldUpdateMatchingRules: boolean
  ) => void;
};

const OrgLevelFieldRow = ({ field, onChange }: OrgLevelFieldRowProps) => {
  const { name, inputType, isCsvManaged, isIntegrated, isRequired, isAttachedToRule } = field;

  return (
    <div className='align-content-center field-row'>
      <div className='d-flex field-name text-regular semi-bold'>
        <span>{name}</span>
        {isRequired && <span className='required'>*</span>}
      </div>
      {isCsvManaged || isIntegrated ? (
        <NonEditableField />
      ) : (
        <EditableField
          inputType={inputType}
          isRequired={isRequired}
          isAttachedToRule={isAttachedToRule}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default OrgLevelFieldRow;
