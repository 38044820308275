import { mergeWith } from 'lodash';
import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import {
  MentorshipProgramConnectionSchema,
  MentorshipProgramConnectionsSchema,
} from 'redux/schemas/api/mentorship-program-connections';
import {
  createMentorshipProgramConnection,
  deleteMentorshipProgramConnection,
  getMentorshipProgramConnections,
  setCreateConnectionModalSettings,
} from 'redux/actions/mentorship-program-connections';
import { initialRootState } from '.';

const setConnectionActionHandler = (state, action) => {
  const normalized = normalize(
    action.payload,
    MentorshipProgramConnectionSchema,
  );

  mergeWith(state.models, normalized.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(
      createMentorshipProgramConnection.fulfilled,
      setConnectionActionHandler,
    )
    .addCase(deleteMentorshipProgramConnection.fulfilled, (state, action) => {
      const { mentorshipProgramConnectionId } = action.meta.arg;
      delete state.models.mentorshipProgramConnections[
        mentorshipProgramConnectionId
      ];
    })
    .addCase(getMentorshipProgramConnections.fulfilled, (state, action) => {
      const normalized = normalize(
        action.payload,
        MentorshipProgramConnectionsSchema,
      );

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(setCreateConnectionModalSettings, (state, action) => {
      const { showModal, type, enrollmentId } = action.payload;
      state.app.connections.createConnectionModal = {
        showModal,
        type,
        enrollmentId,
      };
    });
});
