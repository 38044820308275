import { schema } from 'normalizr';

import { MentoringProgram } from 'redux/schemas/models/mentoring-programs';
import { MentoringProgramEnrollmentSchema } from 'redux/schemas/api/mentoring-program-enrollments';

export const MentoringProgramSchema = new schema.Entity<MentoringProgram>('mentoringPrograms', {
  mentorshipProgramEnrollment: MentoringProgramEnrollmentSchema,
});

export const MentoringProgramsSchema = new schema.Array(MentoringProgramSchema);
