/* eslint-disable react/no-array-index-key */
import { MentorshipProgramProfileQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import { useAppDispatch } from 'redux/store';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';
import { updateMentorshipProgramProfileQuestion } from 'redux/actions/mentoring-program-profile-questions';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { useContext } from 'react';
import AccountLevelFieldRow from './account-level-field-row';

type AccountLevelFieldsProps = {
  accountLevel: MentorshipProgramProfileQuestion[];
};

const AccountLevelFields = ({ accountLevel }: AccountLevelFieldsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const onChange = (questionId: number, newState: boolean) => {
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    const mentorshipProgramProfileQuestion = {
      isRequired: newState,
    };
    wrapThunkAction(dispatch(updateMentorshipProgramProfileQuestion({
      mentorshipProgramId,
      mentorshipProgramProfileQuestion,
      questionId,
    }))).then(() => {
      dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
    })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  return (
    <>
      {accountLevel
        .filter(item => !item.isHidden)
        .map((question) => (
          <AccountLevelFieldRow
            key={question?.id}
            {...question}
            onChange={onChange}
          />
        ))}
    </>
  );
};

export default AccountLevelFields;
