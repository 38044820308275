import NvAthenaFroala from 'athena/components/nv-athena-froala';
import t from 'react-translate';

export const getDefaultGuidelines = (programName) => `
  <p class="froala-style-regular">
    Welcome to ${programName}! Below are some guidelines and expectations we have for you.
  </p>
  <ul>
    <li class="froala-style-regular" dir="ltr">Mentors:
      <ul>
        <li class="froala-style-regular" dir="ltr">{expectation}</li>
        <li class="froala-style-regular" dir="ltr">{expectation}</li>
      </ul>
    </li>
    <li class="froala-style-regular" dir="ltr">Mentees:
      <ul>
        <li class="froala-style-regular" dir="ltr">{expectation}</li>
        <li class="froala-style-regular" dir="ltr">{expectation}</li>
      </ul>
    </li>
    <li class="froala-style-regular" dir="ltr">Resources:
      <ul>
        <li class="froala-style-regular" dir="ltr">{Link to resources}</li>
      </ul>
    </li>
  </ul>
`;

const ProgramGuidelines = () => (
  <div className='mb-6'>
    <div className='heading-5 mb-2'>
      {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_GUIDELINES.TITLE()}
    </div>
    <div className='gray-1 text-medium mb-2 description'>
      {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.PROGRAM_GUIDELINES.DESCRIPTION()}
    </div>
    <NvAthenaFroala name='guideline' />
  </div>
);

export default ProgramGuidelines;
