import { Cell } from '@tanstack/react-table';

import { MentorshipProgramConnection } from 'redux/schemas/models/mentorship-program-connections';

export enum ColumnID {
  TITLE = 'title',
  CONNECTION_TYPE = 'connectionType',
  OPTIONS = 'options'
}

export type CellProps = {
  className: string,
  cell: Cell<MentorshipProgramConnection, any>;
};
