import React from 'react';
import pick from 'lodash/pick';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import MoreOptionsTarget from 'athena/components/more-options-target';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import ParticipantsContext from 'athena/components/mentoring-program/participants/context';
import { setCreateConnectionModalSettings } from 'redux/actions/mentorship-program-connections';
import { commonStyles } from 'athena/components/mentoring-program/participants/participant-row/styles';
import type { CellProps } from 'athena/components/mentoring-program/participants/participant-row/types';
import NvDropdown, { NvDropdownButtonStyle, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import UserForm, { FormData } from 'athena/components/mentoring-program/participants/add-participants-modal/user-form';
import { CreateConnectionType, RootState } from 'redux/schemas';
import { enrollUserToProgram, removeMentoringProgramEnrollment } from 'redux/actions/mentoring-program-enrollments';
import { resetPassword } from 'redux/actions/users';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { Institution } from 'redux/schemas/models/courseFull';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';

const OptionsCell = (props: CellProps) => {
  const { cell, className } = props;

  const dispatch = useAppDispatch();
  const [showEdit, setShowEdit] = React.useState(false);
  const { reset, countState } = React.useContext(ParticipantsContext);
  const { countState: programCountState, mentoringProgram } = React.useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));
  const institution: Institution = useSelector((state: RootState) => getCurrentInstitution(state));
  const { enableBasicSsoInfoEdit = true } = institution;

  const { row: { original: { id, role, mentorshipProgramId, user, assignedConnections } } } = cell;

  const styles = css`
    ${commonStyles};

    .options-target {
      opacity: 0;
      flex-shrink: 0;
    }
  `;

  const menteeOptions: NvDropdownOption[] = [{
    text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.ASSIGN_MENTOR(mentoringProgramAliases),
    type: 'text',
    callback: () => dispatch(setCreateConnectionModalSettings({ showModal: true, type: CreateConnectionType.ASSIGN_MENTOR, enrollmentId: id })),
  }, {
    type: 'divider',
  }];

  const mentorOptions: NvDropdownOption[] = [{
    text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.ASSIGN_MENTEES(mentoringProgramAliases),
    type: 'text',
    callback: () => dispatch(setCreateConnectionModalSettings({ showModal: true, type: CreateConnectionType.ASSIGN_MENTEES, enrollmentId: id })),
  },
  {
    type: 'divider',
  }];

  const editOptions: NvDropdownOption[] = [{
    text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.EDIT(),
    type: 'text',
    callback: () => {
      setShowEdit(true);
    },
  },
  {
    type: 'divider',
  }];

  const resetPasswordOptions: NvDropdownOption[] = [
    // {
    //   text: t.USER_MANAGEMENT.RESET_ATTEMPTS(),
    //   type: 'text',
    //   callback: () => {
    //     // TODO:
    //   },
    // },
    {
      text: t.USER_MANAGEMENT.RESET_PASSWORD(),
      type: 'text',
      callback: () => {
        wrapThunkAction(dispatch(resetPassword(user.email))).then(() => {
          dispatch(addAlertMessage({
            type: AlertMessageType.SUCCESS,
            header: t.FORM.SUCCESS_BANG(),
            message: t.USER_MANAGEMENT.RESET_PASSWORD_EMAIL_SENT(user.email),
          }));
        });
      },
    },
    // {
    //   text: t.USER_MANAGEMENT.RESEND_WELCOME_EMAIL(),
    //   type: 'text',
    //   callback: () => {
    //     // TODO:
    //   },
    // },
    { type: 'divider' },
  ];

  const options: NvDropdownOption[] = [
    ...(role.name === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE && assignedConnections.length === 0 ? menteeOptions : []),
    ...(role.name === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR ? mentorOptions : []),
    ...editOptions,
    ...(enableBasicSsoInfoEdit ? resetPasswordOptions : []),
    {
      text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.REMOVE(),
      type: 'text',
      class: 'text-danger',
      callback: () => {
        dispatch(openConfirmationDialog({
          title: t.MENTORING_PROGRAMS.PARTICIPANTS.REMOVE_PARTICIPANT_MODAL.TITLE(user.fullName),
          bodyText: t.MENTORING_PROGRAMS.PARTICIPANTS.REMOVE_PARTICIPANT_MODAL.BODY(),
          confirmText: t.FORM.YES_SURE(),
          onConfirm: () => {
            wrapThunkAction(dispatch(removeMentoringProgramEnrollment({
              programId: mentoringProgram.id,
              email: user.email,
            }))).then(() => {
              reset();
              countState.refresh();
              programCountState.refresh();
            });
          },
        }));
      },
    },
  ];

  const editDefaultValues: FormData = React.useMemo(() => ({
    ...pick(user, ['firstName', 'lastName']),
    email: user.email!,
    role: role.name,
  }), [user, role]);

  const updateUser = (values) => wrapThunkAction(dispatch(enrollUserToProgram({
    programId: mentorshipProgramId,
    userId: user.id,
    ...values,
  }))).finally(() => {
    reset();
    setShowEdit(false);
  });

  const handleSubmit = (values: FormData) => {
    if (role.name !== values.role && assignedConnections?.length) {
      dispatch(openConfirmationDialog({
        title: t.MENTORING_PROGRAMS.PARTICIPANTS.CONFIRMATION_MODAL.CHANGE_ROLE.TITLE(),
        bodyText: t.MENTORING_PROGRAMS.PARTICIPANTS.CONFIRMATION_MODAL.CHANGE_ROLE.BODY(values.firstName),
        confirmText: t.FORM.UPDATE(),
        onConfirm: () => updateUser(values),
      }));
    } else {
      updateUser(values);
    }
  };

  return (
    <div className={className} css={styles}>
      <NvModal
        width={800}
        show={showEdit}
        bodyClassName='p-0'
        type={ModalType.DYNAMIC}
        onClose={() => setShowEdit(false)}
        header={t.MENTORING_PROGRAMS.PARTICIPANTS.EDIT()}
        body={(
          <div className='p-5'>
            <UserForm
              onSubmit={handleSubmit}
              defaultValues={editDefaultValues}
              disabled={!enableBasicSsoInfoEdit}
              onClose={() => setShowEdit(false)}
            />
          </div>
        )}
      />
      <NvDropdown
        items={options}
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        customTarget={() => (
          <MoreOptionsTarget className='options-target' />
        )}
      />
    </div>
  );
};

export default OptionsCell;
