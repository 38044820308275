import { createSelector } from 'reselect';

import { RootState } from 'redux/schemas';

export const getMentoringProgram = (
  state: RootState,
  programId: number,
) => state.models.mentoringPrograms[programId];

export const getProgramCounts = (state: RootState) => state.app.mentoringProgram.programCounts;

export const getMentoringProgramAliases = createSelector(
  (state: RootState, programId) => {
    const { programAlias } = getMentoringProgram(state, programId) ?? {};

    return programAlias;
  },
  (programAlias) => {
    if (!programAlias) {
      return {};
    }

    const { mentee, mentor } = programAlias;

    return {
      // Mentee
      MenteeAlias: mentee?.capitalizedSingularized,
      MenteesAlias: mentee?.capitalizedPluralized,
      menteeAlias: mentee?.downcasedSingularized,
      menteesAlias: mentee?.downcasedPluralized,
      MenteesTitleAlias: mentee?.pluralizedTitleized,
      MenteeTitleAlias: mentee?.singularizedTitleized,
      // Mentor
      MentorAlias: mentor?.capitalizedSingularized,
      MentorsAlias: mentor?.capitalizedPluralized,
      mentorAlias: mentor?.downcasedSingularized,
      mentorsAlias: mentor?.downcasedPluralized,
      MentorsTitleAlias: mentor?.pluralizedTitleized,
      MentorTitleAlias: mentor?.singularizedTitleized,
    };
  },
);

export const getAutoMatchingStatus = (state: RootState) => state.app.mentoringProgram.autoMatching.status;

export const getSettingsSaveStatus = (state: RootState) => state.app.mentoringProgram.settings.saveStatus;
