const AUTO_SAVE_EVENT_TYPE = 'onBlur';

export type FormComponentType = {
  name: string;
  component: React.FC<any>;
  eventType?: string;
  extras?: any;
};

type HandlerType = (e: React.FormEvent<HTMLFormElement>) => void;

const renderFormComponents = (
  items: FormComponentType[],
  handler: HandlerType,
) => (
  items.map(({
    name,
    component: Component,
    eventType = AUTO_SAVE_EVENT_TYPE,
    extras,
  }) => (
    <form key={name} {...{ [eventType]: handler }}>
      <Component {...extras} onSubmit={handler} />
    </form>
  ))
);

export default renderFormComponents;
