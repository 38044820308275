import { Row, Table } from '@tanstack/react-table';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { MentoringProgramRole, MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramEnrollmentsList } from 'redux/selectors/mentorship-program-enrollments';
import { useAppDispatch } from 'redux/store';
import NvTable, { Column, RowProps } from 'shared/components/nv-table';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { getMentoringProgramEnrollments } from 'redux/actions/mentoring-program-enrollments';
import { useInView } from 'react-intersection-observer';
import { ColumnID } from '../../connections/participant-row/types';
import MentoringProgramContext from '../../context';
import { PAGE_SIZE, PageSizeParams } from '../../constants';

const fallbackData = [];

type Params = PageSizeParams & {
  textSearch: string;
  courseRoleName: MentoringProgramRole;
};

type ParticipantsListProps = {
  listRef: React.Ref<Table<MentoringProgramEnrollment>>;
  listType: MentoringProgramRole;
  tableHeader: string;
  rowType: React.ComponentType<RowProps<MentoringProgramEnrollment>>;
  enableMultiSelection: boolean;
  textSearch?: string;
  enableRowSelection?: (row: Row<MentoringProgramEnrollment>) => boolean;
  filterRows?: (enrollment: MentoringProgramEnrollment) => boolean;
};

const ParticipantsList = React.memo(
  ({
    listRef,
    listType,
    tableHeader,
    rowType,
    textSearch,
    enableMultiSelection,
    enableRowSelection,
    filterRows,
  }: ParticipantsListProps) => {
    const dispatch = useAppDispatch();
    const {
      mentoringProgram: { id: programId },
    } = useContext(MentoringProgramContext);

    const { ref: scrollRef, inView: reachedEnd } = useInView();

    const tableColumns: Column<MentoringProgramEnrollment>[] = [
      {
        id: ColumnID.SELECTOR,
        header: tableHeader,
      },
      {
        id: ColumnID.PARTICIPANT,
      },
    ];

    const params = useMemo(
      () => ({
        pageSize: PAGE_SIZE,
        textSearch: textSearch?.trim() || undefined,
        courseRoleName: listType,
        isMatched: listType === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE ? false : undefined,
        updateCounts: false,
      }),
      [textSearch],
    );

    const bodyProps = {
      ref: scrollRef,
      className: 'table-body',
    };

    const { result, loadMore } = usePaginatedApi<
    MentoringProgramEnrollment,
    Params
    >(
      p => dispatch(
        getMentoringProgramEnrollments({
          programId,
          ...p,
        }),
      )
        .then(action => action.payload)
        .then((enrollments: MentoringProgramEnrollment[]) => {
          if (filterRows) return (enrollments || []).filter(filterRows);
          return enrollments;
        }),
      params,
      getMentoringProgramEnrollmentsList,
    );

    useEffect(() => {
      if (reachedEnd) {
        loadMore();
      }
    }, [loadMore, reachedEnd]);

    return (
      <NvTable<MentoringProgramEnrollment>
        data={result ?? fallbackData}
        tableRef={listRef}
        RowComponent={rowType}
        bodyProps={bodyProps}
        columns={tableColumns}
        enableRowSelection={enableRowSelection}
        enableMultiRowSelection={enableMultiSelection}
      />
    );
  },
);

export default ParticipantsList;
