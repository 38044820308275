import * as yup from 'yup';
import t from 'react-translate';
import { css } from '@emotion/react';
import { dividerMedium } from 'athena/styles/colors';
import {
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  QUESTION_MAX_LENGTH,
} from 'athena/components/mentoring-program/constants';
import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestion,
  ProgramLevelQuestionType,
} from 'redux/schemas/models/mentoring-program-profile-questions';
import { useAppDispatch } from 'redux/store';
import {
  NewProgramLevelQuestionDraft,
  ProgramProfileQuestionType,
  createMentorshipProgramProfileQuestions,
  deleteMentorshipProgramProfileQuestion,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile-questions';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { isEmpty } from 'underscore';
import { wrapThunkAction } from 'redux/utils';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import NvIcon from 'shared/components/nv-icon';
import { danger } from 'styles/global_defaults/colors';
import { getSanitizedStyles } from 'shared/utils';
import InlineConfirmation from 'shared/components/inline-confirmation';
import OptionsMenu from './options-menu';
import RequiredSwitch from './required-switch';
import ResponseOptions from './response-options';
import QuestionType from './question-type';
import QuestionText from './question-text';
import {
  getMentorshipProgramAutoMatchingRulesTotalCount,
  resetAutoMatchingRulesList,
} from 'redux/actions/mentoring-program-matching-rules';

type ProgramLevelFieldProps = {
  index: number;
  question: MentorshipProgramProfileQuestion | NewProgramLevelQuestionDraft;
  canMoveUp: boolean;
  onMoveUp: () => void;
  canMoveDown: boolean;
  onMoveDown: () => void;
  reset: () => void;
  onDeleteDraftQuestion: (questionId: string) => void;
};

const styles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${standardSpacing}px;
  border-bottom: 1px solid ${dividerMedium};

  .required-fields {
    height: 100%;
    display: grid;
    gap: ${threeQuartersSpacing}px;
    grid-template-columns: auto 30% 10% 16px;

    .select-type {
      div {
        width: 222px;
        .text-medium {
          border: 1px solid ${dividerMedium};
        }
      }
      .select-menu {
        div {
          width: 220px;
        }
      }
    }
    .required-type {
      > div:first-of-type {
        border: 1px solid ${danger};
      }
    }

    .self-centered {
      justify-self: center;
    }
  }
`;

const ProgramLevelField = forwardRef<any, ProgramLevelFieldProps>(({
  index,
  question,
  canMoveUp,
  onMoveUp,
  canMoveDown,
  onMoveDown,
  reset,
  onDeleteDraftQuestion,
}, ref) => {
  const dispatch = useAppDispatch();
  const [questionId, setQuestionId] = useState(question.id);
  const [type, setType] = useState(ProgramProfileQuestionType.PROGRAM_LEVEL);
  const [isRequired, setIsRequired] = useState(question.isRequired || false);
  const [questionType, setQuestionType] = useState<ProgramLevelQuestionType>();
  const [questionText, setQuestionText] = useState<string>();
  const [responseOptions, setResponseOptions] = useState<string>();
  const [isAttachedToRule, setIsAttachedToRule] = useState<boolean>();
  const [error, setError] = useState<string>();
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);

  const selectRef = useRef<{ toggle: HTMLDivElement; container: HTMLDivElement; }>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const responseOptionsRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const { profileQuestion } = question;
    const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};

    setQuestionId(question.id);
    setIsRequired(question.isRequired);
    setQuestionType(questionList?.type);
    setQuestionText(questionList?.questionText);
    setResponseOptions(
      questionList?.responseOptions?.map(elem => elem.optionContent).join(', '),
    );
    setIsAttachedToRule(!!question?.isAttachedToRule);
  }, [question]);

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const getIsDropdownOrChecklist = (value: ProgramLevelQuestionType) => [
    ProgramLevelQuestionType.DROPDOWN,
    ProgramLevelQuestionType.CHECKLIST,
  ].includes(value);

  const isDropdownOrChecklist = getIsDropdownOrChecklist(questionType);

  const validationSchema = yup.object().shape({
    isRequired: yup.boolean().required(),
    questionText: yup
      .string()
      .required(t.VALIDATION.REQUIRED())
      .max(
        QUESTION_MAX_LENGTH,
        t.VALIDATION.MAX_LENGTH(`${QUESTION_MAX_LENGTH}`),
      ),
    questionType: yup.string().required(t.VALIDATION.REQUIRED()),
    responseOptions: yup.string().when('questionType', {
      is: () => isDropdownOrChecklist,
      then: yup
        .string()
        .required(t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.VALIDATION.REQUIRED_RESPONSE_OPTIONS()),
      otherwise: yup.string(),
    }),
  });

  const methods = useForm({
    mode: 'onBlur',
    shouldUnregister: true,
    defaultValues: useMemo(
      () => ({
        questionId,
        type,
        isRequired,
        questionType,
        questionText,
        responseOptions,
      }),
      [
        questionId,
        type,
        isRequired,
        questionType,
        questionText,
        responseOptions,
      ],
    ),
    resolver: yupResolver(validationSchema),
  });
  const {
    reset: resetForm,
    setValue,
    formState: { isDirty, errors },
  } = methods;

  const isQuestionTypeDropdownOpen = () => {
    const container = selectRef?.current?.container;
    const selectMenu = container?.querySelector('.select-menu');
    return !!selectMenu;
  };

  const dynamicStyles = css`${showConfirmationDelete ? 'min-height: 100px;' : ''}`;

  useEffect(() => {
    resetForm({
      questionId,
      type,
      isRequired,
      questionType,
      questionText,
      responseOptions,
    });
  }, [
    questionId,
    type,
    isRequired,
    questionType,
    questionText,
    responseOptions,
    reset,
  ]);

  const refreshList = () => {
    dispatch(
      getMentorshipProgramAutoMatchingRulesTotalCount({
        mentorshipProgramId,
      }),
    );
    dispatch(resetAutoMatchingRulesList({ resetList: true }));
  };

  useEffect(() => {
    const areResponseOptionsValid = isDropdownOrChecklist
      ? !!responseOptions.trim()
      : true;
    if (isDirty && isEmpty(errors) && areResponseOptionsValid) {
      const mentorshipProgramProfileQuestion = {
        index,
        isRequired,
        questionText,
        questionType,
        type,
        responseOptions:
          isDropdownOrChecklist && responseOptions
            ? responseOptions
              .split(',')
              .filter(item => !!item.trim())
              .map((val: string) => ({ optionContent: val.trim() }))
            : [],
      };
      let dispatchAction;
      const draftId = (question as NewProgramLevelQuestionDraft).tempId;
      if (!questionId) {
        if (draftId && questionText && questionType) {
          dispatchAction = dispatch(
            createMentorshipProgramProfileQuestions({
              mentorshipProgramId,
              mentorshipProgramProfileQuestions: [
                mentorshipProgramProfileQuestion,
              ],
            }),
          );
        }
      } else {
        dispatchAction = dispatch(
          updateMentorshipProgramProfileQuestion({
            questionId,
            mentorshipProgramId,
            mentorshipProgramProfileQuestion,
          }),
        );
      }
      if (dispatchAction) {
        dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
        wrapThunkAction(dispatchAction)
          .then(async () => {
            dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
            setUnsavedChanges(false);
            if (draftId) {
              await reset();
              onDeleteDraftQuestion(draftId);
            }
            if(!isRequired && isRequired !== question?.isRequired) {
              refreshList();
            }
          })
          .finally(() => {
            setTimeout(() => {
              dispatch(setSaveStatus({ newStatus: null }));
            }, 3000);
          });
      }
    } else if (isDirty || !isEmpty(errors) || !areResponseOptionsValid) {
      setUnsavedChanges(true);
    }
  }, [questionText, questionType, isRequired, responseOptions]);

  useEffect(() => {
    if (!responseOptions && isDropdownOrChecklist) {
      responseOptionsRef?.current?.focus();
    }
  }, [isRequired]);

  const onDelete = () => {
    if (questionId) {
      dispatch(
        deleteMentorshipProgramProfileQuestion({
          mentorshipProgramId,
          questionId,
        }),
      );
      reset();
    } else {
      const { tempId } = question as NewProgramLevelQuestionDraft;
      onDeleteDraftQuestion(tempId);
    }
    setShowConfirmationDelete(false);
  };

  const handleDelete = () => {
    if (isAttachedToRule) {
      setShowConfirmationDelete(true);
    } else onDelete();
  };

  useImperativeHandle(ref, () => ({
    unsavedChanges: () => unsavedChanges,
  }));

  const handleChangeQuestionText = ({ target: { value } }) => {
    setQuestionText(value);
    setTimeout(() => {
      if (!questionType && !isQuestionTypeDropdownOpen()) {
        selectRef.current?.toggle.click();
      }
    }, 200);
  };

  const handleChangeQuestionType = (type) => {
    setValue('questionType', type, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setQuestionType(type);
    if (getIsDropdownOrChecklist(type)) {
      setTimeout(() => {
        responseOptionsRef?.current?.focus();
      });
    }
    setResponseOptions(isDropdownOrChecklist ? responseOptions : '');
  }

  return (
    <FormProvider {...methods}>
      <form css={getSanitizedStyles([styles, dynamicStyles])}>
        <InlineConfirmation
          show={showConfirmationDelete}
          title={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.TITLE()}
          description={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.DESCRIPTION()}
          cancelText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.CANCEL()}
          confirmText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INLINE_CONFIRMATION.DELETE()}
          onCancel={() => setShowConfirmationDelete(false)}
          onConfirm={onDelete}
        >
          <>
            <div className='required-fields py-2'>
              <QuestionText text={questionText} onChange={handleChangeQuestionText} />
              <QuestionType
                questionTypeRef={selectRef}
                type={questionType}
                onChange={handleChangeQuestionType}
                error={error}
                setError={setError}
              />
              <RequiredSwitch
                reset={reset}
                isRequired={isRequired}
                setIsRequired={setIsRequired}
                isAttachedToRule={isAttachedToRule}
                disabled={!question.id}
              />
              <OptionsMenu canMoveUp={canMoveUp} onMoveUp={onMoveUp} canMoveDown={canMoveDown} onMoveDown={onMoveDown} onDelete={handleDelete} />
            </div>
            {error && !questionType && (
              <div className='d-flex mb-2'>
                <NvIcon icon='warning' size='small' className='text-danger mr-1' />
                <div className='text-danger'>{error}</div>
              </div>
            )}
            <div>
              {isDropdownOrChecklist ? (
                <ResponseOptions responseOptionsRef={responseOptionsRef} options={responseOptions} setOptions={setResponseOptions} />
              ) : null}
            </div>
          </>
        </InlineConfirmation>
      </form>
    </FormProvider>
  );
});

export default ProgramLevelField;
