import { normalize } from 'normalizr';
import { MentorshipProgramMatchingRulesSchema } from 'redux/schemas/api/mentoring-program-matching-rules';
import {
  createMentorshipProgramAutoMatchingRule,
  deleteMentorshipProgramAutoMatchingRule,
  getMentorshipProgramAutoMatchingRules,
  getMentorshipProgramAutoMatchingRulesTotalCount,
  resetAutoMatchingRulesList,
  updateMentorshipProgramAutoMatchingRule,
} from 'redux/actions/mentoring-program-matching-rules';
import { createReducer } from '@reduxjs/toolkit';
import { replaceArrays } from 'shared/lodash-utils';
import { mergeWith } from 'lodash';
import { initialRootState } from '.';

const setProgramMatchingRulesActionHandler = (state, action) => {
  const normalized = normalize(
    action.payload,
    MentorshipProgramMatchingRulesSchema,
  );

  mergeWith(state.models, normalized.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(
      getMentorshipProgramAutoMatchingRulesTotalCount.fulfilled,
      (state, action) => {
        const { autoMatchingRulesCount } = action.payload;
        state.app.mentoringProgram.settings.matchingRules.totalCount = autoMatchingRulesCount;
      },
    )
    .addCase(
      getMentorshipProgramAutoMatchingRules.fulfilled,
      setProgramMatchingRulesActionHandler,
    )
    .addCase(
      createMentorshipProgramAutoMatchingRule.fulfilled,
      setProgramMatchingRulesActionHandler,
    )
    .addCase(
      updateMentorshipProgramAutoMatchingRule.fulfilled,
      (state, action) => {
        const { ruleId } = action.meta.arg;
        state.models.mentorshipProgramMatchingRules[ruleId] = action.payload;
      },
    )
    .addCase(
      deleteMentorshipProgramAutoMatchingRule.fulfilled,
      (state, action) => {
        const { ruleId } = action.meta.arg;
        delete state.models.mentorshipProgramMatchingRules[ruleId];
      },
    )
    .addCase(
      resetAutoMatchingRulesList, (state, action) => {
        const { resetList } = action.payload;
        state.app.mentoringProgram.settings.matchingRules.resetList = resetList;
      },
    );
});
