import t from 'react-translate';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import { css } from '@emotion/react';
import {
  doubleSpacing,
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  lightFontWeight,
  semiBoldFontWeight,
  textLargeFontSize,
  textMediumLineHeight,
} from 'styles/global_defaults/fonts';
import { dividerMedium, shadedWhite } from 'athena/styles/colors';
import AthenaTextInput, { AthenaTextInputType } from 'athena/components/text-input';
import { MentoringProgramRole, MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import React, { useCallback, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { debounce } from 'lodash';
import { wrapThunkAction } from 'redux/utils';
import { useAppDispatch } from 'redux/store';
import {
  createMentorshipProgramConnection,
  setCreateConnectionModalSettings,
} from 'redux/actions/mentorship-program-connections';
import { MentorshipProgramConnectionMember } from 'redux/schemas/models/mentorship-program-connections';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { useSelector } from 'react-redux';
import { getCreateConnectionModalSettings } from 'redux/selectors/mentorship-program-connections';
import { getMentorshipProgramEnrollmentById } from 'redux/selectors/mentorship-program-enrollments';
import { CreateConnectionType } from 'redux/schemas';
import { Row } from '@tanstack/react-table';
import { config } from '@config/pendo.config.json';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { refreshProgramParticipantsView } from 'redux/actions/mentoring-program-enrollments';
import MentoringProgramContext from '../../context';
import ParticipantPreview from './participant-preview';
import ParticipantsList from './participants-list';
import { buttonStyles } from '../styles';
import ConfirmationModal, { ModalContentProps } from '../confirmation-modal';
import { MenteeRow, MentorRow } from '../../connections/participant-row';

const FLYOUT_MODAL_WIDTH = 478;
const HEADER_HEIGHT = 250;
const FOOTER_HEIGHT = 105;

const connectionAttribute = {
  [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR]: 'mentorEnrollmentId',
  [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE]: 'menteeEnrollmentId',
};

const commonStyles = css`
  width: ${FLYOUT_MODAL_WIDTH}px;
`;

const headerStyles = css`
  ${commonStyles};
  top: 0;

  h4 {
    padding: ${standardSpacing}px ${largeSpacing}px;
    margin: 0;
    font-size: ${textLargeFontSize}px;
    line-height: ${textMediumLineHeight}px;
    font-weight: ${semiBoldFontWeight};

    border-bottom: 1px solid ${dividerMedium};
  }

  .post-header {
    gap: ${halfSpacing}px;
    padding: 0 ${largeSpacing}px;
  }
`;

const contentStyles = css`
  top: ${HEADER_HEIGHT}px;
  bottom: ${FOOTER_HEIGHT}px;
  overflow-y: auto;
  padding: 0 ${largeSpacing}px;

  .search-input {
    height: ${doubleSpacing + quarterSpacing}px;
    .search-input-element {
      font-weight: ${lightFontWeight};
    }
  }
  .table-body {
    overflow-y: auto;
  }
`;

const footerStyles = css`
  ${commonStyles};
  height: ${FOOTER_HEIGHT}px;
  bottom: 0;
  border-top: 1px solid ${shadedWhite};
  padding: ${largeSpacing}px;
  gap: ${threeQuartersSpacing}px;

  ${buttonStyles}
`;

const CreateConnectionFlyoutModal = () => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [textSearch, setTextSearch] = useState<string>(null);
  const { type, enrollmentId } = useSelector(getCreateConnectionModalSettings);
  const enrollment: MentoringProgramEnrollment = useSelector(
    getMentorshipProgramEnrollmentById(enrollmentId),
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
    false,
  );
  const [modalContentProps, setModalContentProps] = useState<ModalContentProps>(
    null,
  );
  const [selectedEnrollments, setSelectedEnrollments] = useState<
  MentoringProgramEnrollment[]
  >(null);
  const {
    countState,
    mentoringProgram: { id: programId, concurrentMentorConnection },
  } = useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, programId));

  const debounceSetTextSearch = useCallback(debounce(setTextSearch, 500), []);

  const modalSettings = {
    [CreateConnectionType.ASSIGN_MENTOR]: {
      modalHeader: t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.ASSIGN_MENTOR.TITLE(mentoringProgramAliases),
      listType: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
      tableHeader: mentoringProgramAliases.MentorsTitleAlias?.toUpperCase(),
      rowType: MentorRow,
      enableMultiSelection: false,
      getMentorTotalConnections: () => {
        const [mentorEnrollment] = selectedEnrollments;
        return mentorEnrollment?.assignedConnections?.length;
      },
      getModalContent: () => {
        const [mentorEnrollment] = selectedEnrollments;
        const mentorFirstName = mentorEnrollment?.user?.firstName;
        const menteeFirstName = enrollment?.user?.firstName;
        const numberOfConnections = mentorEnrollment?.assignedConnections?.length;
        return t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.ASSIGN_MENTOR.CONFIRMATION_MODAL.CONTENT({
          menteeFirstName,
          mentorFirstName,
          numberOfConnections: `${numberOfConnections}`,
          ...mentoringProgramAliases,
        });
      },
    },
    [CreateConnectionType.ASSIGN_MENTEES]: {
      modalHeader: t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.ASSIGN_MENTEES.TITLE(mentoringProgramAliases),
      listType: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
      tableHeader: mentoringProgramAliases.MenteesTitleAlias?.toUpperCase(),
      rowType: MenteeRow,
      enableMultiSelection: true,
      getMentorTotalConnections: () => enrollment?.assignedConnections?.length,
      getModalContent: () => {
        const mentorFirstName = enrollment?.user?.firstName;
        const newTotalMentees = selectedEnrollments?.length;
        const numberOfConnections = enrollment?.assignedConnections?.length;
        return t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.ASSIGN_MENTEES.CONFIRMATION_MODAL.CONTENT({
          mentorFirstName,
          numberOfConnections: `${numberOfConnections}`,
          newTotalMentees: `${newTotalMentees}`,
          ...mentoringProgramAliases,
        });
      },
      enableRowSelection: useCallback(
        (row: Row<MentoringProgramEnrollment>) => {
          const { original: menteeRow } = row;
          const { assignedConnections: currentMentees } = enrollment;
          if (
            currentMentees?.length
            && currentMentees.some(item => item.assignedEnrollment.id === menteeRow.id)
          ) {
            return false;
          }
          return true;
        },
        [enrollment],
      ),
      filterRows: useCallback((item: MentoringProgramEnrollment) => {
        const { assignedConnections: currentMentees } = enrollment;
        if (
          currentMentees?.length
          && currentMentees.some(mentee => mentee.assignedEnrollment.id === item.id)
        ) {
          return true;
        }
        const { assignedConnections } = item;
        return !assignedConnections.length;
      }, []),
    },
  };

  const createConnection = () => wrapThunkAction(
    dispatch(
      createMentorshipProgramConnection({
        mentorshipProgramId: programId,
        connections: selectedEnrollments.map(selectedItem => ({
          [connectionAttribute[enrollment.role.name]]: enrollment.id,
          [connectionAttribute[selectedItem.role.name]]: selectedItem.id,
        })),
      }),
    ),
  )
    .then(action => {
      const totalConnectionsCreated = (action?.payload as MentorshipProgramConnectionMember[])
        ?.length;
      dispatch(
        addAlertMessage({
          header: t.FORM.SUCCESS(),
          type: AlertMessageType.SUCCESS,
          message: t.MENTORING_PROGRAMS.CONNECTIONS.SUCCESS_MESSAGE(
            totalConnectionsCreated,
          ),
        }),
      );
      dispatch(refreshProgramParticipantsView({ refreshView: true }));
    })
    .catch(() => {
      dispatch(
        addAlertMessage({
          header: t.FORM.ERROR(),
          type: AlertMessageType.ERROR,
        }),
      );
    })
    .finally(() => {
      setShowConfirmationModal(false);
      onClose();
    });

  const {
    rowType,
    listType,
    modalHeader,
    tableHeader,
    filterRows,
    getModalContent,
    enableRowSelection,
    enableMultiSelection,
    getMentorTotalConnections,
  } = modalSettings[type];

  const handleCreateConnection = () => {
    if (getMentorTotalConnections() >= concurrentMentorConnection) {
      setModalContentProps({
        content: getModalContent(),
        onConfirm: createConnection,
      });
      setShowConfirmationModal(true);
    } else {
      createConnection();
    }
  };

  const listRef = useCallback(
    table => {
      if (table) {
        const selected = table.getSelectedRowModel().flatRows;
        setDisabled(!selected.length);
        const selectedItems = selected.map(item => item.original);
        setSelectedEnrollments(selectedItems);
      }
    },
    [setSelectedEnrollments],
  );

  const onClose = () => dispatch(
    setCreateConnectionModalSettings({
      showModal: false,
      type: null,
      enrollmentId: null,
    }),
  );

  return (
    <>
      <NvFlyoutModal
        type={ModalType.RIGHT}
        width={FLYOUT_MODAL_WIDTH}
        zIndex={1040}
        onClose={onClose}
      >
        <div className='position-relative h-100'>
          <div css={headerStyles} className='position-fixed'>
            <h4>{modalHeader}</h4>
            <div className='d-flex flex-column post-header'>
              <ParticipantPreview enrollment={enrollment} />
              <AthenaTextInput
                icon='search'
                type={AthenaTextInputType.SEARCH}
                showLabel={false}
                className='search-input'
                inputClassName='search-input-element'
                onChange={({ target: { value } }) => debounceSetTextSearch(value)}
                placeholder={t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.SEARCH_PLACEHOLDER()}
              />
            </div>
          </div>
          <div css={contentStyles} className='position-absolute'>
            <ParticipantsList
              listRef={listRef}
              rowType={rowType}
              listType={listType}
              textSearch={textSearch}
              tableHeader={tableHeader}
              filterRows={filterRows}
              enableRowSelection={enableRowSelection}
              enableMultiSelection={enableMultiSelection}
            />
          </div>
          <div
            css={footerStyles}
            className='d-flex align-items-center justify-content-end position-fixed'
          >
            <Button variant='outline-primary' onClick={onClose}>
              {t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.CANCEL()}
            </Button>
            <Button
              variant='primary'
              disabled={disabled}
              onClick={handleCreateConnection}
              data-qa={config.pendo.athena.mentorshipProgram.connections.createConnection}
            >
              {t.MENTORING_PROGRAMS.CREATE_CONNECTION_FLYOUT_MODAL.SAVE()}
            </Button>
          </div>
        </div>
      </NvFlyoutModal>
      <ConfirmationModal
        showModal={showConfirmationModal}
        {...modalContentProps}
        onClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
};

export default CreateConnectionFlyoutModal;
