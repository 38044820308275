import { css } from '@emotion/react';
import { novoEdBlack } from 'athena/styles/colors';
import ClickableContainer from 'components/clickable-container';
import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { white } from 'styles/global_defaults/colors';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

type CollapsiblePanelProps = {
  title: string;
  children: React.ReactElement;
  iconSize?: string;
};

const styles = css`
  border-radius: 10px;
  padding: 24px ${standardSpacing}px;
  background-color: ${white};
  .title {
    color: ${novoEdBlack};
  }
`;

const CollapsiblePanel = ({ title, children, iconSize = 'small' }: CollapsiblePanelProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <div css={styles} className='collapsible-panel'>
      <ClickableContainer
        className='d-flex align-items-center justify-content-between'
        onClick={() => setIsExpanded(currentState => !currentState)}
      >
        <span className='heading-5 text-truncate title'>{title}</span>
        <NvIcon icon={isExpanded ? 'arrow-up' : 'arrow-down'} size={iconSize} />
      </ClickableContainer>
      <Collapse in={isExpanded}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsiblePanel;
