import { useState } from 'react';
import t from 'react-translate';

import { css } from '@emotion/react';
import { gray6 } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  extraLargeSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

import { NvTooltip } from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvPopover from 'shared/components/nv-popover';
import NvConfirmationPopover from 'shared/components/nv-confirmation-popover';

const styles = css`
  display: grid;
  align-items: center;
  column-gap: ${standardSpacing}px;
  grid-auto-rows: ${extraLargeSpacing}px;
  grid-template-columns: ${standardSpacing}px auto ${tripleSpacing}px;
  border-bottom: 1px solid ${gray6};
  .switch {
    width: ${doubleSpacing}px;
    height: ${standardSpacing}px;
    transform: scale(1.5);
    justify-self: center;
  }
`;

const overlayStyles = css`
  max-width: 280px;
  .confirmation {
    max-width: 100%;
    .button-container {
      justify-content: center;
    }
  }
`;

type SelectableQuestionRowProps = {
  isHidden?: boolean;
  isSelected: boolean;
  questionId: number;
  questionText: string;
  onChange: (questionId: number, isSelected: boolean, isAttachedToRule: boolean, attachedRules: { id: number }[]) => void;
  isAttachedToRule: boolean;
  attachedRules?: { id: number }[];
};

const SelectableQuestionRow = ({
  isHidden = false,
  isSelected,
  questionId,
  questionText,
  onChange,
  isAttachedToRule,
  attachedRules,
}: SelectableQuestionRowProps) => {
  const [isChecked, setIsChecked] = useState(isAttachedToRule || isSelected);
  const [isSwitchingRequired, setIsSwitchingRequired] = useState(false);
  const tooltip = isHidden ? t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.TOOLTIP.HIDDEN() : t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.TOOLTIP.SHOWN();

  const handleRequiredChange = (newState) => {
    if (!newState) setIsSwitchingRequired(true);
    else {
      setIsChecked(newState);
      onChange(questionId, newState, isAttachedToRule, attachedRules);
    }
  };

  const handleConfirm = () => {
    setIsSwitchingRequired(false);
    setIsChecked(false);
    onChange(questionId, false, isAttachedToRule, attachedRules);
  };

  return (
    <div css={styles}>
      <NvTooltip
        placement='top'
        preventOverflow={false}
        text={tooltip}
      >
        <NvIcon size='small' icon={isHidden ? 'hide' : 'view'} />
      </NvTooltip>
      <span className='text-large-body semi-bold'>{questionText}</span>
      <NvPopover
        enabled
        show={isSwitchingRequired}
        overlayStyles={overlayStyles}
        content={(
          <NvConfirmationPopover
            onCancel={() => setIsSwitchingRequired(false)}
            onConfirm={handleConfirm}
            header={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.TITLE()}
            message={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.DESCRIPTION()}
            cancelBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.CANCEL()}
            confirmBtnText={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.REQUIRED_POPOVER.OK()}
          />
        )}
        placement='left'
        rootClose
        onHide={() => setIsSwitchingRequired(false)}
        className='d-inline-flex mw-100'
        preventOverflow
      >
        <NvSwitch
          checked={isChecked}
          className='switch align-self-center'
          onChange={handleRequiredChange}
        />
      </NvPopover>
    </div>
  );
};

export default SelectableQuestionRow;
