import t from 'react-translate';
import { css } from '@emotion/react';
import { useAppDispatch } from 'redux/store';
import NvFlyoutModal, { ModalType } from 'shared/components/nv-flyout-modal';
import { dividerMedium } from 'athena/styles/colors';
import {
  largeSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { useContext, useState } from 'react';
import PillButton from 'athena/components/pill-button';
import { Button } from 'react-bootstrap';
import { gray6 } from 'styles/global_defaults/colors';
import {
  createMentorshipProgramAutoMatchingRule,
  deleteMentorshipProgramAutoMatchingRule,
  getMentorshipProgramAutoMatchingRulesTotalCount,
  resetAutoMatchingRulesList,
} from 'redux/actions/mentoring-program-matching-rules';
import {
  Expression,
  ImportanceLevel,
  ResponseType,
} from 'redux/schemas/models/mentoring-program-matching-rules';
import { wrapThunkAction } from 'redux/utils';
import { config } from '@config/pendo.config.json';
import { isEmpty } from 'underscore';
import SelectableOrgLevelQuestions from './selectable-org-level-questions';
import SelectableProgramLevelQuestions from './selectable-program-level-questions';
import MentoringProgramContext from '../../context';
import { ModifiedQuestions } from './types';

const FLYOUT_MODAL_WIDTH = 480;

const commonStyles = css`
  width: ${FLYOUT_MODAL_WIDTH}px;
`;

const styles = css`
  .title {
    ${commonStyles};
    border-bottom: 1px solid ${dividerMedium};
    padding: ${standardSpacing}px ${largeSpacing}px;
  }
  .filters {
    gap: ${threeQuartersSpacing}px;
    padding-top: 23px;
  }
  .rules-modal-content {
    ${commonStyles};
    top: 200px;
    bottom: 105px;
    overflow-y: auto;
    padding: 0 ${largeSpacing}px;
  }
  .rules-modal-footer {
    ${commonStyles};
    bottom: 0;
    padding: ${largeSpacing}px;
    gap: ${threeQuartersSpacing}px;
    border-bottom: 1px solid ${gray6};
  }
`;

enum Filter {
  ORG_LEVEL_FIELDS = 'orgLevelFields',
  PROGRAM_LEVEL_FIELDS = 'programLevelFields',
}

type MatchingRulesFlyoutModalProps = {
  onClose: () => void;
};

const MatchingRulesFlyoutModal = ({
  onClose,
}: MatchingRulesFlyoutModalProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const [modifiedQuestions, setModifiedQuestions] = useState<ModifiedQuestions>({});
  const [selectedFilter, setSelectedFilter] = useState<Filter>(
    Filter.ORG_LEVEL_FIELDS,
  );

  const updateSelectedQuestions = (questionId: number, isChecked: boolean, isAttachedToRule: boolean, attachedRules: { id: number }[]) => {
    setModifiedQuestions(currentQuestions => ({ ...currentQuestions, [questionId]: { isChecked, isAttachedToRule, attachedRules } }));
  };

  const filters = {
    [Filter.ORG_LEVEL_FIELDS]: {
      title: t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.ORG_LEVEL_FIELDS(),
      SelectedFilterQuestions: SelectableOrgLevelQuestions,
    },
    [Filter.PROGRAM_LEVEL_FIELDS]: {
      title: t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.PROGRAM_LEVEL_FIELDS(),
      SelectedFilterQuestions: SelectableProgramLevelQuestions,
    },
  };

  const getCheckedQuestionsToCreate = () => Object.fromEntries(Object.entries(modifiedQuestions).filter(([_, question]) => question.isChecked && !question.isAttachedToRule));
  const getUncheckedQuestionsToDelete = () => Object.fromEntries(Object.entries(modifiedQuestions).filter(([_, question]) => !question.isChecked && question.isAttachedToRule));

  const refreshList = () => {
    dispatch(
      getMentorshipProgramAutoMatchingRulesTotalCount({
        mentorshipProgramId,
      }),
    );
    dispatch(resetAutoMatchingRulesList({ resetList: true }));
  };

  const handleSaveRules = () => {
    const createRulesFromQuestions = getCheckedQuestionsToCreate();
    const deleteRulesFromQuestions = getUncheckedQuestionsToDelete();
    if (!isEmpty(createRulesFromQuestions)) {
      Object.keys(createRulesFromQuestions).forEach(mentorshipProgramProfileQuestionId => {
        const autoMatchingRule = {
          mentorshipProgramProfileQuestionId: Number(mentorshipProgramProfileQuestionId),
          responseType: ResponseType.STRING,
          expression: Expression.SIMILAR,
          importanceLevel: ImportanceLevel.PREFERRED,
        };
        wrapThunkAction(
          dispatch(
            createMentorshipProgramAutoMatchingRule({
              mentorshipProgramId,
              autoMatchingRule,
            }),
          ),
        ).then(refreshList);
      });
    }
    if (!isEmpty(deleteRulesFromQuestions)) {
      Object.values(deleteRulesFromQuestions).forEach(({ attachedRules }) => {
        attachedRules.forEach(({ id: ruleId }) => {
          wrapThunkAction(
            dispatch(
              deleteMentorshipProgramAutoMatchingRule({ mentorshipProgramId, ruleId }),
            ),
          ).then(refreshList);
        });
      });
    }
    onClose();
  };

  const { SelectedFilterQuestions } = filters[selectedFilter];

  return (
    <NvFlyoutModal
      type={ModalType.RIGHT}
      width={FLYOUT_MODAL_WIDTH}
      onClose={onClose}
    >
      <div css={styles} className='position-relative h-100'>
        <div className='position-fixed'>
          <div className='title heading-4'>
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.TITLE()}
          </div>
          <div className='pl-5 pr-5'>
            <div className='pt-4'>
              {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.DESCRIPTION()}
            </div>
            <div className='filters d-flex pb-4'>
              {Object.entries(filters).map(([filter, { title }]) => (
                <PillButton
                  text={title}
                  selected={filter === selectedFilter}
                  onClick={() => setSelectedFilter(filter as Filter)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='rules-modal-content position-absolute'>
          <SelectedFilterQuestions
            modifiedQuestions={modifiedQuestions}
            onChange={updateSelectedQuestions}
          />
        </div>
        <div className='rules-modal-footer d-flex align-items-center justify-content-end position-fixed'>
          <Button variant='outline-primary' onClick={onClose}>
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.CANCEL()}
          </Button>
          <Button
            variant='primary'
            disabled={!Object.keys(modifiedQuestions).length}
            onClick={handleSaveRules}
            data-qa={config.pendo.athena.mentorshipProgram.settings.matchingRules.createMatchingRules}
          >
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.SAVE()}
          </Button>
        </div>
      </div>
    </NvFlyoutModal>
  );
};

export default MatchingRulesFlyoutModal;
