import t from 'react-translate';
import NvIcon from 'shared/components/nv-icon';
import { config } from '@config/pendo.config.json';
import ClickableContainer from 'components/clickable-container';

type AddQuestionButtonProps = {
  onClick: () => void;
};

const AddQuestionButton = ({ onClick }: AddQuestionButtonProps) => {
  const addQuestionLabel = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.ADD_QUESTION();

  return (
    <ClickableContainer
      onClick={onClick}
      className='add-new-question align-items-center semi-bold pt-2'
      aria-label={addQuestionLabel}
      data-qa={
        config.pendo.athena.mentorshipProgram.settings.programProfileSetup
          .createProgramLevelProfileQuestion
      }
    >
      <NvIcon icon='add' size='smallest' />
      <span>{addQuestionLabel}</span>
    </ClickableContainer>
  );
};

export default AddQuestionButton;
