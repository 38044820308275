import { MentoringProgramEnrollment } from './mentoring-program-enrollments';

export enum MentorshipProgramConnectionType {
  MANUAL = 'manual',
  AUTO_GENERATED = 'auto',
}

export type MentorshipProgramConnectionMember = {
  id: number;
  mentorshipProgramEnrollment: Pick<MentoringProgramEnrollment, 'id' | 'user' | 'role' >;
};

export type MentorshipProgramConnection = {
  id: number;
  mentorshipProgramId: number;
  formationType: MentorshipProgramConnectionType;
  mentorshipProgramConnectionMembers: MentorshipProgramConnectionMember[];
  createdAt?: Date;
};
