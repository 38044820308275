import t from 'react-translate';
import { css } from '@emotion/react';
import NvIcon from 'shared/components/nv-icon';
import {
  black,
  gray2,
  gray3,
  gray7,
  success,
} from 'styles/global_defaults/colors';
import {
  largeSpacing,
  quarterSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';

type SaveToastProps = {
  status: SaveStatus;
};

const styles = css`
  gap: ${quarterSpacing}px;
  width: 75px;
  height: ${largeSpacing}px;
  background: ${gray7};
  border-radius: ${threeQuartersSpacing}px;

  &.in-progress {
    i {
      color: ${gray3};
    }
    span {
      color: ${gray2};
    }
  }
  &.completed {
    i {
      color: ${success};
    }
    span {
      color: ${black};
    }
  }
`;

const SaveToast = ({ status }: SaveToastProps) => {
  const toast = {
    [SaveStatus.IN_PROGRESS]: {
      icon: 'refresh',
      label: t.MENTORING_PROGRAMS.SETTINGS.SAVING(),
    },
    [SaveStatus.COMPLETED]: {
      icon: 'check',
      label: t.MENTORING_PROGRAMS.SETTINGS.SAVED(),
    },
  };

  const { icon, label } = toast[status] || {};

  return (
    <div
      css={styles}
      className={`d-flex align-items-center justify-content-center ${status}`}
    >
      <NvIcon
        icon={icon}
        size='xss-smallest'
        className={status === SaveStatus.IN_PROGRESS ? 'spin' : ''}
      />
      <span className='text-small semi-bold'>{label}</span>
    </div>
  );
};

export default SaveToast;
