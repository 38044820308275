import React from 'react';
import * as yup from 'yup';
import { css } from '@emotion/core';
import { Button } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import t from 'react-translate';
import AthenaTextInput from 'athena/components/text-input';
import RoleSelect from 'athena/components/mentoring-program/role-select';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { config } from '@config/pendo.config.json';
import { isEmpty } from 'underscore';

export type FormData = {
  email: string;
  lastName: string;
  firstName: string;
  role: MentoringProgramRole;
};

type Props = {
  onSubmit: SubmitHandler<FormData>;
  onClose?: () => void;
  defaultValues?: FormData;
  disabled?: boolean;
};

const UserForm = (props: Props) => {
  const {
    onClose,
    onSubmit,
    defaultValues,
    disabled = false,
  } = props;

  const isEditing = !!defaultValues;

  const validationSchema = yup.object().shape({
    firstName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
    lastName: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(255, t.VALIDATION.MAX_LENGTH('255')),
    email: yup.string()
      .required(t.VALIDATION.REQUIRED())
      .email(t.VALIDATION.EMAIL()),
    role: yup.string().nullable().required(),
  });

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: defaultValues ?? {
      email: '',
      role: null,
      lastName: '',
      firstName: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, formState } = methods;

  const styles = css`
    .form-input {
      flex: 1;
    }
  `;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='mb-4' css={styles}>
        <div className='heading-5 mb-2'>
          {t.MENTORING_PROGRAMS.PARTICIPANTS.ADD_MANUALLY()}
        </div>
        <div className='d-flex mb-4'>
          <AthenaTextInput
            required
            disabled={disabled}
            withForm
            name='firstName'
            className='form-input mr-2'
            placeholder={t.USERS.REGISTRATION.FIRST_NAME()}
          />
          <AthenaTextInput
            required
            disabled={disabled}
            withForm
            name='lastName'
            className='form-input ml-2'
            placeholder={t.USERS.REGISTRATION.LAST_NAME()}
          />
        </div>
        <div className='d-flex mb-4'>
          <AthenaTextInput
            required
            disabled={disabled}
            withForm
            name='email'
            className='form-input mr-2'
            placeholder={t.USERS.REGISTRATION.EMAIL_ADDRESS()}
          />
          <RoleSelect withForm name='role' className='form-input ml-2' />
        </div>
        <div className={`d-flex ${isEditing ? 'justify-content-center' : ''}`}>
          {isEditing && (
            <Button variant='outline-primary' onClick={onClose} className='mr-3'>
              {t.FORM.CANCEL()}
            </Button>
          )}
          <Button
            type='submit'
            disabled={!formState.isDirty || !isEmpty(formState.errors) || formState.isSubmitting}
            data-qa={isEditing ? config.pendo.athena.mentorshipProgram.participants.editParticipant : config.pendo.athena.mentorshipProgram.participants.addParticipant}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {formState.isSubmitting ? (isEditing ? t.FORM.SAVING() : t.FORM.ADDING()) : (isEditing ? t.FORM.SAVE() : t.FORM.ADD())}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default UserForm;
