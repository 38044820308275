import { css } from '@emotion/react';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import ClickableContainer from 'components/clickable-container';
import { gray1, gray2, gray6, gray7, warning, white } from 'styles/global_defaults/colors';
import { semiBoldFontWeight, textLargeBodyFontSize, textMediumFontSize, textXSFontSize } from 'styles/global_defaults/fonts';
import { extraLargeSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { getMentoringProgramSessions, getMentoringProgramSessionsCounts, resetMentoringProgramSessionsList } from 'redux/actions/mentoring-program-sessions';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import prodPathReplace from 'shared/prod-path-rewrite';
import { getMentorshipProgramSessionsList, getNextMentorshipProgramSession, getResetMentoringProgramSessionsListState } from 'redux/selectors/mentorship-program-sessions';
import NextSession from './next-session';
import SessionCard from './session-card';


enum SessionFilters {
  PAST = 'past',
  UPCOMING = 'upcoming',
}

type SessionsListProps = {
  showPlanSessionFlyout: (session?: MentorshipProgramSession) => void,
};

const SessionsList = ({
  showPlanSessionFlyout,
}: SessionsListProps) => {
  const styles = css`
    .filter-button {
      font-weight: ${semiBoldFontWeight};
      font-size: ${textMediumFontSize}px;

      padding-left: ${standardSpacing}px;
      padding-right: ${standardSpacing}px;
      border-radius: ${largeSpacing}px;

      color: ${gray2};
      background-color: ${gray6};
      border: unset;

      &.active {
        color: ${gray1};
        background-color: ${white};
        border: 1px solid ${gray1};
      }
    }

    .empty-sessions {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${standardSpacing}px;

      border-radius: ${halfSpacing}px;
      background-color: ${gray7};
      color: ${gray2};

      img {
        height: ${extraLargeSpacing}px;
        width: ${extraLargeSpacing}px;
        margin-bottom: ${halfSpacing}px;
      }
    }

    .add-session-button {
      font-weight: ${semiBoldFontWeight};
      font-size: ${textLargeBodyFontSize}px;
    }
  `;

  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const [filter, setFilter] = useState<SessionFilters>(SessionFilters.UPCOMING);
  const [pastSessionsCount, setPastSessionsCount] = useState(0);
  const [upcomingSessionsCount, setUpcomingSessionsCount] = useState(0);

  const nextSession = useSelector((state) => getNextMentorshipProgramSession(state, parseInt($state.params.connectionId, 10)));

  const scrollRef = useRef<HTMLDivElement>();
  const reachedEnd = useInfiniteScroll(scrollRef.current, 300);
  const resetList = useSelector(getResetMentoringProgramSessionsListState);

  const params = useMemo(() => ({
    pageSize: 20,
    filter,
  }), [filter]);

  const { result, loadMore, reset } = usePaginatedApi<any, any>(
    (p) => dispatch(getMentoringProgramSessions({
      programId: mentoringProgram.id,
      connectionId: $state.params.connectionId,
      ...p,
    }))
      .then((action) => action.payload),
    params,
    getMentorshipProgramSessionsList,
  );

  useEffect(() => {
    if (reachedEnd) {
      loadMore();
    }
  }, [loadMore, reachedEnd]);

  useEffect(() => {
    if (resetList) {
      reset();
      dispatch(resetMentoringProgramSessionsList({ resetList: false }));

      dispatch(getMentoringProgramSessionsCounts({
        programId: mentoringProgram.id,
        connectionId: $state.params.connectionId,
      })).then((action) => {
        setPastSessionsCount(action.payload.past);
        setUpcomingSessionsCount(action.payload.upcoming);
      });
    }
  }, [dispatch, reset, resetList]);

  useEffect(() => {
    dispatch(getMentoringProgramSessionsCounts({
      programId: mentoringProgram.id,
      connectionId: $state.params.connectionId,
    })).then((action) => {
      setPastSessionsCount(action.payload.past);
      setUpcomingSessionsCount(action.payload.upcoming);
    });
  }, []);

  return (
    <Fragment>
      <NextSession session={nextSession} showPlanSessionFlyout={showPlanSessionFlyout} />
      <CollapsiblePanel
        title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.TITLE(pastSessionsCount + upcomingSessionsCount)}
      >
        <div css={styles}>
          <div className='button-row d-flex align-items-center my-4'>
            <Button
              className={`filter-button ${filter === 'upcoming' ? 'active' : ''}`}
              onClick={() => setFilter(SessionFilters.UPCOMING)}
            >
              {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.UPCOMING(upcomingSessionsCount)}
            </Button>
            <Button
              className={`filter-button ml-2 ${filter === 'past' ? 'active' : ''}`}
              onClick={() => setFilter(SessionFilters.PAST)}
            >
              {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.PAST(pastSessionsCount)}
            </Button>
            <Button
              variant='link'
              onClick={() => showPlanSessionFlyout()}
              className='add-session-button ml-auto'
            >
              {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.ADD_SESSION()}
            </Button>
          </div>
          <div ref={scrollRef}>
            {result?.map((item) => (
              <ClickableContainer onClick={() => showPlanSessionFlyout(item)} className='mt-2'>
                <SessionCard title={item.title} time={item.scheduledDate} />
              </ClickableContainer>
            ))}
          </div>
          {!result?.length && (
            <div className='empty-sessions'>
              <img src={prodPathReplace('images/no-sessions.svg')} alt='' />
              <div>{filter === 'upcoming' ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.NO_UPCOMING_SESSIONS() : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.SESSIONS.NO_PAST_SESSIONS()}</div>
            </div>
          )}
        </div>
      </CollapsiblePanel>
    </Fragment>
  );
};

export default SessionsList;
