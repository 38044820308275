import * as yup from 'yup';

yup.addMethod(yup.string, 'novoEdCatalogId', function (errorMessage) {
  const catalogIdRegex = /^[a-zA-Z0-9_-]+$/;

  return (this as any).test('test-novoed-catalog-id', errorMessage, function (value) {
    return catalogIdRegex.test(value)
      ? true
      : this.createError({ path: this.path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'notOnlySpaces', function (errorMessage) {
  return (this as any).test('test-string-spaces', errorMessage, function (value) {
    return value.trim().length
      ? true
      : this.createError({ path: this.path, message: errorMessage });
  });
});

yup.addMethod(yup.string, 'noNumbers', function (errorMessage) {
  const noNumbersRegex = /^([^0-9]*)$/;

  return (this as any).test('test-no-numbers', errorMessage, function (value) {
    return noNumbersRegex.test(value)
      ? true
      : this.createError({ path: this.path, message: errorMessage });
  });
});
