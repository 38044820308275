import { createSelector } from 'reselect';

import { RootState } from 'redux/schemas';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';

export const getMentoringProgramEnrollments = (state: RootState) => state.models.mentoringProgramEnrollments;

export const getMentorshipProgramEnrollmentById = (enrollmentId: number) => (
  state: RootState,
) => state.models.mentoringProgramEnrollments[enrollmentId];

export const getProgramParticipantCounts = (state: RootState) => state.app.mentoringProgram.participantCounts;

export const getMentoringProgramEnrollmentsList = createSelector(
  (state: RootState) => state.models.mentoringProgramEnrollments,
  (state, ids: number[]) => ids,
  (mentoringProgramEnrollments: Record<number, MentoringProgramEnrollment>, ids: number[]) => ids.map((id) => mentoringProgramEnrollments[id]),
);

export const getProgramParticipantsViewRefreshState = (state: RootState) => state.app.mentoringProgram.participantView.refresh;
