import React from 'react';
import { css } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';
import { almostBlack, darkGray, dividerMedium } from 'athena/styles/colors';
import { doubleSpacing, halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { gray2 } from 'styles/global_defaults/colors';

type Props = {
  label?: string;
  className?: string;
  showLabel?: boolean;
  valueLabel?: string;
  placeholder?: string;
};

const Toggle = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { label, className, showLabel = true, valueLabel, placeholder } = props;

  const styles = css`
    .toggle-label {
      left: 0;
      font-weight: 600;
      color: ${darkGray};
      top: -${standardSpacing}px;
    }

    .toggle {
      width: 100%;
      height: ${doubleSpacing}px;
      border: 1px solid ${dividerMedium};
      border-radius: ${quarterSpacing}px;


      .text-medium {
        flex: 1;
        color: ${valueLabel ? almostBlack : gray2};
      }

      .icon {
        color: ${darkGray};
        // Defining size this way because there's no size class that corresponds
        // to 10px
        font-size: ${halfSpacing}px;
      }
    }
  `;

  return (
    <div className={className} css={styles}>
      {showLabel && (
        <div className='toggle-label text-small mb-1'>
          {label ?? placeholder}
        </div>
      )}
      <div
        ref={ref}
        className='d-flex align-items-center pl-2 pr-2 toggle'
      >
        <div className='text-medium'>{valueLabel ?? placeholder}</div>
        <NvIcon icon='dropdown-arrow' size='' className='ml-2' />
      </div>
    </div>
  );
});

export default Toggle;
