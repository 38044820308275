import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import ClickableContainer from 'components/clickable-container';
import { notDesktop } from 'styles/global_defaults/media-queries';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { gray4, gray6, primary, white } from 'styles/global_defaults/colors';
import {
  halfSpacing,
  tripleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

type Props = {
  title: string,
  titleClassName?: string,
  titleLink?: {
    title: string,
    href: string,
  },
  children?: React.ReactNode,
  backButton?: boolean,
  backAction?: () => void,
};

const L1PageHeader = (props: Props) => {
  const {
    title,
    titleClassName,
    titleLink,
    children,
    backButton,
    backAction,
  } = props;

  const currentInstitution = useSelector(getCurrentInstitution);
  const { $rootScope } = React.useContext(AngularServicesContext);
  const styles = css`
    display: flex;
    height: ${tripleSpacing}px;
    justify-content: space-between;
    border-bottom: 1px solid ${gray6};
    border-top: ${quarterSpacing}px solid ${currentInstitution.brandColor};
    background-color:${white};
    .render-left {
      display: flex;
      .back-button{
        color:${primary};
        justify-content:center;
        display:flex;
        align-items:center;
      }
      .lhs-menu {
        width: 54px;
        height: 54px;
        display: none;
        align-items: center;
        justify-content: center;

        ${notDesktop(css`
          display: flex;
        `)};
      }

      .titles {
        display: flex;
        flex-direction: column;
        padding: ${quarterSpacing}px ${standardSpacing}px ${halfSpacing}px ${standardSpacing}px;

        ${notDesktop(css`
          padding: 0;
          justify-content: center;
        `)}

        .institution-name {
          color: ${gray4};

          ${notDesktop(css`
            display: none;
          `)};

          &:hover {
            color: ${primary};
          }
        }
      }
    }
  `;

  const handleLhsMenuClick = () => $rootScope.$$childHead.MainGridCtrl.toggleLhsMobile();

  return (
    <div css={styles} className='pr-4'>
      <div className='render-left'>
        <div className='lhs-menu'>
          <ClickableContainer onClick={handleLhsMenuClick}>
            <NvIcon icon='menu' size='small' />
          </ClickableContainer>
        </div>
        <div className='titles'>
          <a
            href={titleLink.href}
            className='institution-name course-title-xxs'
          >
            {titleLink.title}
          </a>
          <div className='course-subtitle d-flex'>
            <div className='back-button'>{backButton
            && (
            <ClickableContainer onClick={() => backAction()}>
              <NvIcon size='small' icon='back' className='mr-2' />
            </ClickableContainer>
            )}
            </div>
            <div className={titleClassName}>
              {title}
            </div>
          </div>
        </div>
      </div>
      <div className='render-right'>
        {children}
      </div>
    </div>
  );
};

export default L1PageHeader;
