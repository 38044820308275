import React from 'react';

export const AthenaContext = React.createContext(false);

const useIsAthenaApp = () => {
  const isAthenaApp = React.useContext(AthenaContext);

  return isAthenaApp;
};

export default useIsAthenaApp;
