
import { useContext } from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import {
  ReactComponent as ProgramClosedPicture,
} from 'styles/icons/program-closed.svg';

const IMAGE_HEIGHT = 150;
const IMAGE_WIDTH = 146.42;


const ProgramClosed = () => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const goToDashboard = () => $state.go('dashboard');

  return (
    <div className='unreleased-section d-flex flex-column align-items-center justify-content-center'>
      <ProgramClosedPicture
        height={IMAGE_HEIGHT}
        width={IMAGE_WIDTH}
      />
      <div className='heading-4 mt-6 mb-1'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.TITLE()}
      </div>
      <div className='text-body-large description mb-6'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.DESCRIPTION()}
      </div>
      <Button variant='primary' onClick={goToDashboard}>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.BACK_TO_DASHBOARD()}
      </Button>
    </div>
  );
};

export default ProgramClosed;
