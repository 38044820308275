import { css } from '@emotion/react';
import { blue05, novoEdBlack, novoEdBlue } from 'athena/styles/colors';
import { useState } from 'react';
import { gray5, gray7, primary } from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { ReactComponent as CheckedBox } from 'styles/icons/checked-box.svg';
import { ReactComponent as UncheckedBox } from 'styles/icons/unchecked-box.svg';

const styles = css`
  .multi-select-option {
    gap: ${halfSpacing}px;
    cursor: pointer;
    height: ${tripleSpacing}px;
    background-color: ${gray7};
    border-radius: ${quarterSpacing}px;

    .option-label {
      margin-bottom: 0;
      font-size: 16px;
      color: ${novoEdBlack};
    }
  }
  .checked {
    background-color: ${blue05};
    border: 1px solid ${novoEdBlue};
    .option-label {
      color: ${novoEdBlue};
      :before {
        color: ${novoEdBlue} !important;
        border: 2px solid ${novoEdBlue} !important;
      }
    }
  }
`;

type Option = {
  label: string;
  value: string;
};

type MultiSelectCheckListProps = {
  label: string;
  options: Option[];
  onChange?: (event) => void;
  className?: string;
  selectedOptions?: string[];
};

const MultiSelectCheckList = ({
  label,
  options,
  className,
  onChange,
  selectedOptions,
}: MultiSelectCheckListProps) => {
  const [selected, setSelected] = useState<string[]>(selectedOptions || []);

  const handleChange = (value: string, checked: boolean) => {
    const updatedSelection = checked
      ? [...selected, value]
      : selected.filter((option) => option !== value);
    setSelected(updatedSelection);
    onChange(updatedSelection);
  };

  return (
    <div css={styles} className={className}>
      <div className='input-label text-regular semi-bold mb-4'>{label}</div>
      {options.map(({ label: optionLabel, value: optionValue }) => {
        const isChecked = selected?.includes(optionValue);
        const sizeProps = { width: largeSpacing, height: largeSpacing };
        return (
          <div
            key={optionValue}
            onClick={() => handleChange(optionValue, !isChecked)}
            className={`multi-select-option mb-2 d-flex align-items-center p-3 ${
              isChecked ? 'checked' : ''
            }`}
          >
            {isChecked ? (
              <CheckedBox {...sizeProps} color={primary} />
            ) : (
              <UncheckedBox {...sizeProps} color={gray5} />
            )}
            <div className='text-large-body option-label'>{optionLabel}</div>
          </div>
        );
      })}
    </div>
  );
};

export default MultiSelectCheckList;
