import { RootState } from 'redux/schemas';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { createSelector } from 'reselect';

export const getMentorshipProgramProfileAnswers = (state: RootState) => state.models.mentorshipProgramProfileAnswers;

export const getMentorshipProgramProfileAnswersList = (enrollmentId: number) => createSelector(
  (state: RootState) => state.models.mentorshipProgramProfileAnswers[enrollmentId],
  (state, ids: number[]) => ids,
  (mentorshipProgramProfileAnswers: Record<number, MentorshipProgramProfileAnswer>, ids: number[]) => ids.map((id) => mentorshipProgramProfileAnswers[id]),
);
