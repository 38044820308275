import React from 'react';

import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';

export type ParticipantsContextValue = {
  reset: () => void;
  roleFilter: MentoringProgramRole;
  countState: {
    result: any;
    isLoading: boolean;
    refresh: () => Promise<void>;
  },
};

const ParticipantsContext = React.createContext<ParticipantsContextValue>(null);

export default ParticipantsContext;
