import { Button } from 'react-bootstrap';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { gray1, white } from 'styles/global_defaults/colors';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { headerLineHeight, textSmallLineHeight } from 'styles/global_defaults/fonts';
import t from 'react-translate';

const inlineConfirmationStyles = css`
  background-color: ${gray1};
  color: ${white};
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  .bs4-btn {
    height: ${headerLineHeight}px;
    padding: 3px ${halfSpacing}px;
    line-height: ${textSmallLineHeight}px;
  }
}`;

export enum InlineConfirmationType {
  NORMAL = 'normal',
  CUSTOM = 'custom',
}

export type InlineConfirmationProps = {
  show?: boolean;
  type?: InlineConfirmationType;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  children: React.ReactNode;
  customConfirmation?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
};

const NormalInlineConfirmation = ({
  title = t.FORM.ARE_YOU_SURE(),
  description = t.FORM.WARNING(),
  cancelText = t.FORM.CANCEL(),
  confirmText = t.FORM.CONFIRM(),
  onCancel,
  onConfirm,
}: InlineConfirmationProps) => (
  <div
    css={inlineConfirmationStyles}
    className='inline-confirmation-container d-flex flex-column text-center align-items-center justify-content-center'
  >
    <div className='inline-confirmation-title bold mb-1'>{title}</div>
    <div className='text-small mb-2'>{description}</div>
    <div className='d-flex'>
      <Button variant='outline-info' onClick={onCancel} size='sm' className='mr-3'>
        {cancelText}
      </Button>
      <Button variant='danger' onClick={onConfirm} size='sm'>
        {confirmText}
      </Button>
    </div>
  </div>
);

const InlineConfirmation = (props: InlineConfirmationProps) => {
  const {
    show = false,
    type = InlineConfirmationType.NORMAL,
    children,
    customConfirmation,
  } = props;

  const confirmation = useMemo(() => {
    switch (type) {
      case InlineConfirmationType.CUSTOM:
        return customConfirmation;
      case InlineConfirmationType.NORMAL:
      default:
        return <NormalInlineConfirmation {...props} />;
    }
  }, [type]);

  return (
    <>
      {show && confirmation}
      {children}
    </>
  );
};

export default InlineConfirmation;
