import prodPathReplace from 'shared/prod-path-rewrite';

import { User } from 'redux/schemas/models/my-account';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';

import NvIcon from 'shared/components/nv-icon';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { AccountField } from 'athena/components/mentoring-program/constants';
import AvatarPill from './avatar-pill';

const BACKGROUND_IMAGE_HEIGHT = 120;

type MainInfoType = {
  key?: string;
  text?: string | React.ReactNode;
  className?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
};

const getSection = ({
  key,
  text,
  className,
  prepend = null,
  append = null,
}: MainInfoType) => (text ? (
  <div key={key} className={className}>
    {prepend}
    {text}
    {append}
  </div>
) : null);

const mainInfo: MainInfoType[] = [
  {
    key: AccountField.DISPLAY_LOCATION,
    className: 'text-small mb-2 d-flex justify-content-center',
    prepend: (
      <NvIcon className='warning mr-2' icon='location' size='smallest' />
    ),
  },
  { key: AccountField.HEADLINE, className: 'heading-6 mb-1 bold' },
  { key: AccountField.BIO, className: 'gray-1' },
];

type AccountLevelInfoProps = {
  user: User;
  role: MentoringProgramRole;
  accountInfo: MentorshipProgramProfileAnswer[];
};

const AccountLevelInfo = ({
  user,
  role,
  accountInfo,
}: AccountLevelInfoProps) => {
  const { fullName } = user;
  const getText = (key) => accountInfo?.find((accountField) => !accountField.isHidden && accountField.novoedQuestionName === key)
    ?.answer;

  return (
    <div className='d-flex flex-column align-items-center'>
      <img
        src={prodPathReplace('images/mentee-bg.png')}
        alt='profile'
        height={BACKGROUND_IMAGE_HEIGHT}
      />
      <div className='w-100 px-5 pb-4 info-container'>
        <AvatarPill user={user} role={role} />
        <div className='text-center pt-2'>
          <div className='heading-4 mb-2'>{fullName}</div>
          {mainInfo.map((item) => getSection({
            ...item,
            text: getText(item.key),
          }))}
        </div>
      </div>
    </div>
  );
};

export default AccountLevelInfo;
