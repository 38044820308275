import { mergeWith } from 'lodash';
import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import { createMentoringProgramSession, getMentoringProgramSessions, resetMentoringProgramSessionsList } from 'redux/actions/mentoring-program-sessions';
import { MentoringProgramEnrollmentSchema, MentoringProgramEnrollmentsSchema } from 'redux/schemas/api/mentoring-program-enrollments';
import { MentorshipProgramSessionsSchema } from 'redux/schemas/api/mentoring-program-sessions';
import { CompletionStatus } from 'redux/schemas/models/mentoring-program-enrollments';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getMentoringProgramSessions.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentorshipProgramSessionsSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(createMentoringProgramSession.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentorshipProgramSessionsSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);

      const { enrollmentId, mentorshipProgramConnectionId } = action.meta.arg;
      const connectionIndex = state.models.mentoringProgramEnrollments[enrollmentId].assignedConnections.findIndex((c) => c.connectionId === mentorshipProgramConnectionId);
      state.models.mentoringProgramEnrollments[enrollmentId].states.waitingFirstSession.status = CompletionStatus.COMPLETED;
      state.models.mentoringProgramEnrollments[enrollmentId].assignedConnections[connectionIndex].isSessionCreated = true;
    })
    .addCase(resetMentoringProgramSessionsList, (state, action) => {
      const { resetList } = action.payload;
      state.app.mentoringProgram.sessions.resetList = resetList;
    });
});
