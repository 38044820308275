import React from 'react';
import { css } from '@emotion/react';
import { Controller } from 'react-hook-form';

import { sidewalkGray } from 'athena/styles/colors';
import { white } from 'styles/global_defaults/colors';
import { largeSpacing } from 'styles/global_defaults/scaffolding';
import NumberInputArrows from 'shared/components/number-input-arrows';

// Inheriting props from native input component, but overriding value and
// onChange props to use number values only.
type BaseNumberInputProps = Omit<React.ComponentProps<'input'>, 'value' | 'onChange'> & {
  value?: number;
  onChange?: (newValue: number) => void;
};

const BaseNumberInput = (props: BaseNumberInputProps) => {
  const { min, max, value, onChange, className, ...restProps } = props;

  // This function triggers the change of the input, making sure that the
  // incoming value respects the constraints (min, max, number only, etc).
  const setValue = (newValue: string) => {
    let actualValue: string | number = newValue;

    if (Number.isNaN(Number(newValue)) || !newValue) {
      actualValue = min ?? 0;
    } else if (newValue) {
      actualValue = Math.min((max as number) ?? Infinity, Math.max(min as number, Number(newValue)));
    }

    onChange(actualValue as unknown as number);
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const styles = css`
    border-radius: 4px;
    height: ${largeSpacing}px;
    background-color: ${white};
    border: 1px solid ${sidewalkGray};

    input {
      border: 0;
      width: ${largeSpacing}px;
      background-color: transparent;
    }
  `;

  const increase = () => {
    setValue((value + 1).toString());
  };

  const decrease = () => {
    setValue((value - 1).toString());
  };

  return (
    <div css={styles} className={`d-flex align-items-center pl-1 pr-1 ${className}`}>
      <input
        min={min}
        max={max}
        type='number'
        value={value}
        onChange={handleInputChange}
        className='text-regular text-center mr-1'
        {...restProps}
      />
      <NumberInputArrows
        onUp={() => {
          increase();
        }}
        onDown={() => {
          decrease();
        }}
      />
    </div>
  );
};

type Props = BaseNumberInputProps & {
  withForm?: boolean;
  name?: string;
};

const NumberInput = (props: Props) => {
  const { value, name, onBlur, onChange, withForm, ...restProps } = props;

  return withForm ? (
    <Controller
      name={name}
      render={({ field }) => {
        const handleBlur = (e) => {
          onBlur?.(e);
          field.onBlur();
        };

        const handleChange = (e) => {
          onChange?.(e);
          field.onChange(e);
        };

        return (
          <BaseNumberInput
            name={field.name}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value ?? field.value}
            {...restProps}
          />
        );
      }}
    />
  ) : (
    <BaseNumberInput
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      {...restProps}
    />
  );
};

export default NumberInput;
