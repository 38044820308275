import React from 'react';
import { Button } from 'react-bootstrap';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvIcon from 'shared/components/nv-icon';
import { createMentoringProgram } from 'redux/actions/mentoring-programs';
import ProgramBasicsModal, {
  FormValue as ProgramBasicsModalFormValue,
} from 'athena/components/program-basics-modal';
import { config } from '@config/pendo.config.json';

type Props = {
  onCreate: (createdProgramId: number) => void;
};

const NewProgramButton = (props: Props) => {
  const { onCreate } = props;

  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useAppDispatch();

  const handleModalSubmit = (formValues: ProgramBasicsModalFormValue) => {
    wrapThunkAction(dispatch(createMentoringProgram(formValues))).then((action) => {
      onCreate((action.payload as any).id);
      setShowModal(false);
    });
  };

  return (
    <>
      <ProgramBasicsModal
        show={showModal}
        onSubmit={handleModalSubmit}
        onClose={() => setShowModal(false)}
      />
      <Button
        onClick={() => setShowModal(true)}
        className='d-flex flex-row align-items-center'
        data-qa={config.pendo.athena.mentorshipProgram.createProgram}
      >
        <NvIcon icon='create-new-post' size='smallest' className='mr-2' />
        {t.MENTORING_PROGRAMS.NEW_PROGRAM()}
      </Button>
    </>
  );
};

export default NewProgramButton;
