import React from 'react';
import { css } from '@emotion/react';

import { gray6 } from 'styles/global_defaults/colors';
import { commonStyles } from 'athena/components/mentoring-program/participants/participant-row/styles';
import { doubleSpacing, halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

type Props = {
  className?: string;
};

const LoadingRow = (props: Props) => {
  const { className } = props;

  const styles = css`
    ${commonStyles};

    .avatar-placeholder {
      width: ${doubleSpacing}px;
      height: ${doubleSpacing}px;
      border-radius: ${standardSpacing}px;
    }

    .avatar-placeholder, .top-bar, .bottom-bar {
      background-color: ${gray6};
    }

    .top-bar, .bottom-bar {
      height: ${halfSpacing}px;
      border-radius: ${quarterSpacing}px;
    }

    .top-bar {
      width: 200px;
    }

    .bottom-bar {
      width: 100px;
    }
  `;

  return (
    <div css={styles} className={`d-flex align-items-center ${className}`}>
      <div className='avatar-placeholder mr-2' />
      <div className='d-flex flex-column'>
        <div className='top-bar mb-1' />
        <div className='bottom-bar' />
      </div>
    </div>
  );
};

export default LoadingRow;
