import { useState } from 'react';
import NvSelect from 'shared/components/inputs/nv-select';

type DropdownQuestionProps = {
  title: string;
  answer: string;
  options: {
    label: string,
    value: string,
  }[];
  className?: string,
  placeholder: string;
  onChange: (answer: string) => void;
};
const DropdownQuestion = ({ title, answer, options, className, onChange, placeholder }: DropdownQuestionProps) => {
  const [value, setValue] = useState(answer);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={className}>
      <span className='input-label text-regular semi-bold'>{title}</span>
      <NvSelect
        value={value}
        options={options}
        className='select-dropdown'
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default DropdownQuestion;
