import t from 'react-translate';
import { css } from '@emotion/react';
import NvIcon from 'shared/components/nv-icon';
import {
  gray2,
  gray4,
  gray6,
  success,
  white,
} from 'styles/global_defaults/colors';
import { novoEdBlue } from 'athena/styles/colors';
import {
  halfSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { CompletionStatus, StepInfo } from 'redux/schemas/models/mentoring-program-enrollments';
import { Step } from './types';

const styles = (status: CompletionStatus) => css`
  gap: ${standardSpacing}px;
  .step {
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    color: ${status === CompletionStatus.COMPLETED ? `${white}` : `${gray4}`};
    background-color: ${status === CompletionStatus.COMPLETED ? `${success}` : `${gray6}`};
    position: relative;
    ::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 1px;
      height: 40px;
      background-color: ${gray6};
      transform: translateX(-50%);
    }
  }

  :last-child {
    .step::after {
      display: none;
    }
  }

  .step-badge {
    width: fit-content;
    border-radius: 100px;
    padding: 2.5px ${halfSpacing}px;

    ${status === CompletionStatus.NOT_STARTED
      && css`
        color: ${gray2};
        background-color: ${gray6};
      `}
    ${status === CompletionStatus.IN_PROGRESS
      && css`
        color: ${white};
        background-color: ${novoEdBlue};
      `}
    ${status === CompletionStatus.COMPLETED
      && css`
        color: ${white};
        background-color: ${success};
      `}
  }
`;

const StepItem = ({ status, title, content, showBadge, ctaButton }: StepInfo & Step) => {
  const badge = {
    [CompletionStatus.NOT_STARTED]: {
      label: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.STATUS_BADGE.NOT_STARTED(),
    },
    [CompletionStatus.IN_PROGRESS]: {
      label: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.STATUS_BADGE.IN_PROGRESS(),
    },
    [CompletionStatus.COMPLETED]: {
      label: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.STATUS_BADGE.COMPLETE(),
    },
  };

  const { label } = badge[status] || {};

  return (
    <div css={styles(status)} className='d-flex align-items-center pb-2'>
      <NvIcon
        icon='check'
        size='smallest'
        className='align-content-center text-align-center step'
      />
      <div className='d-flex w-100 align-items-center justify-content-between'>
        <div className='d-flex flex-column'>
          {showBadge && label && (
            <div className='step-badge text-small semi-bold'>{label}</div>
          )}
          <span className='text-medium semi-bold'>{title}</span>
          <span className='text-small'>{content}</span>
        </div>
        {ctaButton}
      </div>
    </div>
  );
};

export default StepItem;
