import { AutoMatchingStatus } from 'redux/schemas/app/mentoring-programs';

export enum EventType {
  AUTO_MATCHING = 'auto_matching',
  BULK_ENROLLMENT = 'bulk_enrollment',
}

type MentorshipProgramEvent = {
  mentorshipProgramId: number;
};

export type AutoMatchingEvent = MentorshipProgramEvent & {
  totalConnectionsCount: number;
  createdConnectionsCount: number;
  unmatchedCount?: number;
  autoMatchingStatus: AutoMatchingStatus;
  autoMatchingLastUpdated?: Date;
};

export type BulkEnrollmentEvent = MentorshipProgramEvent & {
  newEnrolledCount: number;
  existingEnrolledCount: number;
  errors?: [];
};

export type Event = AutoMatchingEvent | BulkEnrollmentEvent;
