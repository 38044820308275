/* eslint-disable react/no-array-index-key */
import t from 'react-translate';
import { css } from '@emotion/react';
import {
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import { novoEdBlue } from 'athena/styles/colors';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import ProgramLevelList from './program-level-list';

const rowStyle = css`
  display: grid;
  gap: ${threeQuartersSpacing}px;
  grid-template-columns: auto 30% 10% 16px;
  padding: 0 ${standardSpacing}px;

  .cell:last-child {
    justify-self: center;
  }
`;

const styles = css`
  padding-top: ${largeSpacing}px;

  .headers {
    ${rowStyle};
  }
  .field-row {
    ${rowStyle};
  }
  .add-new-question {
    gap: ${quarterSpacing}px;
    color: ${novoEdBlue};
  }
`;

const ProgramLevelFieldsSection = forwardRef<{
  checkUnsavedChanges: Function,
}>((props, ref) => {
  const programLevelRef = useRef<any>({});

  useImperativeHandle(ref, () => ({
    checkUnsavedChanges,
  }));

  const checkUnsavedChanges = () => Object.values(programLevelRef.current).some((programField: any) => programField?.unsavedChanges());

  return (
    <div css={styles} className='mb-4 pt-0 mt-6'>
      <div className='subtitle heading-5 mb-2'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.TITLE()}
      </div>
      <div className='description text-regular mb-4'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.DESCRIPTION()}
      </div>
      <ProgramLevelList programLevelRef={programLevelRef} />
    </div>
  );
});

export default ProgramLevelFieldsSection;
