import { RootState } from 'redux/schemas';
import { AutoMatchingRule } from 'redux/schemas/models/mentoring-program-matching-rules';
import { createSelector } from 'reselect';

export const getMentorshipProgramMatchingRules = (state: RootState) => state.models.mentorshipProgramMatchingRules;

export const getMentorshipProgramMatchingRulesList = createSelector(
  (state: RootState) => state.models.mentorshipProgramMatchingRules,
  (state, ids: number[]) => ids,
  (mentorshipProgramMatchingRules: Record<number, AutoMatchingRule>, ids: number[]) => ids.map((id) => mentorshipProgramMatchingRules[id]),
);

export const getResetAutoMatchingRulesListState = (state: RootState) => state.app.mentoringProgram.settings.matchingRules.resetList;

export const getAutoMatchingRulesTotalCount = (state: RootState) => state.app.mentoringProgram.settings.matchingRules.totalCount;
