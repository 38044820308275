import React from 'react';
import { css, ClassNames } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';
import { darkGray } from 'athena/styles/colors';
import usePrevious from 'shared/hooks/use-previous';
import { warning } from 'styles/global_defaults/colors';
import useWindowResize from 'shared/hooks/use-window-resize';
import ClickableContainer from 'components/clickable-container';
import { halfSpacing, quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import NvDropdown, { NvDropdownButtonStyle } from 'shared/components/inputs/nv-dropdown';

const TABS_CONTAINER_PADDING = standardSpacing;

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type TabContainerProps = {
  className: string;
  children: React.ReactNode;
  ref: React.Ref<any>;
};

export type Tab = {
  id: string;
  text: string;
  renderAfterText?: () => React.ReactNode;
  renderContainer?: (props: TabContainerProps) => React.ReactElement;
  dataQa?: string;
};

type Props = {
  tabs: Tab[];
  selectedTab: string;
  onChange?: (selectedTab: string) => void;
  orientation?: Orientation;
};


const ResponsiveTabs = (props: Props) => {
  const { tabs, onChange, selectedTab, orientation = Orientation.HORIZONTAL } = props;

  const containerRef = React.useRef<HTMLDivElement>();
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const styles = css`
    min-height: ${tripleSpacing}px;
    padding: 0 ${TABS_CONTAINER_PADDING}px;

    .vertical {
      flex-direction: column;
      justify-content: center;
      gap: ${halfSpacing}px;
    }
  `;

  const currentTab = tabs.find((tab) => tab.id === selectedTab);

  return (
    <div
      css={styles}
      ref={containerRef}
      className={`d-flex ${
        !isCollapsed && orientation !== Orientation.VERTICAL
          ? 'justify-content-center'
          : ''
      }`}
    >
      <TabsContainer
        tabs={tabs}
        onChange={onChange}
        isCollapsed={isCollapsed}
        selectedTab={selectedTab}
        containerRef={containerRef}
        setIsCollapsed={setIsCollapsed}
        orientation={orientation}
      />
      {isCollapsed && (
        <NvDropdown
          buttonStyle={NvDropdownButtonStyle.CUSTOM}
          items={tabs.map(({ id, text }) => ({
            text,
            type: 'text',
            callback: () => onChange(id),
          }))}
          customTarget={() => (
            <div className='d-flex align-items-center'>
              <TabItem
                text={currentTab.text}
                selected
                dataQa={currentTab.dataQa}
              />
              <NvIcon
                icon='dropdown-arrow'
                size='xss-smallest'
                className='ml-2'
              />
            </div>
          )}
        />
      )}
    </div>
  );
};

const useCalculateTabsWidth = (
  isCollapsed,
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>,
  tabsContainerRef: React.MutableRefObject<HTMLDivElement>,
) => {
  const previousIsCollapsed = usePrevious(isCollapsed);
  const [tabsWidth, setTabsWidth] = React.useState(0);

  const actualCalculateWidth = React.useCallback(() => {
    setTabsWidth(tabsContainerRef.current.clientWidth);
  }, [tabsContainerRef]);

  const calculateTabsWidth = React.useCallback(() => {
    if (isCollapsed) {
      setIsCollapsed(false);
    } else {
      actualCalculateWidth();
    }
  }, [isCollapsed, setIsCollapsed, actualCalculateWidth]);

  React.useLayoutEffect(() => {
    if (previousIsCollapsed && !isCollapsed) {
      actualCalculateWidth();
    }
  }, [isCollapsed, previousIsCollapsed, actualCalculateWidth]);

  return {
    tabsWidth,
    calculateTabsWidth,
  };
};

type TabsContainerRef = {
  calculateTabsWidth: () => void;
};

type TabsContainerProps = Props & {
  isCollapsed: boolean;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const TabsContainer = React.forwardRef<TabsContainerRef, TabsContainerProps>((props, ref) => {
  const {
    tabs,
    onChange,
    selectedTab,
    isCollapsed,
    containerRef,
    setIsCollapsed,
    orientation,
  } = props;

  const tabsContainerRef = React.useRef<HTMLDivElement>();
  const { tabsWidth, calculateTabsWidth } = useCalculateTabsWidth(isCollapsed, setIsCollapsed, tabsContainerRef);

  const calculateTabsCollapsed = React.useCallback(() => {
    if (containerRef.current) {
      const { clientWidth: containerWidth } = containerRef.current;

      setIsCollapsed(tabsWidth > (containerWidth
        // * 2 because of left and right padding.
        - (TABS_CONTAINER_PADDING * 2)
      ));
    }
  }, [tabsWidth, containerRef, setIsCollapsed]);

  React.useLayoutEffect(() => {
    calculateTabsCollapsed();
  }, [calculateTabsCollapsed]);

  React.useLayoutEffect(() => {
    calculateTabsWidth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useWindowResize(calculateTabsCollapsed, undefined, false, [calculateTabsCollapsed]);

  React.useImperativeHandle(ref, () => ({
    calculateTabsWidth,
  }));

  return !isCollapsed && (
    <div className={`tabs-container d-flex ${orientation}`} ref={tabsContainerRef}>
      {tabs.map((tab) => {
        const handleTabClick = () => onChange(tab.id);

        return (
          <TabItem
            key={tab.id}
            text={tab.text}
            dataQa={tab.dataQa}
            onClick={onChange ? handleTabClick : null}
            orientation={orientation}
            selected={selectedTab === tab.id}
            renderContainer={tab.renderContainer}
            renderAfterText={tab.renderAfterText}
          />
        );
      })}
    </div>
  );
});

type TabProps = Omit<Tab, 'id'> & {
  selected: boolean;
  // TabItem is also used as target of collapsed tabs dropdown so in that case
  // onClick is not used.
  onClick?: () => void;
  dataQa?: string;
  orientation?: Orientation;
};

const TabItem = React.forwardRef<HTMLDivElement, TabProps>((props, ref) => {
  const {
    text,
    selected,
    renderAfterText,
    renderContainer = (containerProps: TabContainerProps) => <ClickableContainer {...containerProps} />,
    dataQa,
    orientation,
    onClick,
  } = props;

  return (
    <ClassNames>
      {({ css: classNameCss }) => {
        const stylesClassName = classNameCss`
          color: ${darkGray};

          &.vertical {
            height: 42px !important;
            border-left: ${quarterSpacing}px solid ${selected ? warning : 'transparent'};
          }
          &.horizontal {
            border-bottom: ${quarterSpacing}px solid ${selected ? warning : 'transparent'};
            align-items: center;
          }
        `;

        const containerProps = {
          className: `d-flex h-100 pl-4 pr-4 ${orientation} ${stylesClassName}`,
          ref,
          children: [
            <div className={`text-large-body ${selected ? 'bold text-black' : ''}`}>
              {text}
            </div>,
            renderAfterText?.(),
          ],
          'data-qa': dataQa,
          onClick,
        };

        return renderContainer(containerProps);
      }}
    </ClassNames>
  );
});

export default ResponsiveTabs;
