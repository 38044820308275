import React from 'react';
import { css } from '@emotion/react';
import type { RowData, HeaderContext } from '@tanstack/table-core';

import NvIcon from 'shared/components/nv-icon';
import type { HeaderProps } from 'shared/components/nv-table';
import ClickableContainer from 'components/clickable-container';
import { semiBoldFontWeight } from 'styles/global_defaults/fonts';
import { almostBlack, dividerMedium, paleGray } from 'athena/styles/colors';
import { doubleSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

const DefaultHeader = <T extends RowData>(props: HeaderProps<T>) => {
  const { getCellStyle, headerGroup, className } = props;

  const styles = css`
    border-bottom: 1px solid ${dividerMedium};
  `;

  return (
    <div key={headerGroup.id} css={styles} className={className}>
      {headerGroup.headers.map(header => {
        const { header: headerTitle } = header.column.columnDef;

        const columnStyle = getCellStyle(header.index);

        const renderHeader = () => (
          <DefaultHeaderCell<T>
            {...header.getContext()}
            key={header.id}
            css={columnStyle}
            title={headerTitle as string}
          />
        );

        return (
          header.isPlaceholder
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            ? <div key={header.id} css={columnStyle} />
            : renderHeader()
        );
      })}
    </div>
  );
};


type HeaderCellProps<T> = HeaderContext<T, any> & {
  className: string;
};

type DefaultHeaderCellProps<T> = Omit<HeaderCellProps<T>, 'className'> & {
  title: string;
  className?: string;
};

const DefaultHeaderCell = <T extends RowData>(props: DefaultHeaderCellProps<T>) => {
  const { className, header } = props;

  const canSort = header.column.getCanSort();
  const sorting = header.column.getIsSorted();
  const handler = header.column.getToggleSortingHandler();

  const styles = css`
    color: ${almostBlack};
    font-weight: ${semiBoldFontWeight};
    height: ${doubleSpacing + quarterSpacing}px;

    ${!sorting && css`
      .icon {
        color: ${paleGray};
      }
    `};
  `;

  return (
    <div className={`d-flex align-items-center ${className}`} css={styles}>
      <ClickableContainer
        layoutOnly={!canSort}
        className='align-items-center'
        onClick={handler}
      >
        <span className='text-small'>{props.title}</span>
        {canSort && (
          // eslint-disable-next-line no-nested-ternary
          <NvIcon
            className='ml-1'
            icon={
              sorting
                ? `sorting-${sorting === 'asc' ? 'up' : 'down'}-active`
                : 'sorting-inactive'
            }
            size='xss-smallest'
          />
        )}
      </ClickableContainer>
    </div>
  );
};

export default DefaultHeader;
