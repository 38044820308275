export const alto = '#D9D9D9';
export const oasis = '#1A2172';
export const dunes70 = '#FFCDA3';
export const darkGray = '#53535C';
export const lightBlack = '#41424D';
export const novoEdBlue = '#0E72ED';
export const almostBlack = '#27282E';
export const almostWhite = '#FAFAFA';
export const shadedWhite = '#EDEEF2';
export const sidewalkGray = '#D4D7D9';
export const dividerMedium = '#D4DAE1';
export const paleGray = '#BFC0C7';
export const oasis30 = '#BABCD5';
export const dunes50 = '#FFDBBD';
export const heatwave = '#E95F48';
export const novoEdBlack = '#1D2126';
export const mentorBackgroundColor = '#FDCE00';
export const mentorTextColor = '#1D2126';
export const menteeBackgroundColor = '#59A315';
export const menteeTextColor = '#FFFFFF';
export const blue05 = '#F3F8FE';
