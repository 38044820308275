import { useEffect, useState } from 'react';
import _ from 'underscore';

/**
 * Fires a callback when the browser window is resized.
 * @param cb The callback fired on every 'resize' event
 * @param debounceInterval How frequently the cb() should be called, in milliseconds
 * @param props Values passed as a props array to useEffect(). Changes in these will cause the
 * event listener to be unregistered and re-registered.
 */
const useWindowResize = (cb: () => void, debounceInterval?: number, isDisabled?: boolean, props?: any[]) => {
  useEffect(() => {
    if (isDisabled) {
      return () => {};
    }

    const debouncedCb = debounceInterval ? _.debounce(() => {
      cb();
    }, 0) : cb;

    window.removeEventListener('resize', debouncedCb);
    window.addEventListener('resize', debouncedCb);

    return () => window.removeEventListener('resize', debouncedCb);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, ...(props ?? [])]);
};

export default useWindowResize;
