import { css } from '@emotion/react';
import t from 'react-translate';
import { paleGray } from 'athena/styles/colors';
import { MentorshipProgramConnectionType } from 'redux/schemas/models/mentorship-program-connections';
import NvTooltip from 'shared/components/nv-tooltip';
import NvIcon from 'shared/components/nv-icon';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'athena/components/constants';
import { CellProps } from './types';
import { commonStyles } from './styles';

const styles = css`
  ${commonStyles};
  color: ${paleGray};
`;

const ConnectionTypeCell = (props: CellProps) => {
  const {
    cell: { row },
    className,
  } = props;

  const {
    original: {
      formationType = MentorshipProgramConnectionType.MANUAL,
      createdAt,
    },
  } = row;
  const createdAtDate = moment(createdAt);
  const formattedDate = createdAtDate.format(DATE_FORMAT);
  const formattedTime = createdAtDate.format(TIME_FORMAT);

  const dateParams: [string, string] = [formattedDate, formattedTime];

  const connectionTypes = {
    [MentorshipProgramConnectionType.MANUAL]: {
      icon: 'manual-connection',
      tooltip: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.ROWS.CONNECTION_TYPE.MANUAL_TOOLTIP(...dateParams),
    },
    [MentorshipProgramConnectionType.AUTO_GENERATED]: {
      icon: 'auto-connection',
      tooltip: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.ROWS.CONNECTION_TYPE.AUTO_TOOLTIP(...dateParams),
    },
  };

  return (
    <div className={`${className} d-flex align-items-center justify-content-center`} css={styles}>
      <NvTooltip placement='top' text={connectionTypes[formationType].tooltip}>
        <NvIcon icon={connectionTypes[formationType].icon} size='small' />
      </NvTooltip>
    </div>
  );
};

export default ConnectionTypeCell;
