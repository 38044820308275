import { schema } from 'normalizr';
import { AutoMatchingRule } from '../models/mentoring-program-matching-rules';

export const MentorshipProgramMatchingRuleSchema = new schema.Entity<
AutoMatchingRule
>('mentorshipProgramMatchingRules');

export const MentorshipProgramMatchingRulesSchema = new schema.Array(
  MentorshipProgramMatchingRuleSchema,
);
