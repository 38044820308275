import { css } from '@emotion/react';
import Avatar from 'athena/components/mentoring-program/avatar';
import { darkGray, lightBlack } from 'athena/styles/colors';
import { boldFontWeight } from 'styles/global_defaults/fonts';
import { CellProps } from './types';
import { commonStyles } from './styles';

const styles = css`
  ${commonStyles};
  display: grid;
  grid-template-columns: 1fr auto;
  .user {
    width: 320px;
  }
  .name {
    color: ${lightBlack};
    font-weight: ${boldFontWeight};
  }
  .email {
    color: ${darkGray};
  }
`;

const ParticipantCell = ({ cell: { row }, className }: CellProps) => {
  const {
    original: { user, role },
  } = row;

  return (
    <div className={className} css={styles}>
      <Avatar
        user={user}
        role={role.name}
        className='avatar position-relative'
      />
      <div className='user d-flex flex-column pl-1 ellipsis'>
        <span className='name text-medium ellipsis'>{user.fullName}</span>
        <span className='email text-small ellipsis'>{user.email}</span>
      </div>
    </div>
  );
};

export default ParticipantCell;
