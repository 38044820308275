import t from 'react-translate';
import { MutableRefObject } from 'react';
import { config } from '@config/pendo.config.json';
import NvSelect from 'shared/components/inputs/nv-select';
import { getProgramLevelInputTypeLabelsMap } from 'athena/components/mentoring-program/constants';
import { ProgramLevelQuestionType } from 'redux/schemas/models/mentoring-program-profile-questions';

type QuestionTypeProps = {
  questionTypeRef: MutableRefObject<{
    toggle: HTMLDivElement;
    container: HTMLDivElement;
  }>;
  type: ProgramLevelQuestionType;
  onChange: (type: ProgramLevelQuestionType) => void;
  error: string;
  setError: (error: string) => void;
};

const QuestionType = ({ questionTypeRef, type, onChange, error, setError }: QuestionTypeProps) => {
  const inputTypeSelectOptions = Object.entries(
    getProgramLevelInputTypeLabelsMap(t),
  ).map(([key, value]) => ({ label: value, value: key }));

  const handleOnBlur = () => setTimeout(() => {
    if (!type) {
      setError(
        t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.VALIDATION.REQUIRED_QUESTION_TYPE(),
      );
    } else {
      setError(null);
    }
  }, 300);

  return (
    <NvSelect
      ref={questionTypeRef}
      value={type}
      className={`select-type align-items-center ${
        error && !type ? 'required-type' : ''
      }`}
      options={inputTypeSelectOptions}
      onChange={onChange}
      onBlur={handleOnBlur}
      placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.PLACEHOLDERS.QUESTION_TYPE()}
      data-qa={
        config.pendo.athena.mentorshipProgram.settings.programProfileSetup
          .updateProgramLevelProfileQuestion
      }
    />
  );
};

export default QuestionType;
