import React, { useContext, useState, SyntheticEvent } from 'react';
import { RootState, CombinedInstitution } from 'redux/schemas';
import { connect, useSelector } from 'react-redux';
import _ from 'underscore';
import { Course, CourseUsedFor } from 'redux/schemas/models/course';
import { CourseCloning } from 'redux/schemas/models/institution';
import { css } from '@emotion/react';
import { primary, gray4 } from 'styles/global_defaults/colors';
import { AngularContext, AngularServicesContext } from 'react-app';
import { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import t from 'react-translate';
import moment from 'moment';
import {
  EnrollmentPeriod,
  CourseDetails,
  ExportTypes,
  ExportStatus,
} from 'redux/schemas/models/license';
import {
  getCourseEnrollmentDetails,
  getExportData,
} from 'redux/actions/license';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import PusherService from 'shared/services/pusher-service';
import { textSmallFontSize } from 'styles/global_defaults/fonts';
import { doubleSpacing, halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { deleteCourse, removeCohortStatus, setPerCourseLicense, updateCoursePrimaryStatus } from 'redux/actions/courses';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { getCourseAliases } from 'redux/selectors/course';
import NvResponsiveTableOptionsCell from 'shared/components/nv-responsive-table-options-cell';
import { NvModal, ModalType } from 'shared/components/nv-modal';
import NvTooltip from 'shared/components/nv-tooltip';
import { useAppDispatch } from 'redux/store';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { wrapThunkAction } from 'redux/utils';
import { useResponsiveTableContext } from 'shared/components/nv-responsive-table';
import { setCourseHighlight } from 'redux/actions/institutions';
import NvSwitch from 'shared/components/inputs/nv-switch';
import { CourseCellProps, CourseRowExtraProps } from './course-row';
import CourseCloneModal from './course-clone-modal/course-clone-modal';
import { config } from '../../../config/pendo.config.json';
import { InstitutionDashboardContext } from './institution-dashboard-home';
import { CloningType } from './course-clone-modal/hooks/use-clone-course-form';

type CourseRowOptionsProps = {
  cellProps: CourseCellProps,
};

const itemStyles = css`
  &.course-enrollment-download {
    span, .refresh {
      font-size: ${textSmallFontSize}px;
    }

    span {
      margin-top: ${quarterSpacing}px;
      color: ${gray4};
    }

    .refresh {
      color: ${primary};
      margin-left: ${quarterSpacing}px;
       &.bs4-dropdown-item {
        display: unset;
        padding: 0px 0px;
       }
    }
    .data-download {
      &.bs4-dropdown-item {
        display: unset;
        padding: 0px 0px;
       }
    }
  }

  .disabled {
    color: ${gray4};
    pointer-events: none;
  }
`;
const switchStyles = css`
&.precourse{
  width:293px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-left: ${standardSpacing}px;
  padding-right: ${halfSpacing}px;
  padding-bottom: ${quarterSpacing}px;

  .label-text{
    min-width: 100px;

  }
  .precourse-switch{
    margin-left: ${quarterSpacing}px;
    margin-top: ${quarterSpacing}px;
  }
}

`;

const CourseRowOptions = ({
  cellProps,
}: CourseRowOptionsProps) => {
  const { course } = cellProps;
  const { reload } = useResponsiveTableContext();
  const [pusherChannel, setPusherChannel] = useState(null);
  const [typeCloneModal, setTypeCloneModal] = useState<CloningType>(null);
  const { $state, InteroperabilityRoutes, CurrentPermissionsManager } = useContext(AngularServicesContext);
  const { injectServices } = React.useContext(AngularContext);
  const [ConfirmationOverlays] = injectServices(['ConfirmationOverlays']);
  const { getCourseCounts, updateResultsCount } = React.useContext(InstitutionDashboardContext);
  const aliases = useSelector((state) => getCourseAliases(state));

  const dispatch = useAppDispatch();

  const currentInstitutionId = useSelector<RootState, number>((state: RootState) => state.app.currentInstitutionId);
  const currentInstitution = useSelector<RootState, CombinedInstitution>(getCurrentInstitution);
  const courseDetails = useSelector<RootState, CourseDetails>((state: RootState) => state.models.license.courseEnrollmentDetails[course.id]);
  const gotoCourseBasics = () => $state.go('institution-edit-course', {
    institutionId: currentInstitutionId,
    id: course.id,
    prevStateData: { name: $state.current.name, params: $state.params },
  });

  /** There's a bug on prod https://novoed.atlassian.net/browse/NOV-63548 where in some cases the course.insitution property is the institution ID instead of an insitution object
   * I've been unable to find the source, but ideally we wouldn't be pulling that data off the course object anyway. This guarantees that object has the right data */
  const getCourseFlyerUrl = () => InteroperabilityRoutes.editCourseFlyerUrl({ ...course, institution: cellProps.extraProps.institution });

  const getUserMgmtUrl = () => $state.href('user-management-course-admins', {
    catalogId: course.catalogId,
  });
  const getAnalyticsUrl = () => $state.href('course-analytics', {
    catalogId: course.catalogId,
  });
  const getCohortsUrl = () => $state.href('cohorts', {
    catalogId: course.catalogId,
  });

  const cloneCourse = (type: CloningType = CloningType.NORMAL_COURSE) => {
    setTypeCloneModal(type);
  };

  const updatePerCourseLicense = (catalogId, licensedByInstance) => {
    dispatch(setPerCourseLicense({ catalogId, licensedByInstance }));
  };

  const performDeleteCourse = () => {
    const shouldShowPrimaryMessage = course.isPrimary && course.numCohorts;

    dispatch(openConfirmationDialog({
      title: shouldShowPrimaryMessage ? t.INSTITUTIONS.DASHBOARD.PRIMARY_DELETION.TITLE() : t.INSTITUTIONS.DASHBOARD.DELETION.ARE_YOU_SURE(),
      bodyText: shouldShowPrimaryMessage ? t.INSTITUTIONS.DASHBOARD.PRIMARY_DELETION.DESCRIPTION(course.catalogId, course.numCohorts, course.name) : t.INSTITUTIONS.DASHBOARD.DELETION.WARNING('course'),
      confirmText: t.INSTITUTIONS.DASHBOARD.DELETION.YES_SURE(),
      onConfirm: () => {
        dispatch(deleteCourse({
          courseId: course.id,
          courseCatalogId: course.catalogId,
        }))
          .then((res: any) => {
            if (res.payload?.response?.data?.error?.code === 'error.course_cannot_be_deleted') {
              dispatch(addAlertMessage({
                type: AlertMessageType.ERROR,
                header: t.INSTITUTIONS.DASHBOARD.DELETION.CANNOT_DELETE_HEADER(),
                message: t.INSTITUTIONS.DASHBOARD.DELETION.CANNOT_DELETE_MESSAGE(),
              }));
            }
          });
      },
      confirmDataQa: config.pendo.orgDashboard.yesIAmSure,
      cancelDataQa: config.pendo.orgDashboard.cancel,
    }));
  };

  const getDownloadAICCArchiveUrl = () => InteroperabilityRoutes.downloadAiccUrl({ ...course, institution: cellProps.extraProps.institution });

  const isExportOptionStatusActive = () => courseDetails?.reportStatus === ExportStatus.IN_PROGRESS;


  // eslint-disable-next-line @typescript-eslint/no-shadow
  const loadDetails = (currentInstitutionId) => {
    if (currentInstitutionId) {
      dispatch(getCourseEnrollmentDetails({
        institutionId: currentInstitutionId,
        courseId: course.id,
      }));
    }
  };

  const onToggle = (isOpen: boolean = false) => {
    if (isOpen) {
      const channel = PusherService.setupChannel(`public-institution-${currentInstitutionId}`);
      channel.bind('institution_report_event_name', () => {
        loadDetails(currentInstitutionId);
      });

      setPusherChannel(channel);

      loadDetails(currentInstitutionId);
    } else {
      pusherChannel?.unbind('institution_report_event_name');
    }
  };

  const isAICCDisabled = course.isPrimary || course.usedFor === CourseUsedFor.DEMO;

  const primaryAICCDisabledTooltip = isAICCDisabled ? {
    text: t.INSTITUTIONS.DASHBOARD.DOWNLOAD_AICC_DISABLED_TOOLTIP(course.isPrimary.toString()),
  } : undefined;

  // TODO: Add conditions
  const showCohortsLink = course.isPrimary || course.isCohort;

  const preOptions: NvDropdownOption[] = [
    { type: 'link', text: t.COURSES.ANALYTICS(), link: getAnalyticsUrl() },
  ];


  let downloadEnrollmentItem;

  const isDemo = course.usedFor === CourseUsedFor.DEMO;
  const isProduction = course.usedFor === CourseUsedFor.PRODUCTION;

  const disabledDownloadInfo = t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.DISABLED_DOWNLOAD_INFO();

  if ((courseDetails?.reportStatus === ExportStatus.COMPLETED || courseDetails?.reportStatus === ExportStatus.FAILED) && courseDetails?.downloadFile.url) {
    downloadEnrollmentItem = {
      type: 'custom',
      customItem: (
        <div css={itemStyles} tabIndex={0} role='button' className='bs4-dropdown-item course-enrollment-download'>
          <NvTooltip text={disabledDownloadInfo} enabled={isDemo}>
            <div tabIndex={0} role='button'>
              <a
                tabIndex={0}
                role='button'
                className={isDemo ? 'disabled' : 'data-download bs4-dropdown-item'}
                href='#'
                onClick={() => { window.location.href = courseDetails?.downloadFile.url; }}
              >
                {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA.DOWNLOAD()}
              </a><br />
              <span>{t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.EXPORTED(moment(courseDetails?.downloadFile.completedAt).format('MMM DD, YYYY h:mm A'))}</span>
              <a
                tabIndex={0}
                role='button'
                href='#'
                className={isDemo ? 'disabled ml-1 text-small' : 'refresh bs4-dropdown-item'}
                onClick={() => dispatch(getExportData({
                  institutionId: currentInstitutionId,
                  exportType: ExportTypes.COURSE_ENROLLMENT,
                  period: EnrollmentPeriod.SINCE_ORG_CREATION,
                  startTime: course.createdAt,
                  endTime: moment().toISOString(),
                  courseId: course.id,
                  courseName: course.name,
                }))}
              >
                {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.REFRESH()}
              </a>
            </div>
          </NvTooltip>
        </div>
      ),
    };
  } else {
    downloadEnrollmentItem = {
      type: 'custom',
      customItem: (
        <NvTooltip text={disabledDownloadInfo} enabled={isDemo}>
          <div css={itemStyles} role='button' tabIndex={0}>
            <a
              className={`bs4-dropdown-item ${(isDemo || isExportOptionStatusActive()) ? 'disabled' : ''}`}
              href='#'
              onClick={() => dispatch(getExportData({
                institutionId: currentInstitutionId,
                exportType: ExportTypes.COURSE_ENROLLMENT,
                period: EnrollmentPeriod.SINCE_ORG_CREATION,
                startTime: course.createdAt,
                endTime: moment().toISOString(),
                courseId: course.id,
                courseName: course.name,
              }))}
            >
              {t.INSTITUTIONS.LICENSES.DASHBOARD.EXPORT.USER_DATA[isExportOptionStatusActive() ? 'ACTIVE' : 'READY']()}
            </a>
          </div>
        </NvTooltip>
      ),
    };
  }

  if (downloadEnrollmentItem && course.released) {
    preOptions.push(downloadEnrollmentItem);
  }

  if (showCohortsLink) {
    preOptions.push({ type: 'link', text: t.COURSES.COHORTS_ANALYTICS(), link: getCohortsUrl() });
  }

  const optionItems: NvDropdownOption[] = [
    ...preOptions,
    { type: 'divider' },
    { type: 'header', title: t.COURSES.EDIT(course.isProgram.toString()) },
    { type: 'text', text: t.COURSES.BASICS(), callback: gotoCourseBasics },
    { type: 'link', text: t.COURSES.FLYER(course.isProgram.toString()), link: getCourseFlyerUrl(), targetBlank: true },
    { type: 'link', text: t.USER_MANAGEMENT.HEADER(), link: getUserMgmtUrl(), targetBlank: true },
  ];

  if (!course.isProgram && (
    CurrentPermissionsManager.hasOrgAdminPermissions()
    || CurrentPermissionsManager.hasCourseManagerPermissions()
  )) {
    if (course.isCohort) {
      optionItems.push({ type: 'divider' });
      optionItems.push({
        type: 'text',
        text: t.COHORT_MANAGEMENT.REMOVE_COHORT_STATUS(),
        dataQa: config.pendo.orgDashboard.removeCohortStatus,
        callback: () => {
          dispatch(openConfirmationDialog({
            title: t.COHORT_MANAGEMENT.REMOVE_COHORT_STATUS_ALERT_TITLE(),
            bodyText: t.COHORT_MANAGEMENT.REMOVE_COHORT_STATUS_ALERT_DESCRIPTION(course.primary.catalogId, course.primary.name),
            confirmText: t.FORM.YES_SURE(),
            confirmDataQa: config.pendo.orgDashboard.removeCohortStatusConfirm,
            onConfirm: () => {
              wrapThunkAction(dispatch(removeCohortStatus(course.catalogId))).then(() => {
                dispatch(addAlertMessage({
                  header: t.FORM.SUCCESS_BANG(),
                  type: AlertMessageType.SUCCESS,
                  message: t.COHORT_MANAGEMENT.REMOVE_COHORT_STATUS_SUCCESS(course.name),
                }));

                dispatch(setCourseHighlight(course.catalogId));
                reload();
                updateResultsCount();
              });
            },
          }));
        },
      },
      {
        type: 'text',
        text: t.COURSES.CLONE.CLONE_COURSE({ ...aliases.courseAliases }),
        callback: () => cloneCourse(),
        dataQa: config.pendo.orgDashboard.clone,
      });
    } else if (course.isPrimary) {
      const onConfirm = () => {
        wrapThunkAction(dispatch(updateCoursePrimaryStatus({
          catalogId: course.catalogId,
          usedFor: CourseUsedFor.PRODUCTION,
        }))).then(() => {
          dispatch(addAlertMessage({
            header: t.FORM.SUCCESS_BANG(),
            type: AlertMessageType.SUCCESS,
            message: t.COHORT_MANAGEMENT.REMOVE_AS_PRIMARY_SUCCESS(course.name),
          }));

          dispatch(setCourseHighlight(course.catalogId));
          reload();
          getCourseCounts({ institutionId: currentInstitutionId });
        });
      };
      optionItems.push({ type: 'divider' });
      optionItems.push({ type: 'header', title: t.COURSES.PRIMARY_COURSE() });
      optionItems.push({
        type: 'text',
        text: t.COHORT_MANAGEMENT.REMOVE_AS_PRIMARY(),
        dataQa: config.pendo.orgDashboard.removeAsPrimary,
        callback: () => {
          if (course.numCohorts) {
            dispatch(openConfirmationDialog({
              title: t.COHORT_MANAGEMENT.REMOVE_AS_PRIMARY_ALERT_TITLE(),
              bodyText: t.COHORT_MANAGEMENT.REMOVE_AS_PRIMARY_ALERT_DESCRIPTION(course.catalogId, course.numCohorts, course.name),
              confirmText: t.FORM.YES_SURE(),
              confirmDataQa: config.pendo.orgDashboard.removeAsPrimaryConfirm,
              onConfirm,
            }));
          } else {
            onConfirm();
          }
        },
      },
      {
        type: 'text',
        text: t.COURSES.CLONE.CLONE_NEW_PRIMARY_WITH_CLONE(),
        callback: () => cloneCourse(CloningType.PRIMARY_COURSE),
        dataQa: config.pendo.orgDashboard.clone,
      },
      {
        type: 'text',
        text: t.COURSES.CLONE.CLONE_NEW_COHORT_WITH_CLONE(),
        callback: () => cloneCourse(CloningType.COHORT_COURSE),
        dataQa: config.pendo.orgDashboard.clone,
      });
    } else {
      optionItems.push({ type: 'divider' });
      optionItems.push({
        type: 'text',
        text: t.COHORT_MANAGEMENT.MAKE_PRIMARY(),
        dataQa: config.pendo.orgDashboard.makePrimary,
        callback: () => {
          const modalInstance = ConfirmationOverlays.openConfirmationModal(
            'cohort_management/templates/make-primary-confirmation.html',
            function () {
              // eslint-disable-next-line react/no-this-in-sfc
              this.config = config;
            },
          );

          modalInstance.result.then(() => {
            wrapThunkAction(dispatch(updateCoursePrimaryStatus({
              catalogId: course.catalogId,
              usedFor: CourseUsedFor.PRIMARY,
            }))).then(() => {
              dispatch(addAlertMessage({
                type: AlertMessageType.SUCCESS,
                header: t.FORM.SUCCESS_BANG(),
                message: t.COHORT_MANAGEMENT.MAKE_PRIMARY_SUCCESS(course.name),
              }));

              dispatch(setCourseHighlight(course.catalogId));
              reload();
              getCourseCounts({ institutionId: currentInstitutionId });
            });
          });
        },
      },
      {
        type: 'text',
        text: t.COURSES.CLONE.CLONE_COURSE({ ...aliases.courseAliases }),
        callback: () => cloneCourse(),
        dataQa: config.pendo.orgDashboard.clone,
      });
    }
  }

  if (currentInstitution.hasCsod) {
    optionItems.push({ type: 'divider' });
    optionItems.push({
      type: 'link',
      text: t.INSTITUTIONS.DASHBOARD.DOWNLOAD_AICC(),
      callback: () => onToggle(false),
      link: getDownloadAICCArchiveUrl(),
      disabled: isAICCDisabled,
      tooltip: primaryAICCDisabledTooltip,
    });
  }

  if (CurrentPermissionsManager.hasNovoEdPermissions()) {
    optionItems.push({ type: 'divider' });
    optionItems.push({ type: 'header', title: t.INSTITUTIONS.LICENSES.NOVOED_ADMINS_ONLY() });
    if (isProduction || course.isPrimary) {
      optionItems.push({
        type: 'custom',
        customItem: (
          <div css={switchStyles} className='precourse'>
            <span className='label-text'>{t.INSTITUTIONS.LICENSES.PERCOURSE()}</span>
            <NvTooltip text={!course.licensedByInstance ? t.INSTITUTIONS.LICENSES.USAGE_OFF() : t.INSTITUTIONS.LICENSES.USAGE_ON()}>
              <NvSwitch
                className='precourse-switch'
                checked={course.licensedByInstance}
                onChange={() => { updatePerCourseLicense(course.catalogId, !course.licensedByInstance); }}
                enabledLabel='Yes'
                disabledLabel='No'
              />
            </NvTooltip>
          </div>
        )
        ,
      });
    }
    optionItems.push({
      type: 'text',
      text: t.NOVOED.DELETE(),
      class: 'text-danger',
      dataQa: config.pendo.orgDashboard.deleteOption,
      callback: performDeleteCourse,
    });
  }

  const getModalHeader = () => {
    if (typeCloneModal === CloningType.COHORT_COURSE) {
      return t.COURSES.CLONE.CLONE_NEW_COHORT_WITH_CLONE();
    }
    if (typeCloneModal === CloningType.PRIMARY_COURSE) {
      return t.COURSES.CLONE.CLONE_NEW_PRIMARY_WITH_CLONE();
    }
    return t.COURSES.CLONE.CLONE_NEW_COURSE();
  };

  return (
    <React.Fragment key={`${cellProps}-fragment`}>
      <NvModal
        type={ModalType.FIXED}
        fullHeight={false}
        width={960}
        height={640}
        header={getModalHeader()}
        body={<CourseCloneModal course={course} cloningType={typeCloneModal} closeModal={() => setTypeCloneModal(null)} />}
        show={!!typeCloneModal}
        onClose={() => setTypeCloneModal(null)}
      />
      <NvResponsiveTableOptionsCell<CourseCellProps, Course, CourseCloning, CourseRowExtraProps>
        optionItems={optionItems}
        optionsCellRef={cellProps.optionsCellRef}
        onToggle={onToggle}
        {...cellProps}
        dataQa={config.pendo.orgDashboard.moreOptions}
        dataQaId={`${config.pendo.orgDashboard.moreOptions}-${course.id}`}
      />
    </React.Fragment>
  );
};

export default CourseRowOptions;
