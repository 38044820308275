import React from 'react';
import { css } from '@emotion/core';
import { BrowserRouter as Router } from 'react-router-dom';

import { AngularContext } from 'react-app';
import NvRouter from 'shared/components/nv-router';
import Dashboard from 'athena/components/dashboard';

const DashboardRoute = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [
    $state,
    CurrentPermissionsManager,
  ] = injectServices([
    '$state',
    'CurrentPermissionsManager',
  ]);

  React.useEffect(() => {
    const hasPermission = CurrentPermissionsManager.hasOrgAdminPermissions();

    if (!hasPermission) {
      $state.go('institution-dashboard', null, { replace: true });
    }
  }, [$state, CurrentPermissionsManager]);

  return <Dashboard />;
};

const contentRoutes = [
  {
    path: '/',
    exact: true,
    component: DashboardRoute,
  },
];

const MentoringPrograms = () => {
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const routerBasename = `/#!/institutions/${$state.params.institutionId}/mentoring-programs`;

  const styles = css`
    height: 100vh;
  `;

  return (
    <Router basename={routerBasename}>
      <div css={styles} className='d-flex'>
        <NvRouter
          createRouter={false}
          routes={contentRoutes}
        />
      </div>
    </Router>
  );
};

export default MentoringPrograms;
