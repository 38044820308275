import { useContext } from 'react';
import { AngularContext } from 'react-app';
import { Button } from 'react-bootstrap';
import prodPathReplace from 'shared/prod-path-rewrite';
import t from 'react-translate';
import moment from 'moment';

const UNRELEASE_IMAGE = 'images/unrelease-feature.gif';
const IMAGE_HEIGHT = 140;
const IMAGE_WIDTH = 150;

type Props = {
  date: string;
};

const UnreleasedFeature = ({ date }: Props) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);

  const goToDashboard = () => $state.go('dashboard');

  return (
    <div className='unreleased-section d-flex flex-column align-items-center justify-content-center'>
      <img
        src={prodPathReplace(UNRELEASE_IMAGE)}
        alt={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.UNRELEASED_FEATURE.TITLE()}
        height={IMAGE_HEIGHT}
        width={IMAGE_WIDTH}
      />
      <div className='heading-4 mt-6 mb-1'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.UNRELEASED_FEATURE.TITLE()}
      </div>
      <div className='text-body-large description mb-6'>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.UNRELEASED_FEATURE.DESCRIPTION(moment(date).format('MMMM DD, YYYY hh:mm A'))}
      </div>
      <Button variant='primary' onClick={goToDashboard}>
        {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.PROGRAM_CLOSED.BACK_TO_DASHBOARD()}
      </Button>
    </div>
  );
};

export default UnreleasedFeature;
