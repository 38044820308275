import { Cell } from '@tanstack/react-table';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';

export enum ColumnID {
  SELECTOR = 'selector',
  PARTICIPANT = 'participant'
}

export type CellProps = {
  className: string,
  cell: Cell<MentoringProgramEnrollment, any>;
};
