import React from 'react';
import { css } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';
import { novoEdBlue } from 'athena/styles/colors';
import useIsAthenaApp from 'athena/hooks/use-is-athena-app';
import ClickableContainer from 'components/clickable-container';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { primary, warning, white } from 'styles/global_defaults/colors';

type Props = {
  onUp?: () => void;
  onDown?: () => void;
  className?: string;
};

const NumberInputArrows = (props: Props) => {
  const { onUp, onDown, className } = props;

  const isAthenaApp = useIsAthenaApp();

  const styles = css`
    overflow: hidden;
    border-radius: 4px;

    .arrow-container {
      width: 22px;
      height: 12px;
      outline-color: ${warning}!important;
      background-color: ${isAthenaApp ? novoEdBlue : primary};

      &:first-child {
        margin-bottom: 2px;
      }

      .icon {
        color: ${white};
        font-size: ${halfSpacing}px;
      }
    }
  `;

  return (
    <div className={`d-flex flex-column ${className ?? ''}`} css={styles}>
      <ClickableContainer
        onClick={onUp}
        className='d-flex justify-content-center align-items-center arrow-container'
      >
        <NvIcon icon='arrow-up' size='' />
      </ClickableContainer>
      <ClickableContainer
        onClick={onDown}
        className='d-flex justify-content-center align-items-center arrow-container'
      >
        <NvIcon icon='arrow-down' size='' />
      </ClickableContainer>
    </div>
  );
};

export default NumberInputArrows;
