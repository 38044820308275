import React from 'react';
import { ClassNames, SerializedStyles, css } from '@emotion/react';
import type {
  Row,
  Table,
  RowData,
  ColumnDef,
  HeaderGroup,
  TableOptions,
  GroupColumnDef,
  DisplayColumnDef,
  AccessorColumnDef,
} from '@tanstack/table-core';
import {
  useReactTable,
  getCoreRowModel as libraryGetCoreRowModel,
} from '@tanstack/react-table';

import DefaultHeader from 'shared/components/nv-table/default-header';
import {
  halfSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';

export type HeaderProps<T> = {
  className?: string;
  headerGroup: HeaderGroup<T>;
  getCellStyle: (index) => SerializedStyles;
};

export type RowProps<T> = {
  row: Row<T>;
  className?: string;
  getCellStyle: (index) => SerializedStyles;
};

export type Column<T> = (
  | (Omit<DisplayColumnDef<T, any>, 'cell' | 'header'> & {
    header: string;
  })
  | GroupColumnDef<T, any>
  | AccessorColumnDef<T, any>
) & {
  gridTemplateColumn?: string;
};


// Props that replace original tanstack table props
type OverridenProps<T> = {
  columns: Column<T>[];
};

// Props from tanstack table that are optional in our NvTable
type DefaultedPropNames =
 | 'getCoreRowModel';

type DefaultedProps<T> = Partial<Pick<TableOptions<T>, DefaultedPropNames>>;

// Props that we inherit from original tanstack table
type InheritedOptions<T> = Omit<TableOptions<T>, keyof OverridenProps<T> | DefaultedPropNames>;

type Props<T> =
  OverridenProps<T> &
  InheritedOptions<T> &
  DefaultedProps<T> &
  // These are custom own props that are not from tanstack table
  {
    RowComponent: React.ComponentType<RowProps<T>>;
    spacing?: number;
    className?: string;
    sidesSpacing?: number;
    // Defining component ref with separate property name as it's hard to work
    // with forwardRef and components with generics.
    tableRef?: React.Ref<Table<T>>;
    bodyProps?: React.ComponentProps<'div'>;
    HeaderComponent?: React.ComponentType<HeaderProps<T>>;
    renderAfterRows?: (props: { className: string }) => React.ReactNode;
  };

/**
 * Table component that internally uses tanstack table, it uses css grid for
 * styling.
 */
const NvTable = <T extends RowData>(props: Props<T>) => {
  const {
    // Own custom props
    tableRef,
    className,
    bodyProps,
    RowComponent,
    renderAfterRows,
    spacing = halfSpacing,
    sidesSpacing = standardSpacing,
    HeaderComponent = DefaultHeader,
    // Overriden props
    columns,
    // Defaulted props
    manualSorting = true,
    getCoreRowModel = libraryGetCoreRowModel(),
    // All rest props should be tanstack table options only
    ...restProps
  } = props;

  const reactTableColumns: ColumnDef<T, any>[] = columns.map((column) => ({
    // By default, all columns are not sortable, if you want to make a column
    // sortable, you will need to specify it in the column definition.
    enableSorting: false,
    ...column,
  }) as any);

  const table = useReactTable<T>({
    manualSorting,
    getCoreRowModel,
    columns: reactTableColumns,
    ...restProps,
  });

  // Forwarding table instance outside the component
  React.useImperativeHandle(tableRef, () => table);

  let gridTemplateColumns: string = '';

  table.getVisibleLeafColumns().forEach((c) => {
    const width = (c.columnDef as any).gridTemplateColumn ?? 'auto';

    gridTemplateColumns += `${width.toString()} `;
  });

  const rowStyles = css`
    display: grid;
    column-gap: ${spacing}px;
    grid-template-columns: ${gridTemplateColumns};
  `;

  return (
    <div className={className}>
      <div>
        {table.getHeaderGroups().map(headerGroup => (
          <HeaderComponent<T>
            css={rowStyles}
            key={headerGroup.id}
            headerGroup={headerGroup}
            getCellStyle={(index) => getCellStyle(index, headerGroup.headers.length, sidesSpacing)}
          />
        ))}
      </div>
      <div {...bodyProps}>
        {table.getRowModel().rows.map(row => (
          <RowComponent
            row={row}
            key={row.id}
            css={rowStyles}
            getCellStyle={(index) => getCellStyle(index, row.getVisibleCells().length, sidesSpacing)}
          />
        ))}
        <ClassNames>
          {({ css: classNamesCss }) => renderAfterRows?.({ className: classNamesCss`${getSpacingStyle(0, 1, sidesSpacing)}` })}
        </ClassNames>
      </div>
    </div>
  );
};

const getSpacingStyle = (index: number, totalVisibleColumns: number, sidesSpacing: number) => {
  const isFirst = index === 0;
  const isLast = index === (totalVisibleColumns - 1);

  return css`
    ${isFirst && css`
      padding-left: ${sidesSpacing}px;
    `};
    ${isLast && css`
      padding-right: ${sidesSpacing}px;
    `};
  `;
};

const getCellStyle = (index: number, totalVisibleColumns: number, sidesSpacing: number) => css`
  min-width: 0;
  grid-column: ${index + 1} / span 1;
  ${getSpacingStyle(index, totalVisibleColumns, sidesSpacing)};
`;

export default NvTable;
