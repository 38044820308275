import { useContext } from 'react';
import t from 'react-translate';
import moment from 'moment';
import prodPathReplace from 'shared/prod-path-rewrite';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import MentoringProgramContext from '../../context';

const MODAL_HEIGHT = 340;
const MODAL_WIDTH = 440;

enum STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in-progress',
}

const SETTINGS = {
  [STATUS.IN_PROGRESS]: {
    image: 'remaining-questions',
    label: 'IN_PROGRESS',
  },
  [STATUS.COMPLETED]: {
    image: 'all-done',
    label: 'COMPLETED',
  },
};

const getDaysLeft = (date: string) => (date ? moment(date).diff(moment(), 'days') : 0);

type RequiredQuestionsModalProps = {
  show: boolean;
  remainingQuestions: number;
  onClose?: () => void;
};

const RequiredQuestionsModal = ({ show, remainingQuestions, onClose }: RequiredQuestionsModalProps) => {
  const translationBase = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.REMAINING_QUESTIONS_MODAL;
  const status = remainingQuestions ? STATUS.IN_PROGRESS : STATUS.COMPLETED;

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const daysLeft = getDaysLeft(mentoringProgram?.matchAnnouncementDate);

  const { image, label } = SETTINGS[status];
  const src = prodPathReplace(`images/${image}.png`);
  const title = translationBase[label].TITLE(remainingQuestions ?? null);
  const description = translationBase[label].DESCRIPTION(daysLeft);

  return (
    <NvModal
      show={show}
      width={MODAL_WIDTH}
      height={MODAL_HEIGHT}
      headerBgColor='transparent'
      bodyClassName='p-0'
      type={ModalType.FIXED}
      onClose={onClose}
      header=''
      body={(
        <div className='d-flex flex-column align-items-center text-center'>
          <img src={src} alt={title} />
          <div className='w-100 px-6 pt-5'>
            <div className='page-title mb-2'>{title}</div>
            <div className='text-large-body'>{description}</div>
          </div>
        </div>
      )}
    />
  );
};

export default RequiredQuestionsModal;
