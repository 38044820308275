import moment from 'moment';
import t from 'react-translate';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import { DATE_FORMAT } from 'athena/components/constants';
import CollapsiblePanel from 'athena/components/collapsible-panel';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import {
  CompletionStatus,
  MentoringProgramEnrollment,
  StepKey,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import StepItem from './step-item';
import { Step } from './types';
import CompleteProfileButton from './profile-completion/complete-profile-button';
import { isAnyRequiredFieldUnanswered } from './utils';

const DAYS_BEFORE_MATCH = 5;

type StepListProps = {
  showPlanSessionFlyout: (session?: MentorshipProgramSession) => void,
};

const StepList = ({
  showPlanSessionFlyout,
}: StepListProps) => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { matchAnnouncementDate, mentorshipProgramEnrollment: enrollmentId } = mentoringProgram || {};
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(
    state => getMentoringProgramEnrollment(state, enrollmentId),
  );

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const connectionId = parseInt($state.params.connectionId, 10);

  const { states, assignedConnections } = mentorshipProgramEnrollment || {};

  const connection = assignedConnections?.find((connec) => (
    connec.connectionId === connectionId
  )) ?? assignedConnections?.[0];
  const { isSessionCreated } = connection || {};

  const completionDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate)
      .subtract(DAYS_BEFORE_MATCH, 'days')
      .format(DATE_FORMAT)
    : null;

  const formattedMatchAnnouncementDate = matchAnnouncementDate
    ? moment(matchAnnouncementDate).format(DATE_FORMAT)
    : null;
  const isMatchUpcoming = moment(matchAnnouncementDate).isAfter(moment());
  const isMatchPast = moment().isAfter(moment(matchAnnouncementDate));
  const upcomingMatchMessage = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.UPCOMING_MATCH_DATE(
    formattedMatchAnnouncementDate,
  );
  const passedMatchMessage = assignedConnections?.length
    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.PASSED_MATCH_DATE_WITH_CONNECTION(
      formattedMatchAnnouncementDate,
    )
    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.PASSED_MATCH_DATE_WITHOUT_CONNECTION();
  const getContentBasedOnMatchAnnouncementDate = () => (isMatchUpcoming ? upcomingMatchMessage : passedMatchMessage);

  const stepsList: { [key in StepKey]?: Step } = {
    [StepKey.PROFILE_COMPLETION]: {
      title: isAnyRequiredFieldUnanswered(states)
        ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.TITLE()
        : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.REVIEW_YOUR_PROFILE.TITLE(),
      content: completionDate
        ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.COMPLETE_YOUR_PROFILE.CONTENT(
          completionDate,
        )
        : null,
      showBadge: true,
      ctaButton: (
        <CompleteProfileButton
          status={
            states[StepKey.PROFILE_COMPLETION]?.status
            || CompletionStatus.NOT_STARTED
          }
        />
      ),
    },
    [StepKey.WAITING_CONNECTION]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.WAIT_TO_BE_CONNECTED.TITLE(),
      content: matchAnnouncementDate
        ? getContentBasedOnMatchAnnouncementDate()
        : null,
    },
    [StepKey.WAITING_FIRST_SESSION]: {
      title: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.TITLE(),
      content: t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.CONTENT(),
      ctaButton: (isMatchPast && assignedConnections.length) ? (
        <Button onClick={() => showPlanSessionFlyout()}>
          {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.LIST.PLAN_FIRST_SESSION.ADD_SESSION()}
        </Button>
      ) : null,
    },
  };

  return (
    <CollapsiblePanel
      title={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.TITLE()}
    >
      <div className='mt-4'>
        {Object.entries(stepsList).map(
          ([key, { title, content, showBadge, ctaButton }]) => {
            let status = states[key]?.status || CompletionStatus.NOT_STARTED;
            if (key === StepKey.WAITING_FIRST_SESSION && !isSessionCreated) {
              status = CompletionStatus.NOT_STARTED;
            }
            return (
              <StepItem
                key={key}
                status={status}
                title={title}
                content={content}
                showBadge={showBadge}
                ctaButton={ctaButton}
              />
            );
          },
        )}
      </div>
    </CollapsiblePanel>
  );
};

export default StepList;
