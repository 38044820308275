import React from 'react';
import { css } from '@emotion/react';

import NvIcon from 'shared/components/nv-icon';
import { white } from 'styles/global_defaults/colors';
import { novoEdBlue, shadedWhite } from 'athena/styles/colors';
import { quarterSpacing } from 'styles/global_defaults/scaffolding';

type Props = {
  className?: string;
};

const MoreOptionsTarget = (props: Props) => {
  const { className } = props;

  const styles = css`
    width: 36px;
    height: 36px;
    color: ${white};
    background-color: ${shadedWhite};
    border-radius: ${quarterSpacing}px;

    &:hover {
      background-color: ${novoEdBlue};
    }
  `;

  return (
    <div
      css={styles}
      className={`options-target d-flex align-items-center justify-content-center ${className ?? ''}`}
    >
      <NvIcon icon='more' size='smallest' />
    </div>
  );
};

export default MoreOptionsTarget;
