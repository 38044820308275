import { css } from '@emotion/react';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import t from 'react-translate';
import ClickableContainer from 'components/clickable-container';
import { MentorshipProgramSession } from 'redux/schemas/models/mentoring-program-sessions';
import prodPathReplace from 'shared/prod-path-rewrite';
import NvCalendar from 'shared/components/nv-calendar';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray1, gray2, gray6, primary, warning, white } from 'styles/global_defaults/colors';
import { headerLineHeight, semiBoldFontWeight, textLargeBodyFontSize, textMediumFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { doubleSpacing, halfSpacing, largeSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';


type NextSessionProps = {
  session: MentorshipProgramSession;
  showPlanSessionFlyout: (session?) => void;
};

const NextSession = ({
  session,
  showPlanSessionFlyout,
}: NextSessionProps) => {
  const styles = css`
    display: flex;
    background-color: ${white};
    border-radius: ${halfSpacing}px;

    .next-session-box {
      padding: ${doubleSpacing}px ${tripleSpacing}px;
      border-bottom-left-radius: ${halfSpacing}px;
      border-bottom-right-radius: ${halfSpacing}px;
      border-top-right-radius: ${halfSpacing}px;
    }

    .next-session-box:hover {
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);

      .session-title {
        color: ${primary};
      }
    }

    .session-info {
      margin-left: 80px;
      height: fit-content;
      align-self: center;
    }

    .next-session {
      color: ${gray2};
      font-weight: ${semiBoldFontWeight};
      font-size: ${textMediumFontSize}px;
      line-height: ${textSmallLineHeight}px;
    }

    .session-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .session-time {
      color: ${gray2};
    }

    .no-next-session {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${doubleSpacing}px ${tripleSpacing}px;

      img {
        height: 80px;
        width: 80px;
        margin-top: ${threeQuartersSpacing}px;
      }

      .still-waiting {
        color: ${gray2};
        font-weight: ${semiBoldFontWeight};
        font-size: ${textLargeBodyFontSize}px;
        line-height: ${headerLineHeight}px;
        margin-top: ${threeQuartersSpacing}px;
      }

      .add-session {
        color: ${primary};
        font-weight: ${semiBoldFontWeight};
        font-size: ${textLargeBodyFontSize}px;
        line-height: ${headerLineHeight}px;
        margin-top: ${quarterSpacing}px;
      }
    }
  `;

  const [hasOverflow, setHasOverflow] = useState(false);
  const titleRef = useRef(null);
  useEffect(() => {
    setHasOverflow(titleRef.current?.scrollHeight > titleRef.current?.clientHeight);
  }, [titleRef.current?.scrollHeight, titleRef.current?.clientHeight]);

  return (
    <div css={styles}>
      {session ? (
        <ClickableContainer className='next-session-box' onClick={() => showPlanSessionFlyout(session)}>
          <NvCalendar date={session.scheduledDate} />
          <div className='session-info'>
            <div className='next-session'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.NEXT_SESSION.TITLE()}</div>
            <NvTooltip enabled={hasOverflow} text={session.title} preventOverflow={false}>
              <div className='session-title heading-2 my-2' ref={titleRef}>{session.title}</div>
            </NvTooltip>
            <div className='session-time'>{moment(session.scheduledDate).format('ddd, MMMM D h:mm a')}</div>
          </div>
        </ClickableContainer>
      ) : (
        <div className='no-next-session'>
          <div className='next-session w-100'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.NEXT_SESSION.TITLE()}</div>
          <img src={prodPathReplace('images/no-next-session.svg')} alt='' />
          <div className='still-waiting'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.NEXT_SESSION.STILL_WAITING()}</div>
          <ClickableContainer onClick={() => showPlanSessionFlyout()}>
            <div className='add-session'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.NEXT_SESSION.ADD_SESSION()}</div>
          </ClickableContainer>
        </div>
      )}
    </div>
  );
};

export default NextSession;
