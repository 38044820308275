import { useContext, useEffect, useState } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import { css } from '@emotion/react';
import prodPathReplace from 'shared/prod-path-rewrite';

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { notifyFirstConnectionAnnounced } from 'redux/actions/mentoring-programs';

import NvModal, { ModalType } from 'shared/components/nv-modal';
import MentoringProgramContext from '../../context';

const ANIMATION_DURATION = 3500;
const MODAL_WIDTH = 440;
const MODAL_HEIGHT = 487;
const IMAGE_WIDTH = 220;
const IMAGE_HEIGHT = 190;

const dateHasPassed = (date) => date && new Date(date) < new Date();

const styles = css`
  padding-bottom: 70px;
  .celebration-image {
    img {
      width: ${IMAGE_WIDTH}px;
      height: ${IMAGE_HEIGHT}px;
    }
  }
`;

const MatchCelebration = () => {
  const [announced, setAnnounced] = useState(false);
  const { injectServices } = useContext(AngularContext);
  const [ConfettiAnimation] = injectServices(['ConfettiAnimation']);
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id, matchAnnouncementDate, mentorshipProgramEnrollment } = mentoringProgram;

  const mentoringProgramEnrollment = useSelector(state => (
    state.models.mentoringProgramEnrollments[mentorshipProgramEnrollment]
  ));
  const { firstConnectionAnnounced, assignedConnections } = mentoringProgramEnrollment;
  const hasMultipleMentees = assignedConnections?.length > 1;
  const contentText = hasMultipleMentees
    ? t.MENTORING_PROGRAMS.PARTICIPANT_HOME.MATCH_CELEBRATION.MULTIPLE_MENTEES(assignedConnections.length)
    : t.MENTORING_PROGRAMS.PARTICIPANT_HOME.MATCH_CELEBRATION.SINGLE_MENTEE();

  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);

  const notifyCelebrationModalClosed = () => {
    if (isNotifying) return;
    setIsNotifying(true);
    wrapThunkAction(
      dispatch(notifyFirstConnectionAnnounced({
        programId: id,
        programEnrollmentId: mentorshipProgramEnrollment,
      })),
    ).then(() => {
      setIsNotifying(false);
      setAnnounced(true);
    });
  };

  const handleClose = () => {
    setShowModal(false);
    notifyCelebrationModalClosed();
  };

  useEffect(() => {
    const shouldShowModal = !announced
      && dateHasPassed(matchAnnouncementDate)
      && !firstConnectionAnnounced
      && assignedConnections?.length > 0;
    if (shouldShowModal) {
      ConfettiAnimation?.showConfetti();
      setShowModal(true);
      setTimeout(handleClose, ANIMATION_DURATION);
    }
  }, [matchAnnouncementDate, firstConnectionAnnounced]);

  return (
    <NvModal
      type={ModalType.FIXED}
      header=''
      headerBgColor='transparent'
      height={MODAL_HEIGHT}
      width={MODAL_WIDTH}
      show={showModal}
      onClose={handleClose}
      body={(
        <div
          css={styles}
          className='d-flex flex-column align-items-center justify-content-center h-100'
        >
          <div className='heading-3 mb-2'>{t.MENTORING_PROGRAMS.PARTICIPANT_HOME.MATCH_CELEBRATION.TITLE()}</div>
          <div className='mb-2 text-center'>{contentText}</div>
          <div className='celebration-image'>
            <img
              src={prodPathReplace('images/match-celebration.gif')}
              alt={t.MENTORING_PROGRAMS.PARTICIPANT_HOME.MATCH_CELEBRATION.ALT_IMAGE()}
            />
          </div>
        </div>
        )}
    />
  );
};

export default MatchCelebration;
