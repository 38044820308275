import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  MentorshipProgramConnection,
  MentorshipProgramConnectionMember,
} from 'redux/schemas/models/mentorship-program-connections';
import { CreateConnectionType } from 'redux/schemas';

type MentorshipProgramParams = {
  mentorshipProgramId: number;
};

type CreateMentorshipProgramConnectionParams = MentorshipProgramParams & {
  connections: { [key: string]: number }[];
};

export const createMentorshipProgramConnection = createAsyncThunk<
MentorshipProgramConnectionMember[],
CreateMentorshipProgramConnectionParams
>(
  'CREATE_MENTORSHIP_PROGRAM_CONNECTION',
  async ({ mentorshipProgramId, connections }) => {
    const response = await axios.post(
      `/mentorship_programs/${mentorshipProgramId}/connections.json`,
      {
        connections,
      },
    );

    return response.data.result;
  },
);

type DeleteMentorshipProgramConnectionParams = MentorshipProgramParams & {
  mentorshipProgramConnectionId: number;
};

export const deleteMentorshipProgramConnection = createAsyncThunk<
boolean,
DeleteMentorshipProgramConnectionParams
>(
  'DELETE_MENTORSHIP_PROGRAM_CONNECTION',
  async ({ mentorshipProgramId, mentorshipProgramConnectionId }) => {
    const response = await axios.delete(
      `/mentorship_programs/${mentorshipProgramId}/connections/${mentorshipProgramConnectionId}.json`,
    );

    return response.data.result;
  },
);

export type GetMentorshipProgramConnectionParams = MentorshipProgramParams & {
  page: number;
  pageSize: number;
  textSearch?: string;
  // At the moment we can only sort using column 'title', we might add more columns in the future and have more sorting capabilities
  sortBy?: 'title';
  sortDesc?: boolean;
};

const sortByToParamPrefix = {
  title: 'most_recent_',
};

export const getMentorshipProgramConnections = createAsyncThunk<
MentorshipProgramConnection[],
GetMentorshipProgramConnectionParams
>(
  'GET_MENTORSHIP_PROGRAM_CONNECTIONS',
  async ({ mentorshipProgramId, page, pageSize, sortBy, sortDesc, textSearch }) => {
    let sortingParam;
    if (sortBy) {
      const suffix = sortDesc ? 'desc' : 'asc';
      sortingParam = `${sortByToParamPrefix[sortBy]}${suffix}`;
    }

    const params = {
      page,
      page_size: pageSize,
      text_search: textSearch || undefined,
    };

    if (sortingParam) {
      params[sortingParam] = true;
    }

    const response = await axios.get(
      `/mentorship_programs/${mentorshipProgramId}/connections.json`,
      {
        params,
      },
    );

    return response.data.result;
  },
);

export const setCreateConnectionModalSettings = createAction<{
  showModal: boolean;
  type: CreateConnectionType;
  enrollmentId: number;
}>('SET_CREATE_CONNECTION_MODAL_SETTINGS');
