import { css } from '@emotion/react';
import t from 'react-translate';
import NvDropdown, {
  NvDropdownButtonStyle,
  NvDropdownOption
} from 'shared/components/inputs/nv-dropdown';
import { commonStyles } from './styles';
import { CellProps } from './types';
import MoreOptionsTarget from 'athena/components/more-options-target';

const styles = css`
  ${commonStyles};

  .options-target {
    opacity: 0;
    flex-shrink: 0;
  }
`;

const OptionsCell = (props: CellProps) => {
  const { cell, className } = props;
  const {
    row: {
      original: selectedConnection
    },
    getContext
  } = cell;

  const {
    table: {
      options: { meta }
    }
  } = getContext();
  const { handleUnpairConnection } = meta;

  const options: NvDropdownOption[] = [
    {
      text: t.MENTORING_PROGRAMS.CONNECTIONS_TABLE.ROWS.MENU.UNPAIR_CONNECTION.TITLE(),
      type: 'text',
      callback: () => handleUnpairConnection(selectedConnection)
    }
  ];

  return (
    <div className={className} css={styles}>
      <NvDropdown
        items={options}
        buttonStyle={NvDropdownButtonStyle.CUSTOM}
        customTarget={() => <MoreOptionsTarget className="options-target" />}
      />
    </div>
  );
};

export default OptionsCell;
