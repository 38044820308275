import t from 'react-translate';

const ProgramLevelHeaders = () => {
  const headers = [
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.HEADERS.QUESTION(),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.HEADERS.QUESTION_TYPE(),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.HEADERS.REQUIRED(),
  ];

  return (
    <div className='text-small semi-bold align-content-center headers'>
      {headers.map((header) => (
        <div key={header} className='cell'>
          {header}
        </div>
      ))}
      <span />
    </div>
  );
};

export default ProgramLevelHeaders;
