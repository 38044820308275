import { DRAFT_PREFIX } from 'athena/components/mentoring-program/constants';
import { RootState } from 'redux/schemas';
import { MentorshipProgramProfileQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import { createSelector } from 'reselect';

export const getMentorshipProgramProfileQuestions = (state: RootState) => state.models.mentorshipProgramProfileQuestions;

export const getMentorshipProgramProfileQuestionsList = createSelector(
  (state: RootState) => state.models.mentorshipProgramProfileQuestions,
  (state, ids: number[]) => ids,
  (mentorshipProgramProfileQuestions: Record<number, MentorshipProgramProfileQuestion>, ids: number[]) => ids.map((id) => mentorshipProgramProfileQuestions[id]),
);

export const isLoadingProgramProfile = (state: RootState) => state.app.mentoringProgram.settings.profile.isLoading;

export const getResetOrgLevelProfileQuestionsListState = (state: RootState) => state.app.mentoringProgram.settings.profile.orgLevel.resetList;
export const getResetProgramLevelProfileQuestionsListState = (state: RootState) => state.app.mentoringProgram.settings.profile.programLevel.resetList;
