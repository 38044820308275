import React from 'react';
import { css } from '@emotion/react';

import { User } from 'redux/schemas/models/my-account';
import { gray2, white } from 'styles/global_defaults/colors';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import { lightBlack, almostBlack, shadedWhite } from 'athena/styles/colors';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import NvTooltip from 'shared/components/nv-tooltip';

type AvatarProps = {
  user: User;
  role?: MentoringProgramRole;
  className?: string;
  showNameOnHover?: boolean;
};

const Avatar = (props: AvatarProps) => {
  const { user, role, className, showNameOnHover = false } = props;

  const styles = css`
    width: ${doubleSpacing}px;
    height: ${doubleSpacing}px;
    border-radius: ${doubleSpacing}px;
    box-shadow: 0px 0px 6px 0px #0000001A inset;

    ${role === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR && css`
      background-color: ${shadedWhite};
      color: ${lightBlack};
    `};

    ${role === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE && css`
      background-color: ${gray2};
      color: ${white};
    `};

    ${role === MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN && css`
      background-color: ${almostBlack};
      color: ${white};
    `};

    background-image: url(${user.profilePicture});
    background-position: center;
    background-size: contain;
  `;

  const getInitials = () => (user?.firstName[0]?.toUpperCase() ?? '') + (user?.lastName[0]?.toUpperCase() ?? '');

  const avatarContent = (
    <div className={`d-flex align-items-center justify-content-center text-medium ${className}`} css={styles}>
      {!user.profilePicture && getInitials()}
    </div>
  );

  return (
    showNameOnHover ? (
      <NvTooltip placement='top' text={user.fullName}>
        {avatarContent}
      </NvTooltip>
    ) : (
      avatarContent
    )
  );
};

export default Avatar;
