import { css } from '@emotion/react';
import { gray5 } from 'styles/global_defaults/colors';
import { quarterSpacing, standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';

type Props = {
  className?: string;
};

export const LoadingTabs = (props: Props) => {
  const { className } = props;

  const styles = css`
    gap: ${tripleSpacing}px;

    .loading-bar {
      background-color: ${gray5};
      height: ${standardSpacing}px;
      width: ${standardSpacing * 4}px;
    }
  `;

  return (
    <div css={styles} className={`d-flex align-items-center justify-content-center ${className ?? ''}`}>
      <div className='loading-bar' />
      <div className='loading-bar' />
      <div className='loading-bar' />
      <div className='loading-bar' />
    </div>
  );
};

export const LoadingContent = () => {
  const styles = css`
    .loading-bar {
      background-color: ${gray5};
      height: ${quarterSpacing}px;

      &.short {
        width: 60%;
      }
    }
  `;
  return (
    <div css={styles}>
      <div className='loading-bar mb-3' />
      <div className='loading-bar mb-3' />
      <div className='loading-bar short mb-3' />
    </div>
  );
};

export default LoadingTabs;
