import { createReducer } from '@reduxjs/toolkit';
import { getMentorshipProgramProfileAnswers } from 'redux/actions/mentoring-program-profile-answers';
import { normalize } from 'normalizr';
import { MentorshipProgramProfileAnswersSchema } from 'redux/schemas/api/mentoring-program-profile-answers';
import { initialRootState } from '.';

export default createReducer(initialRootState, (builder) => {
  builder.addCase(
    getMentorshipProgramProfileAnswers.fulfilled,
    (state, action) => {
      const {
        meta: {
          arg: { mentorshipProgramEnrollmentId },
        },
        payload,
      } = action;
      const {
        entities: { mentorshipProgramProfileAnswers: answers },
      } = normalize(payload, MentorshipProgramProfileAnswersSchema);
      state.models.mentorshipProgramProfileAnswers[
        mentorshipProgramEnrollmentId
      ] = {
        ...state.models.mentorshipProgramProfileAnswers[
          mentorshipProgramEnrollmentId
        ],
        ...answers,
      };
    },
  );
});
