import { ReactComponent as LightGrayAvatar } from 'styles/icons/light-gray-avatar.svg';
import t from 'react-translate';

const NoResultsFound = () => (
  <div className='d-flex flex-column align-items-center justify-content-center empty-ui'>
    <LightGrayAvatar width={80} height={80} className='mb-3' />
    <div className='text-medium'>
      {t.MENTORING_PROGRAMS.PARTICIPANTS.NO_RESULTS_FOUND()}
    </div>
  </div>
);

export default NoResultsFound;
