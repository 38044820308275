import { MentorshipProgramProfileQuestion } from './mentoring-program-profile-questions';

export enum ResponseType {
  INTEGER = 'integer',
  STRING = 'string',
}

export enum Expression {
  EQUAL_TO = 'equal_to',
  NOT_EQUAL_TO = 'not_equal_to',
  CONTAINS = 'contains',
  GREATER_THAN = 'greater_than',
  LESS_THAN = 'less_than',
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to',
  SAME = 'same',
  DIFFERENT = 'different',
  SIMILAR = 'similar',
}

export enum ImportanceLevel {
  NICE_TO_HAVE = 'nice_to_have',
  PREFERRED = 'preferred',
  REQUIRED = 'required',
}

export type AutoMatchingRule = {
  id: number;
  mentorshipProgramProfileQuestion: MentorshipProgramProfileQuestion;
  responseType: ResponseType;
  expression: Expression;
  expressionValue: string;
  importanceLevel: ImportanceLevel;
};
