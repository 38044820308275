import React from 'react';
import { useSelector } from 'react-redux';

import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import { fetchProgramCounts } from 'redux/actions/mentoring-programs';
import { getProgramCounts } from 'redux/selectors/mentoring-programs';
import { fetchProgramParticipantCounts } from 'redux/actions/mentoring-program-enrollments';
import { getProgramParticipantCounts } from 'redux/selectors/mentorship-program-enrollments';

export const useProgramSummaryCounts = () => {
  const dispatch = useAppDispatch();

  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { programId } = $state.params;
  const { result, isLoading } = useSelector(getProgramCounts);

  const requestCounts = React.useCallback(async (omitLoading: boolean = false) => {
    dispatch(fetchProgramCounts({ programId, setLoading: !omitLoading }));
  }, [dispatch, programId]);

  React.useEffect(() => {
    requestCounts();
  }, [requestCounts]);

  return {
    result,
    isLoading,
    refresh: () => requestCounts(true),
  };
};

export const useParticipantsSummaryCounts = () => {
  const dispatch = useAppDispatch();

  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { programId } = $state.params;
  const { result, isLoading } = useSelector(getProgramParticipantCounts);

  const requestCounts = React.useCallback(async (omitLoading: boolean = false) => {
    dispatch(fetchProgramParticipantCounts({ programId, setLoading: !omitLoading }));
  }, [dispatch, programId]);

  React.useEffect(() => {
    requestCounts();
  }, [requestCounts]);

  return {
    result,
    isLoading,
    refresh: () => requestCounts(true),
  };
};
