import { mergeWith } from 'lodash';
import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import {
  fetchProgramParticipantCounts,
  getMentoringProgramEnrollment,
  getMentoringProgramEnrollments,
  refreshProgramParticipantsView,
  setMentoringProfileReviewed,
  updateProgramParticipantCounts,
} from 'redux/actions/mentoring-program-enrollments';
import { MentoringProgramEnrollmentSchema, MentoringProgramEnrollmentsSchema } from 'redux/schemas/api/mentoring-program-enrollments';
import { initialRootState } from '.';

export default createReducer(initialRootState, builder => {
  builder
    .addCase(getMentoringProgramEnrollments.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentoringProgramEnrollmentsSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(getMentoringProgramEnrollment.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentoringProgramEnrollmentSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(fetchProgramParticipantCounts.pending, (state, action) => {
      if (action.meta.arg.setLoading ?? true) {
        state.app.mentoringProgram.participantCounts.isLoading = true;
      }
    })
    .addCase(fetchProgramParticipantCounts.fulfilled, (state, action) => {
      state.app.mentoringProgram.participantCounts.result = action.payload;
      state.app.mentoringProgram.participantCounts.isLoading = false;
    })
    .addCase(fetchProgramParticipantCounts.rejected, (state) => {
      state.app.mentoringProgram.participantCounts.isLoading = false;
    })
    .addCase(updateProgramParticipantCounts, (state, action) => {
      const { counts } = action.payload;
      state.app.mentoringProgram.participantCounts.result = counts;
    })
    .addCase(refreshProgramParticipantsView, (state, action) => {
      const { refreshView } = action.payload;
      state.app.mentoringProgram.participantView.refresh = refreshView;
    })
    .addCase(setMentoringProfileReviewed.fulfilled, (state, action) => {
      const normalized = normalize(action.payload, MentoringProgramEnrollmentSchema);

      mergeWith(state.models, normalized.entities, replaceArrays);
    });
});
