import React from 'react';
import t from 'react-translate';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { addAlertMessage } from 'redux/actions/alert-messages';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import RolesDropdown from 'institutions/components/manage-offering-access-modal/roles-dropdown';
import {
  modalStyles,
  modalContentStyles,
} from 'institutions/components/manage-offering-access-modal/styles';
import { config } from '../../../../config/pendo.config.json';

export type AccessRole = {
  label: string;
  value: number;
  renderInfo?: () => React.ReactElement;
};

type Props = {
  show: boolean;
  roles: AccessRole[];
  onClose: () => void;
  managementLink: string;
  type: 'course' | 'program';
  onEnroll: (roleValue: number) => Promise<any>;
  selectedRoleValue?: any;
  managementLinkNewTab?: boolean;
};

function ManageOfferingAccessModal(props: Props) {
  const {
    show,
    type,
    roles,
    onClose,
    onEnroll,
    managementLink,
    selectedRoleValue: initialSelectedRoleValue = null,
    managementLinkNewTab = false,
  } = props;

  const dispatch = useDispatch();
  const [isJoining, setIsJoining] = React.useState(false);
  const [selectedRoleValue, setSelectedRoleValue] = React.useState<number>(initialSelectedRoleValue);

  const handleJoinCourseClick = () => {
    setIsJoining(true);

    onEnroll(selectedRoleValue)
      .catch(() => {
        dispatch(addAlertMessage({
          type: AlertMessageType.ERROR,
          header: t.FORM.OOPS(),
          message: t.USERS.REGISTRATION.ERRORS.GENERIC(),
        }));
      })
      .finally(() => {
        setIsJoining(false);
      });
  };

  const modalContent = (
    <div className='manage-course-access-modal-content' css={modalContentStyles}>
      <div className='select-role'>
        <div className='select-role-message'>
          {t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.SELECT_ROLE_DROPDOWN_MESSAGE(type)}
        </div>
        <div className='select-role-form'>
          <RolesDropdown
            roles={roles}
            selectedRoleValue={selectedRoleValue}
            setSelectedRoleValue={setSelectedRoleValue}
          />
          <Button
            onClick={handleJoinCourseClick}
            disabled={(selectedRoleValue === null) || isJoining}
            pendo-tag-name={config.pendo.manageCourseAccessModal.manageCourseAccessJoin}
          >
            {t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.JOIN_COURSE(type)}
          </Button>
        </div>
      </div>
      {type !== 'program' ? (
        <>
          <div className='divider'>
            <div className='line' />
            <span>{t.SHARED.OR()}</span>
            <div className='line' />
          </div>
          <div className='user-management'>
            {t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.USER_MANAGEMENT_LINK(
              managementLinkNewTab.toString(),
              type,
              managementLink,
            )}
          </div>
        </>
      ) : null}
      <div className='actions'>
        <Button
          onClick={onClose}
          pendo-tag-name={config.pendo.manageCourseAccessModal.manageCourseAccessClose}
        >
          {t.FORM.CLOSE()}
        </Button>
      </div>
    </div>
  );

  return (
    <NvModal
      show={show}
      width={600}
      height={460}
      onClose={onClose}
      body={modalContent}
      type={ModalType.FIXED}
      modalStyles={modalStyles}
      header={t.INSTITUTIONS.MANAGE_COURSE_ACCESS_MODAL.TITLE(type)}
      closePendoTagName={config.pendo.manageCourseAccessModal.manageCourseAccessX}
    />
  );
}

export default ManageOfferingAccessModal;
