/* eslint-disable array-callback-return */
import { useContext, useEffect, useMemo } from 'react';
import {
  deleteMentorshipProgramAutoMatchingRule,
  getMentorshipProgramAutoMatchingRules,
  getMentorshipProgramAutoMatchingRulesTotalCount,
  resetAutoMatchingRulesList,
} from 'redux/actions/mentoring-program-matching-rules';
import { AutoMatchingRule } from 'redux/schemas/models/mentoring-program-matching-rules';
import { useAppDispatch } from 'redux/store';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import {
  getMentorshipProgramMatchingRulesList,
  getResetAutoMatchingRulesListState,
} from 'redux/selectors/mentorship-program-matching-rules';
import { wrapThunkAction } from 'redux/utils';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import MatchingRuleRow from './matching-rule-row';
import MentoringProgramContext from '../../context';
import { PAGE_SIZE, PageSizeParams } from '../../constants';
import MatchingRulesHeaders from './matching-rules-headers';

const MatchingRulesList = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const resetList = useSelector(getResetAutoMatchingRulesListState);
  const { ref: endRef, inView } = useInView();

  useEffect(() => {
    dispatch(
      getMentorshipProgramAutoMatchingRulesTotalCount({
        mentorshipProgramId,
      }),
    );
  }, []);

  const params = useMemo(
    () => ({
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { reset, result: matchingRules, loadMore } = usePaginatedApi<
  AutoMatchingRule,
  PageSizeParams
  >(
    p => {
      if (mentorshipProgramId) {
        return dispatch(
          getMentorshipProgramAutoMatchingRules({
            mentorshipProgramId,
            ...p,
          }),
        ).then(action => action.payload);
      }
      return undefined;
    },
    params,
    getMentorshipProgramMatchingRulesList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  const onDelete = (ruleId: number) => {
    wrapThunkAction(
      dispatch(
        deleteMentorshipProgramAutoMatchingRule({ mentorshipProgramId, ruleId }),
      ),
    ).then(() => {
      dispatch(
        getMentorshipProgramAutoMatchingRulesTotalCount({ mentorshipProgramId }),
      );
      reset();
    });
  };

  useEffect(() => {
    if (resetList) {
      reset();
      dispatch(resetAutoMatchingRulesList({ resetList: false }));
    }
  }, [dispatch, reset, resetList]);

  return matchingRules?.length ? (
    <>
      <MatchingRulesHeaders />
      {matchingRules?.map((rule: AutoMatchingRule) => {
        if (rule) {
          return (
            <MatchingRuleRow key={rule.id} rule={rule} onDelete={onDelete} />
          );
        }
        return null;
      })}
      <div ref={endRef} />
    </>
  ) : null;
};

export default MatchingRulesList;
