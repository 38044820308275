import { css } from '@emotion/react';
import NvCheckbox from 'shared/components/inputs/nv-checkbox';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import { heatwave } from 'athena/styles/colors';
import { gray2, white } from 'styles/global_defaults/colors';
import { commonStyles } from './styles';
import { CellProps } from './types';

const radioButtonStyles = css`
  ${commonStyles};

  input {
    &:checked + label:before {
      background-color: ${white} !important;
      border: 6px solid ${heatwave} !important;
    }
  }
`;

const checkboxStyles = css`
  ${commonStyles};
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    input {
      &:checked + label:before {
        background: ${gray2} !important;
        border: 1px solid ${gray2} !important;
      }
    }
  }

  input {
    &:checked + label:before {
      color: ${white} !important;
      background: ${heatwave} !important;
      border: 1px solid ${heatwave} !important;
    }
  }
`;

const getSelector = (Component, styles) => ({
  cell: { row },
  className,
}: CellProps) => {
  const {
    original: { id },
  } = row;

  return (
    <div
      className={`${className} d-flex align-items-center ${
        !row.getCanSelect() ? 'disabled' : ''
      }`}
      css={styles}
    >
      <Component
        name={`enrollmentId-${id}`}
        label=''
        value={id}
        checked={row.getIsSelected() || !row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    </div>
  );
};

export const RadioSelectorCell = getSelector(NvRadioButton, radioButtonStyles);
export const CheckboxSelectorCell = getSelector(NvCheckbox, checkboxStyles);
